/* Auto-complete dropdown styling */
.auto-complete-dropdown {
    position: absolute;
    bottom: calc(100% + var(--spacing-sm));
    left: 0;
    right: 0;
    background: var(--dropdown-bg);
    border: 1px solid var(--border-interactive);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-elevated);
    max-height: 300px;
    overflow-y: auto;
    z-index: var(--z-dropdown);
    backdrop-filter: var(--blur-md);
    padding: var(--spacing-sm);
    transform-origin: bottom center;
  }
  
  .auto-complete-item {
    display: flex;
    align-items: center;
    padding: var(--spacing-sm) var(--spacing-md);
    margin: var(--spacing-xs) 0;
    border-radius: var(--radius-md);
    cursor: pointer;
    transition: all var(--transition-fast);
    position: relative;
    gap: var(--spacing-md);
  }
  
  .auto-complete-item:hover,
  .auto-complete-item.selected {
    background: var(--dropdown-hover);
  }
  
  .auto-complete-item .suggestion-category {
    font-size: var(--font-size-xs);
    color: var(--primary-color);
    background: rgba(var(--primary-rgb), 0.1);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--radius-lg);
    white-space: nowrap;
    min-width: 100px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .auto-complete-item .suggestion-text {
    flex: 1;
    font-size: var(--font-size-xs);
    color: var(--dropdown-text);
    line-height: var(--line-height-base);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
  }
  
  .auto-complete-item svg {
    color: var(--text-tertiary);
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    transition: transform var(--transition-fast);
  }
  
  .auto-complete-item:hover svg {
    color: var(--primary-color);
  }
  
  /* Empty state */
  .auto-complete-dropdown:empty {
    padding: 0;
    border: none;
  }
  
  /* Mobile responsive adjustments */
  @media (max-width: 768px) {
    .auto-complete-dropdown {
      bottom: calc(100% + var(--spacing-xs));
      max-height: 250px;
    }
  
  }
  
  /* Keyboard navigation indicator */
  .auto-complete-item.selected::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background: var(--primary-color);
    border-radius: var(--radius-sm);
  }