.attached-files-section {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    background: transparent;
}
  
.file-tag {
    display: inline-flex;
    align-items: center;
    background: var(--background-light);
    border: 1px solid var(--border-light);
    padding: 4px 8px;
    border-radius: 16px;
    margin-left: 0rem;
    margin-right: -0.3rem;
    font-size: 0.85rem;
    gap: 0.1rem;
    color: var(--text-primary);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    backdrop-filter: blur(8px);
}

.file-tag:hover {
    background: var(--background-hover);
    border: 1px solid var(--border-interactive);
}

.file-tag svg {
    color: var(--text-secondary);
    transition: transform 0.2s ease;
}

.file-tag span {
    color: var(--text-secondary);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .file-tag {
        padding: 4px 10px;
        font-size: 0.8rem;
    }

    .file-tag svg {
        width: 12px;
        height: 12px;
    }
}

@media (max-width: 480px) {
    .file-tag {
        padding: 3px 8px;
        font-size: 0.75rem;
    }
}

.attachment-dropdown {
    position: absolute;
    top: 100%;
    margin-top: 0.9rem;
    background: var(--dropdown-bg);
    border: 1px solid var(--border-interactive);
    border-radius: var(--radius-2xl);
    padding: var(--spacing-md);
    box-shadow: 0 4px 12px var(--shadow-color);
    z-index: var(--z-dropdown);
    min-width: 220px;
    backdrop-filter: var(--blur-sm);
    transition: all var(--transition-base) var(--ease-out);
    margin-left: -10.6rem;
}

.attachment-dropdown h3 {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-sm);
    padding: 0 var(--spacing-sm);
}

.attachment-dropdown-item {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    color: var(--dropdown-text);
    padding: var(--spacing-sm);
    border-radius: var(--radius-md);
    transition: all var(--transition-fast) var(--ease-out);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
}

.attachment-dropdown-item:hover {
    background: var(--dropdown-hover);
    color: var(--dropdown-text-hover);
}

.attachment-dropdown-item svg {
    color: var(--text-tertiary);
    transition: color var(--transition-fast) var(--ease-out);
}