.arrow-icon {
  transition: transform 0.2s ease;
}

.arrow-icon.rotated {
  transform: rotate(180deg);
}

.mode-text {
  width: auto;
  margin-left: var(--spacing-xs);
  opacity: 1;
  display: block;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-normal);
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.2s ease, width 0.2s ease;
}

.mode-dropdown {
  position: absolute;
  top: 100%;
  left: 35px;
  background: var(--dropdown-bg);
  backdrop-filter: var(--blur-md);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-elevated);
  border: 1px solid var(--border-color);
  z-index: var(--z-tooltip);
  max-width: 280px;
  padding: var(--spacing-xs) var(--spacing-xs);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.95) translateY(-8px);
  transition: all var(--transition-base) var(--ease-out);
  max-height: 250px;
  overflow-y: auto;
  margin-top: var(--spacing-xs);
}

.mode-dropdown.chat {
  top: auto;
  bottom: 53px;
  left: 35px;
}

.mode-dropdown.regenerate-dropdown {
    top: auto;
    bottom: 43px;
    left: 35px;
}

.mode-dropdown.regenerate-dropdown.first-message {
  bottom: -215px;
}

/* Update the active state */
.mode-dropdown.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1) translateY(0);
}

.mode-dropdown-title {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  padding: var(--spacing-sm) var(--spacing-md);
  letter-spacing: 0.5px;
  text-align: left;
  position: sticky;
  top: 0;
  background: var(--dropdown-bg);
  z-index: 1;
}

.mode-title {
  text-align: left;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
}

.mode-option {
  display: flex;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  margin: var(--spacing-xs) 0;
  color: var(--dropdown-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-fast);
  cursor: pointer;
  gap: var(--spacing-sm);
  border-radius: var(--radius-lg);
  position: relative;
  overflow: hidden;
}

.mode-option:hover {
  background: var(--dropdown-hover);
  color: var(--dropdown-text-hover);
}

.mode-description {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-top: var(--spacing-xs);
  display: none;
}

.mode-option svg {
  color: var(--primary-color);
  font-size: var(--font-size-lg);
  transition: transform var(--transition-fast);
  margin-right: var(--spacing-sm);
}

.mode-option:hover svg {
  transform: scale(1.1);
}

.mode-option:first-child {
  border-top-left-radius: var(--radius-md);
  border-top-right-radius: var(--radius-md);
}

.mode-option:last-child {
  border-bottom-left-radius: var(--radius-md);
  border-bottom-right-radius: var(--radius-md);
}

/* Add these new styles for the active mode */
.mode-option.active {
  background: var(--background-hover-secondary);
  color: var(--dropdown-text-hover);
}

.mode-option:hover .mode-description {
  color: var(--dropdown-text-hover);
  opacity: 0.8;
  display: flex;
}
