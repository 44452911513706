@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define custom font variables */
:root {
  --font-primary: 'Inter', sans-serif;
}

body {
  font-family: var(--font-primary);
}

/* Add these rules at the top of the file */
html, body {
  background-color: var(--background-main);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

#root {
  background-color: var(--background-main);
  min-height: 100vh;
}

main {
  flex: 1;
}

.content {
  font-family: var(--font-primary);
  background-color: var(--background-main);
}

.app-container{
  overflow-y: hidden;
  background: var(--background-main);
  overscroll-behavior: contain;
  min-height: 100vh;
}

.app-container.with-sidebar {
  flex-direction: row;
}

.app-container .is-chats-page{
  max-height: 100vh;
  overflow: hidden;
}

/**
universal nav styling
**/
.top-nav, .top-nav2 {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 1rem 1.5rem;
  height: auto;
  background-color: var(--background-main);
}

.navlogo{
  max-width: 60px;
  margin-right: 0.5rem;
}

.top-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.top-nav2{
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: rgba(var(--background-light-rgb), 0.1);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 70px; 
}

.nav-link, .sign-in-button, .profile-button {
  padding: 0.5rem 1rem;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  flex-grow: 1;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-primary);
  text-decoration: none;
  font-weight: 450;
  transition: color 0.3s ease;
  font-size: 0.9em;
}

@media (max-width: 568px) {
  .top-nav{
    padding: 0.3rem 0.8rem;
  }
  .nav-link{
    font-size: 0.8em;
  }
}

.nav-link:hover {
  color: var(--primary-color);
  transform: scale(1.03);
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-toggle {
  background: none;
  border: none;
  color: var(--text-primary);
  font-size: 1.5rem;
  cursor: pointer;
  padding-left: 40px;
}

.top-nav2{
  display: none;
}

.settings-dropdown {
    position: relative;
}

.settings-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    color: var(--text-primary);
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
}

.loading-container{
  height: 100vh;
}

.icon-button {
  background: none;
  border: none;
  padding: 0.75rem;
  color: var(--text-primary);
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.icon-button:hover {
  background-color: var(--background-hover);
  color: var(--text-primary);
  transform: scale(1.05);
}

/* Add overscroll behavior to prevent pull-to-refresh */
body {
  font-family: var(--font-primary);
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
}

/* Sleek Scrollbar Design - Add this near the top of App.css */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

/* Webkit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
  padding: 2px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: var(--radius-full);
  margin: 3px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: var(--radius-full);
  border: 2px solid var(--scrollbar-track);
  margin: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

/* For horizontal scrollbars */
::-webkit-scrollbar-corner {
  background: transparent;
}

/* Internet Explorer */
* {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* Edge Legacy */
@supports (-ms-ime-align:auto) {
  * {
    -ms-overflow-style: auto;
  }
}

/* Opera */
@-o-viewport {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

@media (max-width: 468px) {
  input[type="text"], textarea {
    font-size: 16px;
  }
}