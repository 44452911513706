body {
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-normal);
  background-color: var(--sidebar-bg);
  color: var(--text-primary);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

button {
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

input,
textarea {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 0.5rem;
  font-family: var(--font-family);
}

input:focus,
textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

/* Sidebar Styles */
.sidebar {
  background-color: var(--sidebar-bg);
  color: var(--text-primary);
  position: fixed;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 1vh);
  height: calc(100dvh - 1vh);
  width: var(--sidebar-width);
  left: 0;
  top: 0.5vh;
  z-index: var(--z-fixed);
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
  border-radius: 0 21px 21px 0px;
  transform: translateX(0);
  transition: all var(--transition-normal) var(--bezier-smooth);
  will-change: transform, opacity;
  pointer-events: auto;
}

@media (min-width: 568px){
.sidebar.sidebar-locked {
  height: 100vh;
  top: 0;
  border-radius: 0;
  border-top: none;
  border-bottom: none;
}
}

.sidebar.collapsed {
  transform: translateX(-100%);
  opacity: 0;
  transition: all var(--transition-normal) var(--bezier-smooth);
  box-shadow: none;
  border-right: none;
  pointer-events: none;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(0);
    transition: all var(--transition-normal) var(--bezier-smooth);
    will-change: transform, opacity, visibility;
    backdrop-filter: blur(10px);
  }

  .sidebar.collapsed {
    transform: translateX(-110%);
    opacity: 0;
    visibility: hidden;
    transition: all var(--transition-normal) var(--bezier-smooth), visibility var(--transition-normal) linear;
  }
}

/* Optional: Add transition for content inside sidebar */
.sidebar > * {
  transition: opacity 0.2s ease-in-out;
  will-change: opacity;
}

.sidebar.collapsed > * {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.sidebar-header {
  padding: 0.4rem 0.85rem;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  color: var(--text-primary);
  border-radius: 0 20px 0 0;
  font-weight: var(--font-weight-medium);
}

.user-email {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-normal);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: var(--spacing-sm);
}

.collapse-btn {
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.collapse-btn:hover {
  background-color: var(--hover-overlay);
}

.sidebar-nav {
  flex-grow: 1;
  color: var(--text-secondary);
  overflow-y: auto;
  padding: 0.35rem 0.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 29px 0;
  box-shadow: inset 0 0 0 var(--shadow-color);
}

.sidebar .sentinel {
  height: 1px;
  order: 1;
  width: 100%;
  visibility: hidden;
}

.scrollable-section {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 0rem;
  width: 100%;
  color: var(--text-primary);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding-bottom: 20px;
  z-index: var(--z-fixed);
}

/* Navigation Styles */
.sidebar-nav h3 {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  color: var(--text-secondary);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  letter-spacing: var(--letter-spacing-wide);
  padding: 0 0.5rem;
  opacity: 0.85;
}

.sidebar-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1.25rem 0;
}

.sidebar-nav li {
  margin-bottom: 0.5rem;
}
.sidebar-nav a {
  color: var(--text-secondary);
  text-decoration: none;
  font-weight: var(--font-weight-medium);
  display: flex;
  align-items: center;
  padding: 0.4rem 0.6rem;
  border-radius: 10px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: left center;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: var(--line-height-snug);
  white-space: nowrap;
  overflow: hidden;
}

.sidebar-nav a:hover {
  padding: 0.4rem 0.6rem;
}

.sidebar-nav a .chat-title {
  font-size: var(--font-size-xs);
  color: var(--text-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  flex: 1;
}

.sidebar-nav a .button-group {
  display: none;
  align-items: center;
  flex-shrink: 0;
  gap: 0rem;
  opacity: 1;
  transition: 0.2s ease;
  margin: auto 0;
  margin-left: auto;
}

.sidebar-nav a:hover .button-group {
  display: flex;
  opacity: 1;
}

@media (max-width: 768px) {
  .sidebar-nav a .button-group {
    display: flex;
    opacity: 1;
  }
}

.sidebar-nav a:hover {
  color: var(--text-primary);
  border: 1px solid var(--sidebar-border);
  background-color: var(--sidebar-hover);
  padding: 0.45rem 0.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-nav a.active {
  border: 1px solid var(--sidebar-border);
  color: var(--text-primary);
  font-weight: var(--font-weight-bold);
  padding: 0.45rem 0.6rem;
}

.add-btn,
.logout-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  padding: 0.75rem;
  border-radius: 6px;
  transition: background-color 0.2s ease;
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
  align-self: center;
  border: 1px solid var(--sidebar-border);
}

.sidebar h1 {
  color: var(--error-color);
}

.logout-btn {
  border: none;
  border-radius: 0;
  padding-top: 10px;
  margin-top: auto;
}



.edit-btn,
.remove-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 4px;
  transition: all 0.2s ease;
  margin-left: 0.25rem;
  opacity: 1;
  color: var(--text-secondary);
}

.edit-btn:hover,
.remove-btn:hover {
  background: var(--hover-overlay) !important;
  transform: scale(1.1);
  opacity: 1;
}

.remove-btn {
  color: var(--accent-color);
}

/* Updated editing form and input styles */
.sidebar-nav form {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.6rem;
  margin: 0;
}

.sidebar-nav form input {
  flex-grow: 1;
  background-color: var(--background-white);
  color: var(--text-primary);
  border: 1px solid var(--sidebar-border);
  border-radius: 10px;
  padding: 0.4rem 0.6rem;
  font-size: var(--font-size-xs);
  font-family: var(--font-family);
  width: 100%;
  transition: all 0.2s ease;
}

.sidebar-nav form input:focus {
  outline: none;
  background-color: var(--background-main);
}

.sidebar-nav form input::placeholder {
  color: var(--text-secondary);
  opacity: 0.7;
}

/* Add subtle hover effect */
.sidebar-nav form input:hover:not(:focus) {
  border-color: var(--text-secondary);
  background-color: var(--background-lighter);
}

.collapse-btn {
  display: none;
}

.user-email {
  align-content: first baseline;
}

.sidebar-nav h4 {
  font-size: 0.75rem;
  margin-top: 0rem;
  margin-bottom: 0.25rem;
  padding: 0.4rem 0.6rem;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--sidebar-bg);
}

.sidebar-nav h4:first-of-type {
  margin-top: 0;
}

.sidebar-nav h4 {
  font-size: 0.75rem;
  margin-top: 0rem;
  margin-bottom: 0.25rem;
  padding: 0.4rem 0.6rem;
}

.sidebar-nav li {
  margin-bottom: 0.1rem;
}

.sidebar-nav a {
  padding: 0.4rem 0.6rem;
}

.login-btn {
  margin-top: 1rem;
  border: 1px solid var(--sidebar-border);
}

.sidebar-prompt {
  padding: 2rem 1.5rem;
  text-align: center;
  color: var(--text-primary);
  font-size: var(--font-size-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.login-btn {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1.5rem;
  color: var(--text-primary);
  border-radius: 8px;
  font-weight: var(--font-weight-medium);
  transition: all 0.2s ease;
  width: fit-content;
}

.login-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}


/* Updated toggle styles */
.select-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 16px;
  gap: 12px;
  margin-bottom: 2px;
}

.toggle-label {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 20px;
  background: var(--background-light);
  transition: all 0.3s ease;
  gap: 8px;
  user-select: none;
}

.toggle-label:hover {
  background: var(--background-hover);
}

.toggle-text {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--text-primary);
  transition: color 0.3s ease;
}

.toggle-label input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
  background-color: var(--background-hover);
  border-radius: 25px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--border-light);
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 1px;
  bottom: 1px;
  background-color: var(--background-main);
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 3px var(--shadow-color);
}

/* Active state styling */
.toggle-label input:checked + .toggle-slider {
  background-color: var(--text-primary);
  border-color: var(--text-primary);
}

.toggle-label input:checked + .toggle-slider:before {
  transform: translateX(14px);
  background-color: var(--background-main);
}

/* Delete button styling */
.minimal-delete-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 10px;
  border: none;
  background-color: var(--background-light);
  color: var(--text-secondary);
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.minimal-delete-btn:hover {
  background-color: var(--background-lighter);
  color: var(--text-primary);
}

.minimal-delete-btn svg {
  transition: transform 0.2s ease;
}

.minimal-delete-btn:hover svg {
  transform: scale(1.1);
}

/* Selection mode styles */
.selection-mode .chat-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--background-white);
}

.selection-mode .chat-checkbox:checked {
  background-color: var(--background-white);
  border-color: var(--text-secondary);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.selection-mode .chat-checkbox:checked::after {
  content: '✓';
  position: absolute;
  color: var(--text-primary);
  font-size: 10px;
  left: 1px;
  top: 0px;
  bottom: 2px;
  font-weight: bold;
  opacity: 0;
  transform: scale(0.8);
  animation: checkmark 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes checkmark {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.selection-mode .chat-checkbox:hover {
  border-color: var(--text-secondary);
  background-color: var(--background-lighter);
  transform: scale(1.05);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.selection-mode .chat-checkbox:checked:hover {
  background-color: var(--text-secondary);
  border-color: var(--text-secondary);
  transform: scale(1.05);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Add transition for the delete button appearance */
.minimal-delete-btn {
  opacity: 0;
  transform: translateX(-10px);
  animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes subtle-bounce {
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-2px) scale(1.05);
  }
}

/* Add these new animations */
@keyframes fade-slide-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gentle-pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.9;
  }
  50% {
    transform: translateY(-3px);
  }
}

@keyframes borderGlow {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
}

/* Add hover trigger area */
.sidebar-trigger-area {
  position: fixed;
  left: 0;
  top: 0;
  width: 41px;
  height: 100vh;
  z-index: 1049;
  display: none;
}

.sidebar-trigger-area.space-chat{
  width: 20px;
}

/* Show trigger area only on non-mobile */
@media (min-width: 769px) {
  .sidebar-trigger-area {
    display: block;
  }
}

/* When hovering or active */
.sidebar.hover-open,
.sidebar.open {
  transform: translateX(0);
  opacity: 1;
  pointer-events: auto;
}

/* Loading animation styles */
.loading-container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  gap: 0.75rem;
}

.loading-skeleton {
  width: 100%;
  height: 2.5rem;
  background: linear-gradient(
    90deg,
    var(--background-light) 0%,
    var(--background-lighter) 20%,
    var(--background-lightest) 40%,
    var(--background-lighter) 60%,
    var(--background-light) 80%
  );
  background-size: 200% 100%;
  animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  will-change: transform, opacity;
}

.loading-skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    var(--hover-overlay),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

/* Make skeletons appear one by one */
.loading-skeleton:nth-child(1) { animation-delay: 0.1s; }
.loading-skeleton:nth-child(2) { animation-delay: 0.2s; }
.loading-skeleton:nth-child(3) { animation-delay: 0.3s; }
.loading-skeleton:nth-child(4) { animation-delay: 0.4s; }
.loading-skeleton:nth-child(5) { animation-delay: 0.5s; }

/* Vary the widths for more natural look */
.loading-skeleton:nth-child(odd) { width: 95%; }
.loading-skeleton:nth-child(even) { width: 90%; }
.loading-skeleton:last-child { width: 85%; }

@keyframes loading {
  0% {
    background-position: 200% 0;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    background-position: -200% 0;
    opacity: 0.5;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* Optional: Add hover effect */
.loading-skeleton:hover {
  transform: translateY(-1px);
  transition: transform 0.2s ease;
}

/* Add these new styles for the header buttons */
.sd-menu-button,
.new-chat {
  background: none;
  border: none;
  padding: 2px;
  border-radius: 8px;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.sd-menu-button:hover,
.new-chat:hover {
  background-color: var(--hover-overlay);
}

.sd-menu-button:active,
.new-chat:active {
  transform: translateY(0px);
}

.new-chat.chat{
  margin-left: 10px;
}



/* Add these styles for the logo text */
.logo-text {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  font-family: var(--font-secondary);
  color: var(--text-primary);
  background: var(--primary-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-tight);
  text-shadow: var(--text-shadow-sm);
  flex-grow: 1;
  text-align: left;
  margin-left: 10px;
  transition: all var(--transition-base);
}

.logo-text:hover {
  text-shadow: var(--text-shadow-base);
}


/* Add these styles for hover text */
.sidebar .hover-text {
  position: absolute;
  background-color: var(--background-main);
  color: var(--text-primary);
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.8rem;
  white-space: nowrap;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px var(--shadow-color);
  border: 1px solid var(--border-color);
  z-index: var(--z-tooltip);
}

/* Position variants */
.sidebar .hover-text.right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .hover-text.left {
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .hover-text.top {
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.sidebar .hover-text.toggle{
  margin-top: -10px;
  margin-left: 80px;
}

.sidebar .hover-text.bottom {
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.sidebar .hover-text.bottom-group{
  margin-top: 15px;
  margin-right: 18px;
}

/* Show hover text on parent hover */
.sidebar *:hover > .hover-text {
  opacity: 1;
  visibility: visible;
}

/* Adjust for collapsed sidebar */
.sidebar.collapsed .hover-text {
  left: 100%;
  margin-left: 10px;
}

.sidebar-upgrade{
  padding-left: 10px;
  padding-right: 10px;
}

.upgrade-but {
  display: flex;
  align-items: center;
  color: var(--text-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  margin-bottom: var(--spacing-sm);
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  transition: background-color 0.2s ease;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
}

.upgrade-but:hover{
  background-color: var(--sidebar-hover);
  border-radius: var(--radius-lg);
  border: 1px solid var(--border-color);
}

.upgrade-icon{
  min-width: 35px;
  margin: auto 0;
}

.upgrade-main{
  display: flex;
  flex-direction: column;
}

.upgrade-main-text{
  font-size: var(--font-size-xs);
}

.upgrade-description{
  font-size: var(--font-size-xxs);
  color: var(--text-muted);
}

.sidebar-features{
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.sidebar-feature {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  border-radius: 6px;
}

.sidebar-feature:hover {
  border: 1px solid var(--sidebar-border);
  background-color: var(--sidebar-hover);
  border-radius: 10px;
  color: var(--text-primary);
}

.sidebar-feature .button-text {
  margin-left: 4px;
}


.sd-menu-button.open{
  background-color: var(--background-main);
  padding: 2px;
}

.sidebar .rounded-icon {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid var(--text-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -7px;
  margin-right: 6px;
}

.no-chats-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px;
}
.refresh-chats-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-normal) var(--bezier-smooth);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.refresh-chats-btn:hover {
  background-color: var(--sidebar-hover);
  border-color: var(--border-interactive-hover);

  box-shadow: 0 2px 4px var(--shadow-color);
}