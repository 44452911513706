.messages-skeleton {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
  height: 100%;
}

.skeleton-text {
  height: 1em;
  margin: var(--spacing-xs) 0;
  border-radius: var(--radius-sm);
  background: var(--skeleton-bg);
  background-image: linear-gradient(90deg, var(--skeleton-bg), var(--skeleton-highlight), var(--skeleton-bg));
  background-size: 200% 100%;
  animation: shine 1.5s infinite;
}

.message.skeleton {
  opacity: var(--hover-opacity);
}

.message.skeleton .message-content {
  min-height: 60px;
  background: var(--skeleton-bg);
  border-radius: var(--radius-md);
  background-image: linear-gradient(90deg, var(--skeleton-bg), var(--skeleton-highlight), var(--skeleton-bg));
  background-size: 200% 100%;
  animation: shine 1.5s infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
