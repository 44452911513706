.footer {
  background: var(--background-main);
  color: var(--text-primary);
  padding: var(--spacing-xl) 0;
  font-family: var(--font-primary);
  border-top: 1px solid var(--border-light);
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
  gap: var(--spacing-xl);
}

.footer-section {
  margin-bottom: var(--spacing-lg);
  gap: var(--spacing-lg);
}

.footer-section .logo-text {
  color: var(--primary-color);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-wide);
}

.footer-section h4 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-wide);
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: var(--spacing-sm);
}

.footer-section ul li a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: all var(--transition-base);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-relaxed);
}

.footer-section a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: all var(--transition-base);
  font-size: var(--font-size-sm);
}

.footer-section a:hover {
  color: var(--primary-color);
  transform: translateX(var(--spacing-xs));
}

.footer-bottom {
  text-align: center;
  margin-top: var(--spacing-xl);
  padding-top: var(--spacing-xl);
  border-top: 1px solid var(--border-color);
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
}

.theme-picker-container {
  margin-top: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.footerlogo {
  width: 60px;
  height: auto;
  opacity: 0.9;
  transition: opacity var(--transition-base);
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--spacing-lg);
}

.footerlogo:hover {
  opacity: 1;
}

.theme-picker {
  margin-top: var(--spacing-md);
  display: flex;
  justify-content: center;
}

.theme-button {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-xs) var(--spacing-md);
  background: var(--background-light);
  color: var(--text-secondary);
  border: 1px solid var(--border-light);
  border-radius: var(--radius-lg);
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: background-color var(--transition-base), transform var(--transition-fast), box-shadow var(--transition-fast);
}

.theme-button:hover {
  background: var(--background-hover-primary);
  transform: translateY(-1px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.theme-button:active {
  transform: translateY(0);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.theme-icon {
  font-size: var(--font-size-lg);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-lg);
  }

  .footer-section {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
}
