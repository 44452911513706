:root {
    --pricing-primary: var(--primary-color);
    --pricing-primary-hover: var(--primary-dark);
    --pricing-background: var(--background-main);
    --pricing-card-bg: var(--background-main);
    --pricing-text: var(--text-primary);
    --pricing-muted: var(--text-secondary);
    --pricing-border: var(--border-color);
    --pricing-shadow: var(--shadow-color);
    --pricing-highlight: var(--background-hover-primary);
}

.pricing-container {
    font-family: 'Inter', sans-serif;
    background: var(--pricing-background);
    min-height: 100vh;
    padding: 4rem 2rem;
}

.pricing-hero {
    text-align: center;
    margin-bottom: 4rem;
    animation: fadeIn 0.5s ease-out;
}

.pricing-hero h1 {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--pricing-primary);
    margin-bottom: 1rem;
    letter-spacing: -1px;
    position: relative;
}

.pricing-hero h1::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 4px;
    background-color: var(--pricing-primary);
    border-radius: 2px;
    transition: all 0.3s ease;
}

.pricing-hero p {
    font-size: 1.2rem;
    color: var(--pricing-muted);
    max-width: 600px;
    margin: 2rem auto;
}

.pricing-toggle {
    background: var(--pricing-card-bg);
    display: inline-flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: var(--radius-full);
    gap: 1.25rem;
    box-shadow: var(--shadow-elevated);
    margin: 2rem 0;
    border: 1px solid var(--border-glass);
    backdrop-filter: blur(8px);
    transition: all var(--transition-base);
}

.pricing-toggle span {
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all var(--transition-base);
    border-radius: var(--radius-md);
    color: var(--text-primary);
    font-weight: var(--font-weight-normal);
    user-select: none;
}

.pricing-toggle span.active {
    background: var(--background-light);
    color: var(--text-dark);
    font-weight: var(--font-weight-bold);
    box-shadow: var(--shadow-color);
}

.save-badge {
    background: linear-gradient(
        145deg,
        var(--glass-background),
        rgba(var(--background-light-rgb), 0.9)
    );
    backdrop-filter: blur(8px);
    border: 1px solid var(--border-glass);
    color: var(--text-primary);
    padding: 0.35rem 1rem;
    border-radius: var(--radius-full);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-semibold);
    margin-left: 0.5rem;
    box-shadow: var(--shadow-primary);
    transition: all var(--transition-base);
}

.pricing-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    max-width: 1200px;
    padding: 2rem 0;
    margin: 0 auto;
}

.pricing-card {
    background: linear-gradient(
        145deg,
        rgba(var(--background-main-rgb), 0.8),
        rgba(var(--background-secondary-rgb), 0.9)
    );
    backdrop-filter: blur(12px);
    border-radius: var(--radius-2xl);
    padding: 3rem;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: var(--shadow-elevated);
    border: 1px solid var(--primary-color);
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem auto 0;
}

.pricing-card:hover {
    box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.1),
        0 0 0 1px var(--primary-color);
}

.pricing-card.highlight {
    background: linear-gradient(
        145deg,
        var(--glass-background),
        var(--pricing-highlight)
    );
    border: 2px solid var(--primary-color);
    transform: scale(1.05);
    max-width: 400px;
    margin: 0 auto;
}

.popular-badge {
    position: absolute;
    top: -1rem;
    right: 2rem;
    background: var(--pricing-primary);
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    font-weight: 600;
    font-size: 0.875rem;
    box-shadow: 0 4px 6px rgba(255, 107, 107, 0.2);
}

.price-container {
    text-align: center;
    margin: 2rem 0;
}

.price {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--pricing-primary);
    line-height: 1;
}

.period {
    color: var(--pricing-muted);
    font-size: 1rem;
}

.features {
    margin: 2rem 0;
}

.feature {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    color: var(--pricing-text);
}

.feature svg {
    color: var(--pricing-primary);
    flex-shrink: 0;
}

.signup-button {
    width: 100%;
    padding: 1.2rem;
    background: linear-gradient(135deg, var(--background-hover-primary), var(--background-hover-secondary));
    color: var(--text-primary);
    border: 1px solid var(--border-interactive);
    border-radius: var(--radius-lg);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
    transition: all var(--transition-base);
    position: relative;
    overflow: hidden;
    margin-top: auto;
    backdrop-filter: blur(8px);
}

.signup-button.cta {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}


.signup-button:hover {
    background: linear-gradient(135deg, var(--background-hover-secondary), var(--background-hover-primary));
    border-color: var(--border-interactive-hover);
    box-shadow: var(--shadow-elevated);
    transform: var(--button-hover-transform);
}

/* FAQ Section */
.pricing-faq {
    max-width: 1000px;
    margin: 6rem auto 0;
    padding: 2rem;
}

.faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.faq-item {
    background: var(--pricing-card-bg);
    border-radius: 15px;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid var(--pricing-border);
    text-align: left;
    width: 100%;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    color: var(--pricing-text);
}

.faq-answer {
    margin-top: 1rem;
    color: var(--pricing-muted);
    display: none;
}

.faq-item.active .faq-answer {
    display: block;
}

.faq-item.active .arrow {
    transform: rotate(90deg);
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}


/* Responsive Design */
@media (max-width: 768px) {
    .pricing-container {
        padding: 2rem 1rem;
    }

    .pricing-hero h1 {
        font-size: 2.5rem;
    }

    .price {
        font-size: 3rem;
    }
}

@media (max-width: 480px) {
    .pricing-hero h1 {
        font-size: 2rem;
    }

    .pricing-toggle {
        padding-top: 0rem;
        padding-bottom: 0rem;

        margin-top: 0rem;
        margin-bottom: 0rem;
    }

    .toggle-switch {
        width: 46px;  /* Slightly smaller for mobile */
        height: 26px;
        margin: 0 8px;
    }

    .toggle-switch label:before {
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 3px;
    }

    .toggle-switch input:checked + label:before {
        transform: translateX(20px);
    }

    .save-badge {
        font-size: 0.75rem;
        padding: 0.2rem 0.5rem;
        margin-left: 0.25rem;
        display: none;
    }
}

/* Improved Toggle Switch */
.toggle-switch {
    position: relative;
    width: 60px;
    height: 34px;
    margin: 0 10px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-switch label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-hover-secondary);
    transition: .4s;
    border-radius: 34px;
}

.toggle-switch label:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--background-main);
    transition: .4s;
    border-radius: 50%;
}

.toggle-switch input:checked + label {
    background-color: var(--pricing-primary);
}

.toggle-switch input:checked + label:before {
    transform: translateX(26px);
}

/* Enhanced FAQ Styling */
.pricing-faq {
    background: var(--pricing-card-bg);
    border-radius: 20px;
    padding: 3rem;
}

.pricing-faq h2 {
    text-align: center;
    color: var(--pricing-text);
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1.5rem;
}

.faq-item {
    background: var(--background-main);
    border: 1px solid var(--border-color);
    border-radius: var(--radius-lg);
    padding: var(--spacing-lg);
    cursor: pointer;
    transition: all var(--transition-base) var(--ease-out);
    text-align: left;
    width: 100%;
}

.faq-item:hover {
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-elevated);
    border-color: var(--border-interactive-hover);
    background: var(--background-hover);
}

.faq-item.active {
    background: var(--glass-background);
    border-color: var(--border-primary);
    box-shadow: var(--shadow-primary);
}

.faq-question {
    font-size: 1.1rem;
    color: var(--pricing-text);
}

.faq-answer {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--pricing-muted);
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--pricing-border);
}

.arrow {
    transition: transform 0.3s ease;
    color: var(--pricing-primary);
}

/* Enhanced Card Styling */
.pricing-card {
    background: linear-gradient(145deg, var(--background-main), var(--background-secondary));
}

.pricing-card.highlight {
    background: linear-gradient(145deg, var(--background-main), var(--pricing-highlight));
    transform: scale(1.05);
}

.card-header h3 {
    font-size: 1.8rem;
    color: var(--pricing-text);
    margin-bottom: 0.5rem;
}

.tagline {
    color: var(--pricing-muted);
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}

/* Enhanced Responsive Design */
@media (max-width: 768px) {
    .faq-grid {
        grid-template-columns: 1fr;
    }
    .pricing-card.highlight {
        transform: none;
    }

    .pricing-card.highlight:hover {
        transform: translateY(-10px);
    }
}

/* Animation Refinements */
@keyframes fadeIn {
    from { 
        opacity: 0; 
        transform: translateY(30px); 
    }
    to { 
        opacity: 1; 
        transform: translateY(0); 
    }
}

@keyframes pulse {
    0% { 
        transform: scale(1); 
        opacity: 1;
    }
    50% { 
        transform: scale(1.05); 
        opacity: 0.8;
    }
    100% { 
        transform: scale(1); 
        opacity: 1;
    }
}

.free-tier-hint {
    font-size: 0.9rem;
    color: var(--text-tertiary);
    margin-top: 0.5rem;
}

.free-tier-footer {
    text-align: center;
    margin: 2rem 0;
    padding: 1rem;
    border-radius: 8px;
    color: var(--text-primary);
}

.free-tier-footer a {
    color: var(--info-color);
    text-decoration: none;
}

.free-tier-footer a:hover {
    text-decoration: underline;
}

.study-spaces-showcase {
    max-width: 1200px;
    margin: 8rem auto;
    padding: 0 2rem;
}

.showcase-header {
    text-align: center;
    margin-bottom: 4rem;
}

.showcase-header h2 {
    font-size: 3rem;
    font-weight: 800;
    background: linear-gradient(135deg, var(--pricing-primary), var(--error-color));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1.5rem;
}

.showcase-header p {
    font-size: 1.25rem;
    color: var(--pricing-muted);
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
}

.showcase-content {
    position: relative;
}

/* Feature Preview Window */
.showcase-visual {
    margin-bottom: 4rem;
    max-width: 1200px;
    border-radius: var(--radius-lg);
    margin: 0 auto;
    border: 1px solid var(--border-color);
}

.feature-preview {
    background: var(--pricing-card-bg);
    border-radius: 16px;
    box-shadow: 0 4px 20px var(--shadow-color);
    padding: 1.75rem;
    overflow: hidden;
}

.preview-window {
    background: var(--background-secondary);
    border-radius: 12px;
    overflow: hidden;
}

.window-header {
    background: var(--background-main);
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--pricing-border);
}

.window-controls {
    display: flex;
    gap: 6px;
    margin-right: 1rem;
}

.window-controls span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--background-hover-secondary);
}

.window-title {
    font-size: 0.875rem;
    color: var(--pricing-muted);
}

.space-preview-layout {
    display: grid;
    grid-template-columns: 220px 1fr;
    height: 300px;
}

.sidebar-preview {
    background: var(--background-main);
    padding: 1rem;
    border-right: 1px solid var(--pricing-border);
}

.preview-section {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    border-radius: 8px;
    color: var(--pricing-muted);
    transition: all 0.2s ease;
}

.preview-section:hover {
    background: var(--pricing-highlight);
    color: var(--pricing-primary);
}

.main-preview {
    padding: 1.5rem;
    background: var(--background-main);
}

.preview-chat {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.chat-bubble {
    padding: 1rem;
    border-radius: 12px;
    max-width: 80%;
}

.chat-bubble.ai {
    background: var(--pricing-highlight);
    color: var(--pricing-primary);
    align-self: flex-start;
}

.chat-bubble.user {
    background: var(--pricing-primary);
    color: var(--background-main);
    align-self: flex-end;
}

/* Feature Grid */
.feature-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 4rem 0;
}

.feature-column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.feature-card {
    background: var(--pricing-card-bg);
    padding: 2rem;
    border-radius: 16px;
    display: flex;
    gap: 1.5rem;
    transition: all 0.3s ease;
    border: 1px solid var(--pricing-border);
}

.feature-card:hover {
    box-shadow: 0 12px 24px var(--card-shadow);
}

.feature-icon {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: var(--pricing-primary);
}

.feature-content h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
    background: linear-gradient(135deg, var(--pricing-primary), var(--error-color));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.feature-content p {
    color: var(--pricing-muted);
    line-height: 1.6;
    font-size: 1rem;
}

/* Call to Action */
.showcase-cta {
    text-align: center;
    margin-top: 4rem;
    padding: 3rem;
    background: linear-gradient(135deg, var(--pricing-highlight), var(--background-main));
    border-radius: 20px;
}

.cta-content h3 {
    font-size: 2rem;
    font-weight: 700;
    background: linear-gradient(135deg, var(--pricing-primary), var(--error-color));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
}

.cta-content p {
    color: var(--pricing-muted);
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.6;
}

.cta-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    background: var(--pricing-primary);
    color: var(--background-main);
    border: none;
    border-radius: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cta-button:hover {
    background: var(--pricing-primary-hover);
    transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .feature-grid {
        grid-template-columns: 1fr;
    }
    
    .space-preview-layout {
        grid-template-columns: 180px 1fr;
    }
}

@media (max-width: 468px) {
    .showcase-header h2 {
        font-size: 2.5rem;
    }
    
    .space-preview-layout {
        grid-template-columns: 1fr;
        height: auto;
    }
    
    .sidebar-preview {
        border-right: none;
        border-bottom: 1px solid var(--pricing-border);
    }
    
    .feature-card {
        flex-direction: column;
    }
    
    .showcase-cta {
        padding: 2rem;
    }
}

@media (max-width: 480px) {
    .showcase-header h2 {
        font-size: 2rem;
    }
    
    .feature-preview {
        padding: 0.5rem;
    }
    
    .cta-button {
        width: 100%;
        justify-content: center;
    }
}

.monthly-equivalent {
    display: block;
    font-size: 2.5rem;
    font-weight: bold;
}

.annual-total {
    display: block;
    font-size: 1rem;
    color: #666;
    margin-top: 0.25rem;
}

/* Match the actual study spaces grid layout */
.space-preview-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1.5rem;
    margin: 2rem auto 0;
    max-width: 1400px;
}

/* Match the actual study space card styling */
.space-preview-card {
    position: relative;
    padding: 1.75rem;
    border-radius: 16px;
    background: linear-gradient(145deg, var(--background-main), var(--background-light));
    border: 1px solid var(--border-color);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 4px 20px var(--shadow-color);
    animation: fadeInUp 0.6s ease forwards;
    animation-delay: calc(var(--index) * 0.1s);
}

.space-preview-card:hover {
    transform: translateY(-6px);
    box-shadow: 0 12px 24px var(--shadow-primary);
}

/* Match the actual header styling */
.space-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    margin-bottom: 2rem;
    position: relative;
    background: var(--background-main);
    border-radius: 16px;
    padding: 1.75rem;
    color: var(--text-primary);
}

/* Add search bar styling to match actual implementation */
.space-preview-search {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.space-preview-search input {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    background: var(--background-main);
    font-size: 1rem;
    transition: all 0.3s ease;
}

.space-preview-search .search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-tertiary);
}

/* Add responsive adjustments */
@media (max-width: 768px) {
    .space-preview-grid {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        gap: 1rem;
    }

    .space-preview-card {
        height: auto;
        min-height: 120px;
        padding: 1.25rem;
    }

    .space-preview-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 1rem;
    }
}

/* Add animations to match actual implementation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Additional styles for space preview cards */
.space-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--text-primary);
}

.space-description {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin: 0;
}

.space-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.space-stats {
    display: flex;
    gap: 1rem;
}

.stat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-tertiary);
    font-size: 0.85rem;
}

.arrow-icon {
    color: var(--text-tertiary);
    transition: transform 0.2s ease;
}

.space-preview-card:hover .arrow-icon {
    transform: translateX(4px);
    color: var(--primary-color);
}

/* Header styling */
.header-left {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.return-to-chats {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    border-radius: 12px;
    color: var(--text-tertiary);
    background: var(--background-light);
    border: 1px solid var(--border-light);
    text-decoration: none;
}

.create-space-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    color: var(--text-light);
    background: var(--primary-gradient);
    border: none;
    border-radius: 0.75rem;
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

@media (max-width: 768px) {
    .pricing-cards {
        gap: 1.5rem;
        padding: 1rem;
    }
}

/* Add new styles for free trial badge */
.free-trial-badge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(135deg, var(--primary-color), var(--error-color));
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    font-weight: 600;
    font-size: 0.875rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1;
    white-space: nowrap;
}
