:root {
    --welcome-gradient-start: #F4806B;
    --welcome-gradient-end: #ff6b52;
    --welcome-text: #333333;
    --welcome-background: #ffffff;
}

.welcome-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--glass-background);
    backdrop-filter: var(--blur-md);
    padding: var(--spacing-xl);
}

.welcome-content {
    background: var(--background-main);
    border: 1px solid var(--border-glass);
    border-radius: var(--radius-2xl);
    padding: var(--spacing-xl) var(--spacing-xl);
    width: 100%;
    max-width: 520px;
    box-shadow: var(--shadow-elevated);
    position: relative;
    overflow: hidden;
}

.welcome-header {
    margin-bottom: var(--spacing-xl);
}

.welcome-header h1 {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    background: var(--primary-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: var(--spacing-xs);
}

.welcome-header h2 {
    font-size: var(--font-size-lg);
    color: var(--text-secondary);
    font-weight: var(--font-weight-medium);
}

.welcome-message {
    font-size: var(--font-size-base);
    color: var(--text-secondary);
    margin-bottom: var(--spacing-lg);
}

.progress-container {
    position: relative;
    margin: var(--spacing-xl) 0;
}

.progress-bar {
    height: 6px;
    background: var(--background-light);
    border-radius: var(--radius-full);
    overflow: hidden;
}

.progress {
    height: 100%;
    background: var(--primary-gradient);
    border-radius: var(--radius-full);
}

.start-button {
    width: 100%;
    padding: var(--spacing-md) var(--spacing-xl);
    background: var(--primary-gradient);
    color: var(--text-light);
    border: none;
    border-radius: var(--radius-lg);
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
    transition: all var(--transition-base);
}

.start-button:hover {
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-primary-hover);
}

.confetti {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
