:root {
  /* Brand Colors - Refined for sophistication */
  --primary-color: #E07A68;      /* More muted coral/terracotta */
  --primary-light: #EEA193;      /* Softer, less vibrant hover state */
  --primary-dark: #C4604F;       /* Deeper, richer active state */
  --primary-gradient: linear-gradient(135deg, #E07A68 0%, #C4604F 100%);
  
  /* Background Colors - Enhanced hierarchy */
  --background-main: #ffffff;
  --background-light: #f8f9fa;  /* Slightly lighter for better contrast */
  --background-light-rgb: 248, 249, 250;
  --background-dark: #e9ecef;   /* Adjusted for better depth */
  --background-overlay: rgba(255, 255, 255, 0.98);  /* More opaque for readability */
  --background-hover: rgba(243, 244, 246, 0.8);  /* More translucent */
  --background-hover-primary: rgba(255, 241, 239, 0.9);  /* Warmer, glossier hover */
  --background-hover-secondary: rgba(255, 235, 232, 0.95); /* Deeper, glossier hover */
  
  /* Text Colors - Improved readability */
  --text-primary: #1a1d21;     /* Darker for better contrast */
  --text-secondary: #4b5563;   /* Adjusted for WCAG compliance */
  --text-tertiary: #9ca3af;    /* Better distinction */
  --text-light: #ffffff;
  --text-dark: #111827;        /* Deeper for emphasis */
  --text-muted: #6b7280;       /* Better for supplementary text */
  
  /* Border Colors - Enhanced definition and hierarchy */
  --border-color: #e2e4e9;            /* Slightly cooler gray for base borders */
  --border-light: #f0f1f4;            /* Lighter for subtle separators */
  --border-strong: #d1d5db;           /* Added: Stronger borders for emphasis */
  --border-primary: rgba(244, 128, 107, 0.45);  /* Increased opacity for better visibility */
  --border-primary-hover: rgba(244, 128, 107, 0.65);
  --border-focus: rgba(244, 128, 107, 0.8);     /* Added: Strong border for focus states */
  --border-interactive: #e5e7eb;      /* Added: For interactive elements */
  --border-interactive-hover: #d1d5db; /* Added: Hover state for interactive borders */
  
  /* Shadow Colors - Refined depth */
  --shadow-color: rgba(0, 0, 0, 0.08);
  --shadow-primary: rgba(244, 128, 107, 0.15);
  --shadow-primary-hover: rgba(244, 128, 107, 0.25);
  
  /* Message Colors - Better distinction */
  --message-user-bg: #f8f9fa;
  --message-user-border: #e5e7eb;
  --message-ai-bg: #ffffff;
  --message-loading-color: #4b5563;
  
  /* Status Colors - Enhanced visibility */
  --success-color: #22c55e;    /* More vibrant green */
  --success-dark: #16a34a;
  --warning-color: #f59e0b;    /* Warmer yellow */
  --error-color: #ef4444;      /* More noticeable red */
  --info-color: #3b82f6;       /* Brighter blue */
  
  /* UI Component Colors - Better interaction states */
  --dropdown-bg: rgba(255, 255, 255, 0.98);
  --dropdown-hover: rgba(0, 0, 0, 0.04);  /* Changed: More neutral hover */
  --dropdown-text: var(--text-primary);    /* Added: Explicit dropdown text color */
  --dropdown-text-hover: var(--text-dark); /* Added: Subtle text enhancement on hover */
  --button-disabled: #d1d5db;
  --input-focus: rgba(244, 128, 107, 0.15);
  
  /* Sidebar Variables */
  --sidebar-width: 250px;
  --sidebar-bg: #f8f9fa;          /* Changed: Matches background-light */
  --sidebar-text: #1a1d21;        /* Changed: Matches text-primary */
  --sidebar-hover: #e9ecef;       /* Changed: Matches background-dark */
  --sidebar-active: #ffffff;      /* Changed: Matches background-main */
  --sidebar-border: #e2e4e9;      /* Changed: Matches border-color */
  
    /* Resources Variables */
    --resources-width: 40%;
    --resources-width-expanded: 50%;
    --resources-width-large: 25%;
    --resources-width-medium: 28%;
    --resources-border-color: #f4806b;
  
  /* Typography System */
  --font-primary: 'Inter', 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-secondary: 'SF Pro Display', 'Helvetica Neue', 'Arial Nova', 'Noto Sans', system-ui, sans-serif;
  --font-mono: 'JetBrains Mono', 'Fira Code', 'Cascadia Code', 'Source Code Pro', 'IBM Plex Mono', 'Roboto Mono', Consolas, monospace;
  
  /* Font Weights */
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 550;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;

  /* Font Sizes - Refined for better readability and consistency */
  --font-size-xxs: clamp(0.6875rem, 0.65rem + 0.2vw, 0.75rem);   /* 11px - 12px */
  --font-size-xs: clamp(0.75rem, 0.7rem + 0.25vw, 0.813rem);    /* 12px - 13px */
  --font-size-sm: clamp(0.875rem, 0.813rem + 0.25vw, 0.938rem); /* 14px - 15px */
  --font-size-base: clamp(0.95rem, 0.9rem + 0.25vw, 1rem);      /* 15px - 16px */
  --font-size-lg: clamp(1.25rem, 1.2rem + 0.25vw, 1.35rem);     /* Increased */
  --font-size-xl: clamp(1.4rem, 1.3rem + 0.25vw, 1.6rem);       /* Increased */
  --font-size-2xl: clamp(1.7rem, 1.5rem + 0.5vw, 2.1rem);       /* Increased */
  --font-size-3xl: clamp(2.2rem, 1.9rem + 1vw, 2.7rem);         /* Increased */
  --font-size-4xl: clamp(2.7rem, 2.3rem + 1vw, 3.2rem);         /* New size */

  /* Line Heights - Adjusted to complement new font sizes */
  --line-height-tight: 1.2;     /* For headings */
  --line-height-snug: 1.35;     /* For compact text */
  --line-height-base: 1.65;      /* Slightly reduced for bolder text */
  --line-height-relaxed: 1.75;   /* Slightly reduced */
  --line-height-loose: 1.75;    /* For maximum readability */

  /* Letter Spacing */
  --letter-spacing-tight: -0.025em;
  --letter-spacing-normal: 0em;
  --letter-spacing-wide: 0.025em;
  --letter-spacing-wider: 0.05em;
  --letter-spacing-widest: 0.1em;

  /* Text Colors - Enhanced for better readability */
  --text-primary: hsl(220, 15%, 16%);    /* Darker for better readability */
  --text-secondary: hsl(220, 15%, 40%);  /* Adjusted for better hierarchy */
  --text-tertiary: hsl(220, 15%, 55%);
  --text-light: hsl(0, 0%, 100%);
  --text-dark: hsl(220, 15%, 9%);
  --text-muted: hsl(220, 15%, 45%);

  /* Paragraph Spacing */
  --paragraph-spacing: 1.2em;    /* More breathing room between paragraphs */
  --paragraph-spacing-loose: 2em;
  
  /* Text Shadows */
  --text-shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.1);
  --text-shadow-base: 0 2px 4px rgba(0, 0, 0, 0.1);
  --text-shadow-lg: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  
  /* Border Radius */
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 16px;
  --radius-2xl: 20px;
  --radius-3xl: 24px;
  --radius-4xl: 28px;
  --radius-5xl: 32px;
  --radius-full: 9999px;
  
  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-base: 0.3s ease;
  --transition-slow: 0.5s ease;
  --transition-bezier: cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Z-index Layers */
  --z-dropdown: 1000;
  --z-sticky: 1020;
  --z-fixed: 1030;
  --z-modal: 1040;
  --z-popover: 1050;
  --z-tooltip: 1060;
  
  /* Blur Effects */
  --blur-sm: blur(4px);
  --blur-md: blur(8px);
  --blur-lg: blur(16px);
  
  /* Animation Curves */
  --ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --ease-out: cubic-bezier(0, 0, 0.2, 1);
  --ease-in: cubic-bezier(0.4, 0, 1, 1);
  
  /* Interactive States */
  --hover-opacity: 0.8;
  --active-opacity: 0.7;
  --disabled-opacity: 0.5;
  --focus-ring: 0 0 0 3px var(--primary-color);
  --focus-ring-offset: 2px;
  
  /* Form Elements */
  --input-bg: var(--background-main);
  --input-border: var(--border-color);
  --input-placeholder: var(--text-tertiary);
  --input-disabled-bg: var(--background-light);
  --input-error-border: var(--error-color);
  --input-success-border: var(--success-color);
  
  /* Button States */
  --button-hover-transform: translateY(-1px);
  --button-active-transform: translateY(1px);
  --button-focus-shadow: 0 0 0 3px var(--primary-color);
  
  /* Scrollbar */
  --scrollbar-width: 8px;
  --scrollbar-track: var(--background-light);
  --scrollbar-thumb: var(--text-tertiary);
  --scrollbar-thumb-hover: var(--text-secondary);
  
  /* Loading States */
  --skeleton-bg: var(--background-light);
  --skeleton-highlight: var(--background-hover);
  --loading-spinner-color: var(--primary-color);
  
  /* Selection */
  --selection-bg: var(--primary-color);
  --selection-text: var(--text-light);
  
  /* Enhanced transitions for seamless theme switching */
  transition: 
    background-color 0.3s var(--ease-out),
    color 0.3s var(--ease-out),
    border-color 0.3s var(--ease-out),
    box-shadow 0.3s var(--ease-out),
    transform 0.2s var(--ease-out),
    opacity 0.2s var(--ease-out),
    fill 0.3s var(--ease-out),
    stroke 0.3s var(--ease-out);
  
  /* Apply transitions to common interactive elements */
  & button,
  & input,
  & select,
  & textarea,
  & a {
    transition: 
      background-color 0.2s var(--ease-out),
      color 0.2s var(--ease-out),
      border-color 0.2s var(--ease-out),
      box-shadow 0.2s var(--ease-out),
      transform 0.1s var(--ease-out),
      opacity 0.2s var(--ease-out);
  }
  
  /* Smooth transitions for SVG elements */
  & svg {
    transition: 
      fill 0.3s var(--ease-out),
      stroke 0.3s var(--ease-out),
      opacity 0.2s var(--ease-out),
      transform 0.2s var(--ease-out);
  }
  
  /* Glass Effect */
  --glass-background: rgba(255, 255, 255, 0.85);
  --border-glass: rgba(255, 255, 255, 0.2);
  --shadow-elevated: 
    0 10px 20px rgba(0, 0, 0, 0.1),
    0 6px 6px rgba(0, 0, 0, 0.06),
    0 0 100px rgba(0, 0, 0, 0.02);

  /* Text Rendering */
  text-rendering: geometricPrecision;  /* Changed from optimizeLegibility */
  -webkit-font-smoothing: subpixel-antialiased;  /* Changed from antialiased */
  -moz-osx-font-smoothing: auto;  /* Changed from grayscale */

  /* Content Spacing */
  --content-spacing-xs: 0.5rem;    /* 8px */
  --content-spacing-sm: 0.75rem;   /* 12px */
  --content-spacing-md: 1rem;      /* 16px */
  --content-spacing-lg: 1.25rem;   /* 20px */
  --content-spacing-xl: 1.5rem;    /* 24px */
  --content-spacing-2xl: 2rem;     /* 32px */
  
  /* Line Heights - Refined */
  --line-height-tight: 1.25;      /* Headings */
  --line-height-snug: 1.375;      /* Code blocks */
  --line-height-base: 1.5;        /* Normal text */
  --line-height-relaxed: 1.625;   /* Paragraphs */
  --line-height-loose: 1.75;      /* Lists */
  
  /* Paragraph Spacing */
  --paragraph-spacing-base: 1.25rem;
  --paragraph-spacing-large: 1.5rem;
  
  /* List Item Spacing */
  --list-item-spacing: 0.75rem;
  --nested-list-spacing: 1rem;
}

[data-theme="dark"] {
  /* Brand Colors - Adjusted for dark mode */
  --primary-color: #D47566;      /* Muted, warmer tone for dark mode */
  --primary-light: #E39485;      /* Softer highlight */
  --primary-dark: #B35647;       /* Rich shadow tone */
  --primary-gradient: linear-gradient(135deg, #D47566 0%, #B35647 100%);
  
  /* Background Colors - Softer, more sophisticated hierarchy */
  --background-main: #1A1B1E;     /* Changed: Warmer, less harsh dark */
  --background-light: #242529;    /* Changed: Subtle elevation */
  --background-light-rgb: 36, 37, 41;
  --background-dark: #141517;     /* Changed: Deeper but not too harsh */
  --background-overlay: rgba(26, 27, 30, 0.97);
  --background-hover: rgba(45, 46, 51, 0.85);
  --background-hover-primary: rgba(45, 31, 29, 0.9);
  --background-hover-secondary: rgba(38, 27, 25, 0.95);
  /* Text Colors - Improved readability */
  --text-primary: #F0F0F1;  /* Brighter for better contrast */
  --text-secondary: #B8B8B9;
  --text-tertiary: #949495;
  --text-light: #FFFFFF;
  --text-dark: #F0F0F1;
  --text-muted: #AEAEB0;  /* Slightly brighter for better readability */
  
  /* Border Colors - Enhanced contrast and depth */
  --border-color: #2d2d31;            /* Slightly lighter for better visibility */
  --border-light: #363639;            /* Subtle but visible separators */
  --border-strong: #454548;           /* Added: For stronger emphasis in dark mode */
  --border-primary: rgba(255, 143, 125, 0.35);  /* Warmer, more visible */
  --border-primary-hover: rgba(255, 143, 125, 0.5);
  --border-focus: rgba(255, 143, 125, 0.7);     /* Added: Strong border for focus states */
  --border-interactive: #3a3a3e;      /* Added: For interactive elements */
  --border-interactive-hover: #454548; /* Added: Hover state for interactive borders */
  
  /* Shadow Colors - Enhanced depth */
  --shadow-color: rgba(0, 0, 0, 0.35);
  --shadow-primary: rgba(255, 143, 125, 0.2);
  --shadow-primary-hover: rgba(255, 143, 125, 0.3);
  
  /* Message Colors - Better distinction */
  --message-user-bg: #1E1E21;
  --message-user-border: #2A2A2D;
  --message-ai-bg: #17171A;
  --message-loading-color: #B8B8B9;
  
  /* Status Colors - Adjusted for dark mode visibility */
  --success-color: #4AE99D;  /* Brighter green */
  --success-dark: #35CF84;
  --warning-color: #FFE066;  /* Warmer yellow */
  --error-color: #ef4444;    /* Brighter red */
  --info-color: #66B9FF;     /* Brighter blue */
  
  /* UI Component Colors - Enhanced interaction states */
  --dropdown-bg: rgba(30, 30, 33, 0.97);
  --dropdown-hover: rgba(255, 255, 255, 0.03);  /* Changed: More subtle hover */
  --dropdown-text: var(--text-primary);         /* Added: Explicit dropdown text color */
  --dropdown-text-hover: var(--text-light);     /* Added: Subtle text enhancement on hover */
  --button-disabled: #3A3A3D;
  --input-focus: rgba(255, 143, 125, 0.3);
  
  /* Sidebar Variables - Coordinated with main colors */
  --sidebar-bg: #141517;          /* Changed: Matches background-dark */
  --sidebar-text: #F0F0F1;
  --sidebar-hover: #1E1E21;
  --sidebar-active: #26262A;
  --sidebar-border: #2A2A2D;
  
  /* Resources Variables - Enhanced borders */
  --resources-border-color: #FF8F7D;
  
  /* Interactive States - Dark Mode */
  --focus-ring: 0 0 0 3px var(--primary-light);
  
  /* Form Elements - Dark Mode */
  --input-bg: var(--background-dark);
  --input-disabled-bg: var(--background-main);
  
  /* Scrollbar - Dark Mode */
  --scrollbar-track: var(--background-light);
  --scrollbar-thumb: var(--text-tertiary);
  --scrollbar-thumb-hover: var(--text-secondary);
  
  /* Loading States - Dark Mode */
  --skeleton-bg: var(--background-dark);
  --skeleton-highlight: var(--background-hover);
  
  /* Glass Effect - Dark */
  --glass-background: rgba(18, 18, 20, 0.85);
  --border-glass: rgba(255, 255, 255, 0.1);

  /* Text Rendering */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Enhanced Dark Mode Text Colors */
  --text-primary: hsl(220, 15%, 95%);    /* Brighter for better contrast */
  --text-dark: hsl(220, 15%, 98%);       /* Even brighter for headlines */
}