.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-modal);
  backdrop-filter: var(--blur-md);
}

.confirmation-modal-content {
  background-color: var(--background-main);
  padding: 1.5rem;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-elevated);
  max-width: 400px;
  width: 90%;
  border: 1px solid var(--border-color);
}

.confirmation-modal-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.confirmation-modal-content p {
  color: var(--text-primary);
  font-size: var(--font-size-base);
  margin-bottom: 0.5rem;
  text-align: center;
  line-height: var(--line-height-relaxed);
}

.confirmation-modal-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.confirmation-modal-actions .button-main {
  min-width: 100px;
  margin: 0;
}

.confirmation-modal-actions .cancel-button {
  min-width: 100px;
  margin: 0;
}

.confirmation-modal-actions .confirmation-confirm-button {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  padding: 0.8rem 1.2rem;
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: all var(--transition-base);
  margin-top: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
  max-width: 200px;
}

.confirmation-modal-actions .confirmation-confirm-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
}

.confirmation-modal-actions .confirmation-cancel-button {
  background-color: var(--background-main);
  border: 1px solid var(--text-primary);
  color: var(--text-light);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: all var(--transition-fast) var(--ease-out);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.confirmation-modal-actions .confirmation-cancel-button:hover {
  background-color: var(--background-light);
}