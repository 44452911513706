.send-button-icon {
  color: var(--background-main);
  transition: color var(--transition-fast);
  fill: var(--text-primary);
}

.send-button-icon:hover {
  transform: scale(1.1);
}


.send-button-icon.disabled:hover {
  transform: scale(1);
}

.regenerate-button-content {
  display: flex;
  align-items: center;
  
  gap: var(--spacing-xs);
}

.regenerate-button-content .mode-text {
  padding: 8;
  opacity: 0;
  transition: opacity 0.2s ease, width 0.2s ease;
}

.mode-selector:hover .mode-text {
  width: auto;
  margin-left: var(--spacing-xs);
  opacity: 1;
}

.test-button {
  background-color: var(--background-light);
  color: var(--text-primary);
  padding: 0px 8px;
  border-radius: 24px;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
}

.test-button:hover {
  background-color: var(--glass-background-hover);
}

.test-button-content svg {
  color: var(--text-muted);
}

.test-button-content {
  display: flex;
  align-items: center;
  color: var(--text-muted);
  font-size: var(--font-size-xs);
  gap: var(--spacing-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-snug);
}

.test-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-indicator-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--text-primary);
}

.wrapper-button-left {
  background-color: var(--background-light);
  color: var(--text-muted);
  padding: var(--spacing-sm) var(--spacing-sm);
  font-size: var(--font-size-sm);
  border-radius: var(--radius-2xl);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  transition: all var(--transition-base);
  z-index: var(--z-dropdown);
  font-weight: var(--font-weight-medium);
  box-shadow: var(--shadow-sm);
  width: fit-content;
  border: 1px solid var(--border-color);
}

.wrapper-button-left.active {
  background-color: var(--background-dark);
}

.wrapper-button-left.chat {
  padding: 8px;
}

.wrapper-button-left:hover {
  background-color: var(--background-dark);
}

.wrapper-button-left svg {
  margin-left: 0rem;
  margin-right: 0rem;
  color: var(--text-muted);
}