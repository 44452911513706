.settings-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1.5rem;
  font-family: var(--font-primary);
  color: var(--text-primary);
  background-color: var(--background-main);
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 768px) or (max-height: 768px) {
  .settings-container {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: none;
    height: auto;
  }
}

.settings-container .settings-header h1 {
  font-size: var(--font-size-xl);
  font-weight: 700;
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .settings-container .settings-header h1 {
    font-size: var(--font-size-lg);
  }
}

.settings-container .settings-content {
  display: flex;
  gap: 1.5rem;
  min-height: 100vh;
  height: auto;
}

.settings-nav {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.settings-container .nav-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1rem;
  border-radius: var(--radius-lg);
  cursor: pointer;
  color: var(--text-primary);
  background: var(--glass-background);
  border: 1px solid var(--border-glass);
  width: 100%;
  text-align: left;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  backdrop-filter: blur(8px);
  transition: all var(--transition-base) var(--ease-out);
}

.settings-container .nav-item:hover {
  background: var(--background-hover);
}

.settings-container .nav-item.active {
  background: var(--background-hover-primary);
  color: var(--primary-color);
  font-weight: var(--font-weight-semibold);
}

.settings-container .nav-item.logout {
  background: var(--background-light);
  border: 1px solid var(--border-light);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.settings-container .nav-item.logout:hover {
  color: var(--error-color);
}

.settings-container .nav-item.logout:hover svg {
  color: var(--error-color);
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.settings-container .settings-main {
  flex: 1;
  height: auto;
  min-height: 100%;
  overflow: auto;
  min-height: calc(100vh);
}

.group-wrap {
  display: flex;
  gap: 1rem;
}

.group-wrap .form-group {
  flex: 1;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.4rem;
  font-weight: 500;
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.settings-container input[type="text"],
.settings-container input[type="email"],
.settings-container input[type="tel"], 
.settings-container input[type="password"],
.settings-container select {
  width: 100%;
  padding: 0.9rem 1rem;
  border: 1.5px solid var(--border-interactive);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--input-bg);
  transition: all var(--transition-base) var(--ease-out);
  box-shadow: 0 2px 4px var(--shadow-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: var(--font-primary);
  letter-spacing: var(--letter-spacing-normal);
}

.settings-container input::placeholder {
  color: var(--text-tertiary);
  opacity: 0.8;
}

.settings-container select {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 3rem;
  cursor: pointer;
}

.settings-container input[type="text"]:hover,
.settings-container input[type="email"]:hover,
.settings-container input[type="tel"]:hover,
.settings-container input[type="password"]:hover,
.settings-container select:hover {
  border-color: var(--border-interactive-hover);
  box-shadow: 0 3px 6px var(--shadow-color);
}

.settings-container input[type="text"]:focus,
.settings-container input[type="email"]:focus,
.settings-container input[type="tel"]:focus,
.settings-container input[type="password"]:focus,
.settings-container select:focus {
  outline: none;
  background-color: var(--background-main);
}

.settings-container input:disabled,
.settings-container select:disabled {
  background-color: var(--input-disabled-bg);
  border-color: var(--border-color);
  cursor: not-allowed;
  opacity: var(--disabled-opacity);
}

.settings-container .button-main {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  padding: 0.8rem 1.2rem;
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  font-weight: 600;
  cursor: pointer;
  transition: all var(--transition-base);
  margin-top: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
  max-width: 200px;
}

.settings-container .button-main:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
}

.button-main.delete {
  background-color: var(--background-light);
  color: var(--error-color);
  padding: 0.8rem 1rem;
  width: 100%;
}

.button-main.delete:hover {
  background-color: var(--background-hover);
  color: var(--error-color);
  border-color: var(--error-color);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.settings-container .cancel-button {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: all var(--transition-fast) var(--ease-out);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.settings-container .cancel-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
}

.settings-container .error-message {
  color: var(--error-color);
  margin-bottom: 0.8rem;
}

.settings-container .success-message {
  color: var(--success-color);
  margin-bottom: 0.8rem;
}

.settings-container .loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.settings-container .spinner-icon {
  animation: spin 1s linear infinite;
  margin-right: 0.4rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.settings-container .subscription-info {
  background: linear-gradient(145deg, var(--background-main), var(--background-light));
  border-radius: var(--radius-lg);
  padding: 1.2rem;
  box-shadow: 0 2px 10px var(--shadow-color);
  border: 1px solid var(--border-primary);
  margin-bottom: 1.5rem;
}

.settings-container .subscription-info p {
  font-size: var(--font-size-sm);
  margin-bottom: 1rem;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.settings-container .subscription-info p strong {
  color: var(--primary-color);
  font-weight: 600;
}

.settings-container .subscription-actions {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;
}

.settings-container .subscription-details-link {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: var(--font-size-xs);
  transition: all var(--transition-base);
  position: relative;
  padding-left: 1.2rem;
  display: inline-block;
}

.settings-container .subscription-details-link::before {
  content: '→';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform var(--transition-base);
}

.settings-container .subscription-details-link:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.settings-container .subscription-details-link:hover::before {
  transform: translate(4px, -50%);
}

.settings-container .trial-disclaimer {
  font-size: var(--font-size-xs);
  color: var(--text-muted);
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.settings-container .trial-disclaimer p {
  margin: 0;
}

@media (max-width: 768px) {
  .settings-container {
    padding: 0;
    height: auto;
    min-height: 100vh;
  }

  .settings-header {
    padding: 0.8rem;
  }

  .settings-container .settings-content {
    flex-direction: column;
    min-height: calc(100vh - 80px);
  }

  .settings-container .settings-nav {
    flex: none;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 0.4rem;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .settings-container .settings-nav::-webkit-scrollbar {
    display: none;
  }

  .settings-container .nav-item {
    flex: 0 0 content;
    padding: 0.4rem 0.8rem;
    white-space: nowrap;
    font-size: var(--font-size-xs);
    margin-right: 0.4rem;
  }

  .settings-container .settings-main {
    width: 100%;
  }

  .settings-section {
    background-color: var(--background-main);
  }

  .settings-nav {
    margin-top: -1rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .settings-section {
    padding: 0.8rem;
  }

  .settings-container .subscription-info {
    padding: 0.8rem;
  }

  .settings-content {
    background-color: var(--background-main);
  }

  .settings-main {
    margin-top: -1rem;
    background-color: var(--background-main);
  }

  .settings-container .button-main {
    width: 100%;
    margin-top: 0.8rem;
  }

  .settings-container .cancel-button {
    width: 100%;
    margin-top: 0.8rem;
  }

  .subscription-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 0.4rem;
  }
  
  .subscription-details-link {
    text-align: center;
  }

  .settings-container .subscription-info {
    padding: 1rem;
  }

  .settings-container .subscription-actions {
    grid-template-columns: 1fr;
    gap: 0.8rem;
  }

  .settings-container .subscription-actions .button-main {
    width: 100%;
    min-width: unset;
  }

  .settings-container .subscription-details-link {
    text-align: center;
    padding-left: 0;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-top: 1px solid var(--border-color);
  }

  .settings-container .subscription-details-link::before {
    display: none;
  }
}

.subscription-promo {
  background: linear-gradient(135deg, var(--background-main) 0%, var(--background-hover-secondary) 100%);
  border-radius: var(--radius-xl);
  padding: 2rem;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 107, 107, 0.2);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.subscription-promo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at top right,
    rgba(255, 107, 107, 0.1),
    transparent 60%
  );
  pointer-events: none;
}

.promo-title {
  max-width: 500px;
  margin-bottom: 2rem;
}

.promo-title h3 {
  font-size: var(--font-size-xl);
  font-weight: 700;
  background: linear-gradient(135deg, #FF6B6B 0%, #FF8E8E 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.75rem;
}

.promo-title p {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  line-height: 1.5;
}

.feature-list {
  margin-bottom: 2rem;
}

.feature-list-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1rem;
}

.premium-feature {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: 500;
  color: var(--text-primary);
  transition: all 0.3s ease;
}

.premium-feature:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 107, 107, 0.2);
}

.premium-feature svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 1rem;
  color: #FF6B6B;
}

.settings-container .upgrade-button {
  display: inline-flex;
  align-items: center;
  padding: 0.875rem 1.5rem;
  background: linear-gradient(135deg, #FF6B6B 0%, #FF8E8E 100%);
  color: white;
  font-weight: 600;
  border-radius: var(--radius-lg);
  text-decoration: none;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 4px 12px rgba(255, 107, 107, 0.3);
}

.settings-container .upgrade-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(255, 107, 107, 0.4);
}

@media (max-width: 768px) {
  .subscription-promo {
    padding: 1.5rem;
    border-radius: var(--radius-lg);
  }

  .promo-title h3 {
    font-size: var(--font-size-lg);
  }

  .feature-list-items {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .premium-feature {
    padding: 0.875rem;
  }

  .upgrade-button {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .settings-container .delete-account-button {
    width: 100%;
  }
}

.settings-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: space-between;
}

.settings-header-left {
  display: flex;
  align-items: center;
}

.help-button {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-light);
  cursor: pointer;
  padding: 0.6rem 1rem;
  border-radius: var(--radius-md);
  font-size: var(--font-size-xs);
  font-weight: 500;
  transition: all var(--transition-base);
  box-shadow: 0 1px 3px var(--shadow-color);
}

.help-button:hover {
  background-color: var(--background-hover);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px var(--shadow-color);
}

.help-button svg {
  color: var(--text-secondary);
}

.return-to-chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--text-primary);
  text-decoration: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: all var(--transition-base);
  margin-right: 0.8rem;
}

.return-to-chat-button:hover {
  background-color: var(--background-hover);
}

.return-to-chat-button span {
  display: none;
}

@media (max-width: 768px) {
  .settings-header {
    padding: 0.8rem;
  }

  .return-to-chat-button {
    width: 28px;
    height: 28px;
  }

  .promo-grid {
    display: none;
  }
}

.settings-row {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 0.8rem;
}

.settings-row > span,
.settings-row > div {
  flex: 1;
  padding-right: 0.8rem;
  font-size: var(--font-size-sm);
}

.settings-card {
  margin-bottom: 1.5rem;
  padding: var(--spacing-xl);
  border-radius: var(--radius-xl);
  border: 1px solid var(--border-color);
  background: var(--glass-background);
  z-index: 1000;
  transition: all var(--transition-base) var(--ease-out);
}

.settings-card.delete {
  background: var(--background-light);
  border: 1px solid var(--border-color);
}

.settings-card:last-child {
  margin-bottom: 0;
}

.settings-card h3 {
  font-size: var(--font-size-base);
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-weight: 600;
}

.setting-description {
  color: var(--text-secondary);
  font-size: var(--font-size-xs);
  margin-top: 0.2rem;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.beta-features-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.beta-feature-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-lg);
}

.beta-feature-header h3 {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-tight);
  margin: 0;
  margin-bottom: var(--spacing-xs);
  color: var(--text-dark);
}

.beta-tag {
  background: var(--primary-gradient);
  color: var(--text-light);
  padding: 0.25em 0.75em;
  border-radius: var(--radius-full);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-wide);
  margin-left: var(--spacing-xs);
  box-shadow: 0 2px 8px rgba(244, 128, 107, 0.25);
}

.settings-card p {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-relaxed);
  margin: 0;
  margin-bottom: var(--spacing-sm);
}

/* Toggle Switch Styles */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
  flex-shrink: 0;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-dark);
  transition: all var(--transition-base) var(--ease-out);
  border-radius: var(--radius-full);
  border: 2px solid var(--border-interactive);
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 3px;
  background-color: var(--text-light); /* Ensures the toggle is visible against the slider */
  transition: var(--transition-base);
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background: var(--text-primary) /* Consistent use of gradient for active state */
}

input:checked + .toggle-slider:before {
  transform: translateX(14px); /* Adjusted to prevent overextending */
}

@media (max-width: 768px) {
  .beta-features-container {
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
  }

  .settings-card {
    padding: var(--spacing-lg);
  }
}

.toggle-switch.disabled {
  opacity: var(--disabled-opacity);
  cursor: not-allowed;
}

.toggle-switch.disabled .toggle-slider {
  background-color: var(--button-disabled);
  border-color: var(--border-color);
}

.upgrade-link {
  color: var(--primary-color);
  text-decoration: none;
  margin-top: 1rem;
  display: inline-block;
  font-weight: 500;
}

.upgrade-link:hover {
  text-decoration: underline;
}

.beta-disclaimer {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  margin-top: 0.5rem;
  background: rgba(255, 107, 107, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(255, 107, 107, 0.2);
}

.beta-disclaimer .disclaimer-icon {
  flex-shrink: 0;
  color: #FF6B6B;
  margin-top: 0.2rem;
}

.beta-disclaimer p {
  margin: 0;
  font-size: 0.95rem;
  color: var(--text-primary);
  line-height: 1.5;
}

@media (max-width: 768px) {
  .beta-disclaimer {
    padding: 0.875rem;
    margin-bottom: 1rem;
  }
}

.settings-container select {
  min-width: 140px;
}

.settings-container .memory-description {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin-bottom: 1rem;
}

.settings-container .memory-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 1rem;
}

.settings-container .memory-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem;
  background-color: var(--background-main);
  border: 1px solid var(--border-light);
  border-radius: var(--radius-md);
  transition: all var(--transition-base);
}

.settings-container .memory-item:hover {
  border-color: var(--border-color);
}

.settings-container .memory-preview {
  flex: 1;
  cursor: pointer;
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  min-height: 24px;
  padding: 0.2rem;
}

.settings-container .memory-preview:empty::before {
  content: 'Click to add details...';
  color: var(--text-secondary);
  font-style: italic;
}

.settings-container .memory-item textarea {
  flex: 1;
  padding: 0.2rem;
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--background-main);
  resize: none;
  min-height: 30px;
  max-height: 200px;
  outline: none;
  border: none;
  box-shadow: none;
}

.settings-container .memory-item textarea:focus {
  box-shadow: none;
}


.settings-container .memory-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.4rem;
  border-radius: var(--radius-sm);
  transition: all var(--transition-base);
}

.settings-container .memory-button:hover {
  color: var(--error-color);
  background-color: var(--background-hover);
}

.settings-container .memory-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.settings-container .memory-add-button {
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  color: var(--text-primary);
  padding: 0.6rem;
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: all var(--transition-base);
}

.settings-container .memory-add-button:hover {
  background-color: var(--background-hover);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px var(--shadow-color);
}

.settings-container .empty-memory-message {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  text-align: center;
  padding: 1rem;
  background-color: var(--background-light);
  border-radius: var(--radius-md);
  border: 1px dashed var(--border-light);
}

@media (max-width: 768px) {
  .settings-container .memory-section {
    padding: 1rem;
    margin-top: 1.5rem;
  }
  
  .settings-container .memory-item {
    padding: 0.6rem;
  }
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.password-form {
  margin-top: 1rem;
}

.password-form .form-group {
  margin-bottom: 1rem;
}

.error-message {
  color: #dc3545;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.success-message {
  color: #28a745;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.nar {
  max-width: 300px;
}