* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-normal);
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-feature-settings: "liga" 1, "kern" 1, "calt" 1, "ss01" 1;
}

/* Heading Styles - More Imposing */
.ai-response-heading {
  font-weight: 600;
  line-height: 1.3;
  color: var(--text-primary);
}

/* Enhanced Header Sizes */
.ai-response-heading-1 { 
  font-size: calc(var(--font-size-2xl) * 1.1);
  font-weight: var(--font-weight-semibold);
  letter-spacing: -0.03em;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.ai-response-heading-2 { 
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.ai-response-heading-3 { 
  font-size: 1.5em;  /* Reduced from 1.6em */
  text-align: left; 
  margin-top: 1.75rem;
  margin-bottom: 1rem;
}

.ai-response-heading-4 { 
  font-size: 1.25em;  /* Reduced from 1.3em */
  text-align: left; 
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.ai-response-heading-5 { 
  font-size: 1.1em;  /* Reduced from 1.15em */
  text-align: left; 
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
}

.ai-response-heading-6 { 
  font-size: 1em;  /* Reduced from 1.05em */
  text-align: left; 
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.ai-response-content {
  font-family: var(--font-primary);
  font-size: var(--font-size-sm); /* Reduced font size */
  line-height: var(--line-height-base); /* Reduced line height */
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-normal);
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-feature-settings: "liga" 1, "kern" 1, "calt" 1, "ss01" 1;
  z-index: 1;
  padding: 0 var(--spacing-xs);
}

.ai-response-content a {
  color: var(--info-color);
  text-decoration: none;
  padding-bottom: 0.1em;
}

/* Paragraph Spacing */
.ai-response-paragraph {
  margin-bottom: 1rem; /* Reduced margin */
  line-height: var(--line-height-relaxed); /* Reduced line height */
  color: var(--text-primary);
  font-size: var(--font-size-md); /* Reduced font size */
}

/* Strong Text */
.ai-response-strong {
  font-weight: var(--font-weight-extrabold);
  color: var(--primary-dark);
  text-shadow: var(--text-shadow-base);
  margin-left: -0.15rem;
}

/* Consolidated List Styles */
.ai-response-list,
.ai-response-ordered-list,
.ai-response-unordered-list {
  list-style-position: outside;
  line-height: var(--line-height-base); /* Reduced line height */
  margin: 0;
  padding-left: 1.5em; /* Reduced padding */
}

.ai-response-ordered-list {
  list-style-type: decimal;
}

.ai-response-unordered-list {
  list-style-type: disc;
}

/* List Item Styles */
.ai-response-list-item {
  display: list-item;
  margin-bottom: 0.2em; /* Reduced margin */
  padding-left: 0.4rem; /* Reduced padding */
}

/* Nested List Styles */
.ai-response-list .ai-response-list,
.ai-response-ordered-list .ai-response-ordered-list,
.ai-response-unordered-list .ai-response-unordered-list {
  margin: 0.5rem 0 0.5rem 0.75rem; /* Reduced margin */
}

/* List Content Wrapper */
.ai-response-list-item-content {
  display: inline;
  font-size: var(--font-size-md); /* Reduced font size */
  font-weight: var(--font-weight-medium);
  white-space: normal;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .ai-response-list,
  .ai-response-ordered-list,
  .ai-response-unordered-list {
    padding-left: 1.2em; /* Reduced padding */
  }
  
  .ai-response-list .ai-response-list,
  .ai-response-ordered-list .ai-response-ordered-list,
  .ai-response-unordered-list .ai-response-unordered-list {
    margin-left: 0.4em; /* Reduced margin */
  }
}

.ai-response-blockquote {
  border-left: 5px solid var(--primary-color);
  padding: 1rem 1.5rem; /* Reduced padding */
  margin: 1rem 0; /* Reduced margin */
  font-style: italic;
  color: var(--text-secondary);
  background-color: var(--background-light);
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--shadow-color);
  font-weight: var(--font-weight-medium);
  text-shadow: var(--text-shadow-sm);
}

/* Link Styles */
.ai-response-link {
  color: var(--primary-color);
  text-decoration: none;
  transition: color var(--transition-base), border-bottom var(--transition-base);
  border-bottom: 2px solid transparent;
  font-weight: var(--font-weight-medium);
  text-shadow: var(--text-shadow-sm);
  padding-bottom: 0.1em;
}

.ai-response-link:hover {
  color: var(--primary-dark);
  border-bottom: 2px solid var(--primary-dark);
}

/* Horizontal Rule */
.ai-response-hr {
  border: none;
  height: 1px;
  background-color: var(--text-tertiary);
  margin: 3.5rem 0;
  width: 100%;
}

@media (max-width: 760px) {
  .ai-response-hr {
    margin-left: -2rem;
    width: 110%;
  }
}

/* Code Display Styles */
.modern-code-display {
  font-family: var(--font-mono);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-snug);
  letter-spacing: var(--letter-spacing-tight);
  margin: 1.5rem 0;
}

.modern-code-display.snippet {
  margin-bottom: var(--spacing-sm);
  margin-top: 0;
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  color: var(--text-light);
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: sticky;
  top: 0;
  z-index: var(--z-sticky);
}

.code-header.snippet {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0 var(--spacing-sm);
  padding-bottom: var(--spacing-xs);
  position: relative;
}

.code-language.snippet {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.sticky-copy-button {
  position: sticky;
  top: 0.75rem;
  right: 1.25rem;
  z-index: var(--z-sticky);
  background-color: var(--background-main);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all var(--transition-base);
}

.sticky-copy-button:hover {
  background-color: var(--background-hover);
}

.code-language {
  text-transform: uppercase;
  color: var(--text-primary);
  font-family: var(--font-primary);
  font-weight: var(--font-weight-normal);
  letter-spacing: var(--letter-spacing-wide);
  font-size: var(--font-size-xs);
}

.code-display {
  padding: 1.25rem;
  font-size: 0.95rem;
  line-height: 1.6;
  color: var(--text-light);
  counter-reset: line;
  overflow-x: auto;
  font-weight: 600;
  font-feature-settings: "zero" 1, "ss01" 1, "ss02" 1;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
  white-space: pre-wrap !important;
  word-break: break-word;
  overflow-x: visible !important;
}

/* Syntax Highlighting */
.code-display .token.comment,
.code-display .token.prolog,
.code-display .token.doctype,
.code-display .token.cdata {
  color: var(--success-color);
  font-style: italic;
}

.code-display .token.punctuation { color: var(--text-light); }
.code-display .token.property,
.code-display .token.tag,
.code-display .token.constant,
.code-display .token.symbol,
.code-display .token.deleted { color: var(--info-color); }
.code-display .token.boolean,
.code-display .token.number { color: var(--success-color); }
.code-display .token.selector,
.code-display .token.attr-name,
.code-display .token.string,
.code-display .token.char,
.code-display .token.builtin,
.code-display .token.inserted { color: var(--warning-color); }
.code-display .token.operator,
.code-display .token.entity,
.code-display .token.url,
.code-display .language-css .token.string,
.code-display .style .token.string { color: var(--text-light); }
.code-display .token.atrule,
.code-display .token.attr-value,
.code-display .token.keyword { color: var(--primary-light); }
.code-display .token.function,
.code-display .token.class-name { color: var(--warning-color); }
.code-display .token.regex,
.code-display .token.important,
.code-display .token.variable { color: var(--info-color); }

/* Line Numbers and Hover Effect */
.code-display .line {
  display: inline-block;
  width: 100%;
  padding-left: 3.5em;
  position: relative;
  white-space: pre-wrap !important;
  word-break: break-word !important;
  padding-right: 1em;
}

.code-display .line::before {
  counter-increment: line;
  content: counter(line);
  position: absolute;
  left: 0;
  top: 0;
  width: 3em;
  text-align: right;
  color: var(--text-tertiary);
  opacity: 0.5;
  padding-right: 0.5em;
  border-right: 1px solid var(--sidebar-border);
}

.code-display .line:hover {
  background-color: var(--sidebar-hover);
}

/* Scrollbar Styles */
.code-display::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}

.code-display::-webkit-scrollbar-track {
  background: var(--sidebar-bg);
}

.code-display::-webkit-scrollbar-thumb {
  background-color: var(--sidebar-border);
  border-radius: 4px;
}

.code-display::-webkit-scrollbar-thumb:hover {
  background-color: var(--sidebar-active);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modern-code-display {
    font-size: 0.9rem;
  }

  .code-header {
    padding: 0.6rem 0.5rem;
  }

  .code-display {
    padding: 0.5rem;
  }

  .code-display .line {
    padding-left: 1.2em; /* Minimal space for line numbers */
  }

  .code-display .line::before {
    width: 1em;
  }

  .modern-code-display,
  .code-display {
    overflow-x: visible !important;
    white-space: pre-wrap !important;
  }
}

/* Media query for high-resolution mobile displays */
@media (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2), 
     (max-width: 768px) and (min-resolution: 192dpi) {
  body {
    -webkit-font-smoothing: antialiased; /* Changed from subpixel-antialiased */
  }
  
  .ai-response-heading,
  .ai-response-paragraph,
  .ai-response-strong,
  .ai-response-link,
  .ai-response-inline-code,
  .code-display,
  .citation {
    text-shadow: none;
  }
}

.interrupted-message {
  background-color: var(--background-hover-secondary);
  width: 100%;
  border: 1px solid var(--border-primary);
  border-radius: var(--radius-md);
  padding: var(--spacing-sm) var(--spacing-md);
  margin: calc(var(--spacing-xs) * -1) 0 var(--spacing-sm);
  font-size: var(--font-size-xs);
  color: var(--primary-color);
  box-shadow: var(--shadow-base);
  transition: all var(--transition-base);
}

.interrupted-message p {
  margin: 0;
  line-height: var(--line-height-base);
}

.interrupted-message:hover {
  background-color: var(--background-hover-primary);
  box-shadow: var(--shadow-lg);
  border-color: var(--border-primary-hover);
}

/* Citation Styles */
.citations {
  display: block;
  margin: 1rem 0;
  padding: 1rem;
  background-color: var(--background-light);
  border-radius: 6px;
  box-shadow: 0 2px 6px var(--shadow-color);
  position: relative;
  overflow-x: auto;
}

.citations::before {
  content: '§';
  position: absolute;
  top: 0.5em;
  left: -1em;
  font-size: 1.5em;
  color: var(--primary-color);
  opacity: 0.6;
}

.citation {
  display: inline-block;
  padding: 0.2em 0.4em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  background-color: var(--background-hover-primary);
  border-radius: var(--radius-sm);
  font-family: var(--font-mono);
  font-size: 0.95em;
  color: var(--primary-color);
  transition: background-color var(--transition-base);
  font-weight: var(--font-weight-medium);
  text-shadow: var(--text-shadow-sm);
  border: 1px solid var(--primary-light);
  font-feature-settings: "tnum" 1;
}

.citation:hover {
  background-color: var(--background-hover-secondary);
  cursor: pointer;
}
.ai-response-list {
  padding-left: 1.8em;
  margin: 1em 0;
  line-height: var(--line-height-loose);
}

/* Adjust nested list styles */
.ai-response-sublist {
  padding-left: 1.5rem;
  margin: 0.25rem 0;
}

.ai-response-list-item {
  margin-bottom: 0.3em;
}

.ai-response-inline-code {
  display: inline;
  background-color: var(--background-light);
  padding: var(--spacing-xs) var(--spacing-xs);
  border-radius: var(--radius-sm);
  font-family: var(--font-mono);
  margin: auto var(--spacing-xs);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-xs);
  color: var(--text-primary);
  border: 1px solid var(--border-light);
  white-space: normal;
  overflow-wrap: anywhere;
  word-break: break-word;
  letter-spacing: var(--letter-spacing-tight);
  text-shadow: var(--text-shadow-sm);
  transition: all var(--transition-fast);
}

/* Table Styles */
.table-wrapper {
  overflow-x: auto;
  margin: 1.75rem 0;
  border-radius: 8px;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.ai-response-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 0;  /* Changed from 1rem */
  font-size: 0.95rem;
  text-align: left;
  background: var(--background-light);
}

.ai-response-table th,
.ai-response-table td {
  padding: 0.875rem 1.25rem;
  border: none;  /* Removed borders */
  border-bottom: 1px solid var(--background-dark);
}

.ai-response-table th {
  background-color: var(--background-main);  /* Changed from primary-color */
  color: var(--text-primary);  /* Changed from text-light */
  font-weight: var(--font-weight-semibold);  /* Changed from bold */
  text-transform: uppercase;
  font-size: 0.85rem;
  letter-spacing: 0.05em;
}

.ai-response-table tr:last-child td {
  border-bottom: none;  /* Remove border for last row */
}

.ai-response-table tbody tr {
  transition: background-color var(--transition-base);
}

.ai-response-table tbody tr:hover {
  background-color: var(--background-hover);
}

@media (max-width: 468px) {
  .ai-response-table th,
  .ai-response-table td {
    padding: 0.75rem 1rem;  /* Slightly reduced padding for mobile */
    font-size: 0.9rem;
  }
}
/* Chemistry content styles */
.chemistry-content {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: var(--background-hover-primary);
  border-radius: 8px;
  box-shadow: var(--shadow-elevated);
  overflow-x: auto;
  white-space: normal;
  word-wrap: break-word;
}

.inline-chemistry {
  display: inline-block;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 0.95em;
  background-color: var(--background-hover-primary);
  color: var(--primary-dark);
  transition: all var(--transition-base);
  max-width: 100%;
  overflow-wrap: break-word;
  word-break: break-word;
  vertical-align: middle;
}

.inline-chemistry:hover {
  background-color: var(--background-main);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px var(--shadow-color);
}

/* Responsive styles */
@media (max-width: 768px) {
  .chemistry-content,
  .inline-chemistry {
    font-size: 0.9em;
  }
}
/* Adjust inline code within list items */