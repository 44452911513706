.expanded-resource {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
  border-radius: 20px 20px 20px 20px;
  background: var(--background-main);
  z-index: 1001;
  border: 1px solid var(--border-light);
}

.expanded-resource-content {
  flex-grow: 1;
  overflow-y: auto;
  transition: opacity 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  will-change: opacity;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

.expanded-resource-content::-webkit-scrollbar {
  display: none; /* Chrome/Safari/Opera */
}


.expanded-resource {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: width var(--transition-fast), margin-left var(--transition-fast);
  overflow: hidden;
}

.expanded-resource-header {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: var(--background-dark);
  gap: 12px;
  max-height: 50px;
}

.expanded-resource-header h2 {
  margin: 0 0 0 12px;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 500px) {
  .expanded-resource{
    border-radius: 20px 20px 0 0;
    border-bottom: none;
    max-height: 87svh;
    bottom: 0;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .expanded-resource-header {
    padding: 8px 12px;
  }

  .expanded-resource-content.visualization-content {
    padding: 8px;
  }
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--text-primary);
  border-radius: 10px;
  padding: 10px;
}

.back-button:hover {
  background-color: var(--background-light);
}

.back-button svg {
  margin-right: 2px;
}

.smart-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-dark);
  border-top: 1px solid var(--color-neutral-lighter);
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1002;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.02),
              0 -8px 40px rgba(0, 0, 0, 0.04);
  min-width: 0px;   
  max-height: 50px; 
          
}

.version-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.version-number {
  font-weight: 500;
  background: linear-gradient(to right, rgba(var(--accent-color-rgb), 0.08), rgba(var(--accent-color-rgb), 0.12));
  border: 1px solid rgba(var(--accent-color-rgb), 0.15);
  padding: 6px 14px;
  font-size: 0.85rem;
  color: var(--text-primary);
  transition: background-color 0.3s ease;
}

.version-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
  transition: color 0.3s ease;
}

.version-toggle:hover {
  color: var(--text-primary);
}

.version-toggle-icon {
  font-size: 1.1rem;
  transition: transform 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
}

.version-toggle-icon:hover {
  transform: scale(1.1);
}

.version-toggle-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.version-toggle-icon.disabled:hover {
  transform: none;
}

@media (max-width: 768px) {
  .version-info {
    width: 100%;
    justify-content: space-between;
  }

  .version-toggle {
    width: 100%;
    justify-content: space-between;
  }
}

.version-toggle-icon {
  font-size: 1.1rem;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.version-toggle-icon:hover {
  transform: scale(1.1);
}

.version-toggle-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.version-toggle-icon.disabled:hover {
  transform: none;
}

.expanded-resource-content.essay-content {
  line-height: 1.75;
  letter-spacing: -0.011em;
  color: var(--text-primary);
  width: 100%;
  padding: 22px 24px;
  background-color: var(--background-light);
}

.expanded-resource-content.test-content {
  padding: 0;
}

@media (max-width: 768px) {
  .expanded-resource-content.essay-content {
    padding: 15px;
  }
}

.text-options {
    display: flex;
    background-color: var(--background-main);
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-elevated);
    overflow: hidden;
    border: 1px solid var(--border-primary);
    z-index: var(--z-popover);
    animation: fadeIn var(--transition-fast);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(var(--spacing-sm));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.option-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm) var(--spacing-md);
  border: none;
  background-color: transparent;
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.option-button:hover {
  background-color: var(--background-hover);
  transform: translateY(-1px);
}

.option-button svg {
  margin-right: var(--spacing-xs);
  font-size: var(--font-size-xs);
}

.option-button.improve {
  color: var(--primary-color);
  background-color: var(--background-hover-primary);
}

.option-button.improve:hover {
  background-color: var(--background-hover-secondary);
}

.option-button.explain {
  color: var(--info-color);
  background-color: rgba(var(--primary-color), 0.1);
}

.option-button.explain:hover {
  background-color: rgba(var(--info-color), 0.2);
}

.improve-input-container {
  display: flex;
  align-items: center;
  background: var(--background-main);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-elevated);
  padding: var(--spacing-xs) var(--spacing-sm);
  gap: var(--spacing-xs);
  min-width: 280px;
  border: 1px solid var(--border-primary);
  transition: all var(--transition-base);
}

.improve-input-container:focus-within {
  transform: translateY(-1px);
  border-color: var(--border-focus);
}

.improve-suggestion-input {
  flex: 1;
  border: none;
  background: transparent;
  color: var(--text-primary);
  font-size: 0.9rem;
  padding: 6px 0;
  min-width: 200px;
}

.improve-suggestion-input:focus {
  outline: none;
}

.improve-suggestion-input::placeholder {
  color: var(--text-secondary);
  opacity: 0.7;
}

.send-improvement-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-color);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 6px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.send-improvement-button:hover {
  background: var(--accent-color-dark);
  transform: translateY(-1px);
}

.send-improvement-button:active {
  transform: translateY(0);
}

.send-improvement-button svg {
  font-size: 0.9rem;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .improve-input-container {
    min-width: 240px;
    padding: 6px 10px;
  }

  .improve-suggestion-input {
    font-size: 0.85rem;
    min-width: 160px;
  }

  .send-improvement-button {
    width: 28px;
    height: 28px;
    padding: 5px;
  }
}

.smart-footer .version-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--background-light);
  padding: 6px 12px;
  border-radius: 20px;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity;
}

.smart-footer .version-nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
}

.smart-footer .version-nav-btn:hover:not(:disabled) {
  background-color: var(--background-main);
  transform: translateY(-1px);
  filter: brightness(1.1);
}

.smart-footer .version-nav-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .version-divider {
    display: none;
  }

  .version-total {
    display: none;
  }

  .version-nav-btn.diff-toggle {
    display: none;
  }
}

.smart-footer .version-display {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
  color: var(--text-primary);
}

.smart-footer .version-current {
  font-weight: 600;
}

.smart-footer .version-divider {
  color: var(--text-secondary);
}

.diff-content {
  padding: 2rem;
  background-color: var(--background-light);
}

.change-block {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: var(--background-main);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.change-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.previous-version, .current-version {
  padding: 1rem;
  border-radius: 6px;
}

.arrow {
  text-align: center;
  font-size: 1.5rem;
  color: var(--text-secondary);
  margin: 1rem 0;
}

.diff-content .content {
  padding: 1rem;
  border-radius: 6px;
  line-height: 1.6;
}

.content.removed {
  background-color: var(--error-light);
  border: 1px solid var(--error-color);
}

.content.added {
  background-color: var(--success-light);
  border: 1px solid var(--success-color);
}

.added-block .change-label {
  color: var(--success-color);
}

.removed-block .change-label {
  color: var(--error-color);
}

/* Add these new styles */
.visualization-content {
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, 
    rgba(var(--background-main-rgb), 0.5),
    rgba(var(--background-main-rgb), 0.8)
  );
  backdrop-filter: blur(20px);
  border-radius: 16px;
  box-shadow: 
    0 4px 24px rgba(0, 0, 0, 0.03),
    0 1px 2px rgba(0, 0, 0, 0.02);
}

.visualization-content .ant-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--background-main);
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  padding: 20px;
  border: 1px solid var(--color-neutral-lighter);
}

.visualization-content .ant-tabs-nav {
  margin-bottom: 20px !important;
  background: var(--background-light);
  padding: 4px;
  border-radius: 12px;
  border: 1px solid var(--color-neutral-lighter);
}

.visualization-content .ant-tabs-tab {
  padding: 8px 16px;
  margin: 0 4px;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.visualization-content .ant-tabs-tab-active {
  background: var(--background-main);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.visualization-content .ant-tabs-content {
  flex: 1;
  height: 100%;
  background: var(--background-main);
  border-radius: 12px;
  padding: 20px;
}

/* Chart Container Styles */
.chart-container {
  width: 100%;
  height: 400px;
  margin: 20px 0;
  padding: 20px;
  background: linear-gradient(to bottom right, 
    rgba(var(--background-main-rgb), 0.9),
    rgba(var(--background-main-rgb), 0.95)
  );
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
              0 12px 28px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
}

.chart-container:hover {
  transform: translateY(-2px);
}

.chart-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--color-border);
  margin-bottom: 16px;
}

.chart-description {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-bottom: 24px;
  line-height: 1.5;
}

/* Flowchart Specific Styles */
.flow-chart-container {
  width: 100%;
  min-height: 400px;
  background: var(--background-main);
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  border: 1px solid var(--color-neutral-lighter);
  margin: 8px 0;
}

.flow-chart-container svg {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Chart Theme Overrides */
.visualization-content .g2-tooltip {
  background: var(--background-main) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  padding: 12px !important;
  border: 1px solid var(--color-neutral-lighter) !important;
}

.visualization-content .g2-tooltip-title {
  font-weight: 600 !important;
  color: var(--color-border) !important;
}

.visualization-content .g2-tooltip-list-item {
  color: var(--text-secondary) !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .visualization-content {
    padding: 16px;
  }

  .visualization-content .ant-tabs {
    padding: 16px;
  }

  .chart-container {
    min-height: 300px;
    padding: 16px;
  }

  .flow-chart-container {
    min-height: 300px;
    padding: 16px;
  }

  .chart-title {
    font-size: 1rem;
  }

  .chart-description {
    font-size: 0.85rem;
    margin-bottom: 16px;
  }
}

/* Style for tabs when multiple visualizations are present */
.ant-tabs-nav {
  margin-bottom: 16px !important;
}

.ant-tabs-tab {
  padding: 12px 16px !important;
}

.ant-tabs-tab-active {
  background: var(--info-light) !important;
  border-radius: 4px 4px 0 0 !important;
}

.ant-tabs-content {
  background: var(--background-main);
  border-radius: 0 0 8px 8px;
  padding: 16px;
}

.flowchart-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.zoom-controls {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 8px;
  z-index: 1000;
}

.zoom-button {
  background: var(--background-main);
  border: 1px solid var(--color-neutral-lighter);
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.zoom-button:hover {
  background: var(--background-light);
}

.flow-chart-container {
  position: relative;
  background: var(--background-main);
}

/* Ensure the SVG takes up the full space */
.flow-chart-container svg {
  min-width: 100%;
  min-height: 100vh;
}

/* Optimize visualization rendering */
.visualization-wrapper {
  transform: translateZ(0);
  backface-visibility: hidden;
  contain: content;
  will-change: transform;
}

.visualization-content {
  opacity: 0;
  animation: fadeIn 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: 0.2s; /* Delay visualization render until transition completes */
}

.visualization-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border: 3px solid var(--color-neutral-lighter);
  border-top: 3px solid var(--info-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

/* Optimize chart rendering */
.ant-tabs {
  contain: content;
}

.ant-tabs-content {
  contain: content;
  will-change: transform;
}

/* Disable unnecessary animations */
.ant-tabs-tab {
  transition: none !important;
}

.ant-tabs-ink-bar {
  transition: none !important;
}

.add-to-space-button{
  background: var(--background-light);
  border: 1px solid var(--color-neutral-lighter);
  border-radius: 8px;
}

.expanded-resource .add-to-space-button:hover {
  border-color: var(--accent-color);
  box-shadow: 0 4px 12px rgba(var(--accent-color-rgb), 0.1);
}

.expanded-resource .add-to-space-button:active {
  box-shadow: 0 1px 2px rgba(var(--accent-color-rgb), 0.1);
}

.footer-right {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.footer-right-button-icon {
  width: 18px;
  height: 18px;
}

.footer-right-button {
  padding: 8px;
  border-radius: 8px;
}

.footer-right-button:hover {
  background: var(--background-light);
}

/* Add responsive styles */
@media (max-width: 768px) {
  .expanded-resource .add-to-space-button {
    padding: 4px 8px;
    font-size: 0.85rem;
  }
}


.expanded-resource .ai-response-list-item {
  line-height: 1.1;
  position: relative;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .expanded-resource .ai-response-list,
  .expanded-resource .ai-response-ordered-list,
  .expanded-resource .ai-response-unordered-list {
    padding-left: 1rem;
  }

  .expanded-resource .ai-response-list-item.level-2 {
    margin-left: 0.8rem;
  }

  .expanded-resource .ai-response-list-item.level-3 {
    margin-left: 1.6rem;
  }

  .expanded-resource .ai-response-list-item.level-4 {
    margin-left: 2.4rem;
  }

  .expanded-resource .ai-response-sublist {
    margin-left: 0.3rem;
    padding-left: 0.8rem;
  }
}


.expanded-resource .ai-response-heading {
  margin-top: 0.1rem;
}

.expanded-resource .ai-response-heading-1 {
  font-size: 1.6rem;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
}

.expanded-resource .ai-response-heading-2 {
  font-size: 1.35rem;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
}

.expanded-resource .ai-response-heading-3 {
  font-size: 1.15rem;
}

.expanded-resource .ai-response-heading-4 {
  font-size: 1rem;
}

.expanded-resource .ai-response-heading-5 {
  font-size: 0.8rem;
}

.expanded-resource .ai-response-paragraph {
  margin-top: 0.1rem;
}

/* AI Response Typography */
.expanded-resource .ai-response-list,
.expanded-resource .ai-response-ordered-list,
.expanded-resource .ai-response-unordered-list,
.expanded-resource .ai-response-paragraph,
.expanded-resource .ai-response-heading {
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  letter-spacing: -0.011em;
  font-feature-settings: 'ss01' 1, 'ss02' 1, 'ss03' 1, 'liga' 1;
}

.expanded-resource .ai-response-heading {
  font-weight: 600;
  line-height: 1.3;
  color: var(--text-primary);
}

.expanded-resource .ai-response-paragraph {
  font-weight: 400;
  line-height: 1.6;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.expanded-resource .ai-response-list-item {
  line-height: 1.5;
  font-weight: 400;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.expanded-resource .code-language {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.diff-content .change-block {
  margin-bottom: var(--spacing-xl);
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  background-color: var(--background-light);
}
