/* Base input wrapper styles */
.input-wrapper {
  width: 95%;
  max-width: 750px;
  max-height: 400px;
  padding: var(--spacing-sm);
  margin-bottom: 0px;
  position: relative;
  z-index: 2;

  background-color: var(--background-main);
  
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  
  border-radius: var(--radius-4xl);
  outline: none;
  border: 1px solid var(--border-color);
  
  /* Positioning */
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center;
}

/* Chat mode variations */
.input-wrapper.chat {
  margin-bottom: 1rem;
  gap: 10px;
}

@media (max-width: 768px) {
  .input-wrapper.chat {
    border-radius: 24px;
    margin-bottom: 0.8rem;
  }

  .input-wrapper {
    width: 90%;
  }
}

.input-wrapper.chat.nouser {
  border-radius: 24px;
  margin-bottom: 0.4rem;
}

/* Empty state variation */
.input-wrapper.empty {
  max-width: 750px;
  max-height: none;
  border-radius: 24px;
  transition: all 0.3s ease;
}

.input-button-wrapper {
  max-height: 30px;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: var(--spacing-lg) var(--spacing-xs);
}

.input-button-wrapper-left {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

@media (max-width: 768px) {
  .input-wrapper.chat {
    border-radius: 24px;
    margin-bottom: 0.8rem;
    gap: 10px;
  }

  .input-wrapper {
    width: 94%;
    gap: 10px;
  }

  .input-button-wrapper {
    padding: var(--spacing-sm) 0;
  }

  .input-button-wrapper-left {
    gap: 8px;
  }
}

.input-button-wrapper-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: auto; /* Pushes the div to the right */
}

.rounded-button {
  background: none;
  border: 1px solid var(--border-color);
  cursor: pointer;
  color: var(--text-primary);
  transition: color 0.3s ease;
  position: relative;
  padding: var(--spacing-sm);
  border-radius: var(--radius-2xl);
  background-color: var(--background-light);
}

.rounded-button:hover {
  background-color: var(--background-dark);
  box-shadow: var(--shadow-sm);
}

.rounded-button svg {
  color: var(--text-muted);
}

 .button-wrapper-icon.disabled {
  color: var(--text-muted);
}

.attach-button {
  background: none;
  margin-left: 0.5rem;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  transition: color 0.3s ease;
  position: relative;
  padding: 8px;
  border-radius: var(--radius-md);
}

.attach-button:hover {
  background-color: var(--background-main);
}

.input-button-wrapper-left {
  color: var(--primary-color);
}

.chat-input {
  width: 100%;
  padding: 8px 10px;
  min-height: 45px;
  max-height: 250px;
  color: var(--text-primary);
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  overflow-y: auto;
  flex-grow: 1;
  box-sizing: border-box;
  font-family: var(--font-primary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-normal);
}

.chat-input::-webkit-scrollbar-track,
.chat-input::-webkit-scrollbar-track-piece,
.chat-input::-webkit-scrollbar-thumb {
  background: var(--background-hover);
}
.chat-input::-webkit-scrollbar-thumb:hover {
  background: var(--background-hover-primary);
}

@media (max-width: 468px) {
  .chat-input {
    font-size: 16px;
  }
}

.input-wrapper input {
  flex-grow: 1;
  background: transparent;
  font-size: var(--font-size-sm);
  border: none;
  outline: none;
}

.chat-input::placeholder {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.chat-input.scrollable.empty {
  max-height: 190px;
  min-height: 20px;
  transition: height 0.3s ease;
}

@media (max-width: 768px) {
  .chat-input.scrollable.empty {
    max-height: 120px;
    min-height: 20px;
  }
}