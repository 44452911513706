.message {
  margin-bottom: var(--spacing-lg);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--radius-xl);
  line-height: var(--line-height-base);
  animation: messageSlideIn var(--transition-fast) var(--ease-out);
  font-family: var(--font-primary);
  font-weight: var(--font-weight-medium);
  min-height: 50px;
  width: 100%;
  min-width: 250px;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform-origin: center;
}

.message.user {
  font-family: var(--font-primary);
  margin-left: auto;
  margin-right: auto;
  background-color: var(--message-user-bg);
  color: var(--text-primary);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-base);
  text-align: left;
  border-radius: var(--radius-xl);
  position: relative;
  transition: all var(--transition-base) var(--transition-bezier);
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--border-light);
  position: relative;
}

.user-message-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  line-height: 1.5;
}

.user-message-left {
  font-size: 0.9rem;
  word-break: break-word;
  white-space: pre-wrap;
}

.user-message-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: auto;
  gap: 4px;
}

.user-icon {
    width: 28px;
    height: 28px;
    background-color: var(--background-dark);
    color: var(--text-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 0.8rem;
    margin-left: 0rem;
    margin-right: -0.3rem;
    min-width: 28px;
    margin-left: auto;
  }  

.message-actions {
  display: flex;
  opacity: 1;
  z-index: 3;
}

.message.ai {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  color: var(--message-ai-text);
  background-color: var(--background-primary);
  max-width: 640px;
  font-size: 1.0005rem;
  padding: 0px;
  text-align: left;
  position: relative;
  margin-top: 0.5rem;
}
.message.ai:last-child {
  margin-bottom: 1rem;
}

.message.ai .ai-icon {
  position: absolute;
  top: -5px;
  left: -50px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--border-light);
  border-radius: 40px;
  background-color: transparent;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.message.ai .ai-icon.dark{
  border: none;
}

@media (max-width: 768px) {
  .ai-icon {
    display: none;
  }
}

.message-footer.editing {
  display: block;
} 

.message.ai .message-actions.not-last-message {
  opacity: 0;
}

.message.ai:hover .message-actions {
  opacity: 1;
}

.message-actions {
  display: flex;
  align-items: center;
  gap: 0px;
}

.message-actions button {
  background: none;
  color: var(--text-primary);
  border: none;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color var(--transition-base), color var(--transition-base);
}

.message.user .message-actions button {
  color: var(--text-dark);
}

.message.ai .message-actions button {
  color: var(--text-primary);
}

.message.ai .message-actions button:hover {
  color: var(--text-primary);
  background-color: var(--background-light);
}

.edit-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.edit-disclaimer {
  font-size: 0.75rem;
  color: var(--error-color);
  margin: 12px 0;
  padding: 8px 12px;
}

.edit-actions {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.action-button.edit-button {
  position: absolute;
  top: -10px;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: var(--message-user-bg);
  color: var(--text-primary);
  font-size: 0.85rem;
  font-weight: var(--font-weight-medium);
  opacity: 0;
  transition: all var(--transition-bezier);
  border: 1px solid transparent;
  box-shadow: 0 2px 4px var(--shadow-light);
  cursor: pointer;
  z-index: 2;
  backdrop-filter: blur(8px);
}

.action-button.edit-button:hover {
  color: var(--text-dark);
  border-color: var(--border-light) var(--border-light) transparent;
}

.action-button.edit-button span {
  opacity: 0;
  width: 0;
  overflow: hidden;
  transition: opacity var(--transition-fast), width var(--transition-fast);
}

.action-button.edit-button:hover span {
  opacity: 1;
  width: auto;
  margin-left: 4px;
}

@media (min-width: 769px) {
  .message:hover .action-button.edit-button {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .action-button.edit-button {
    opacity: 1;
    padding: 4px 8px;
  }
}

@media (max-width: 768px) {
    .message.ai,
    .message.user{
      max-width: 580px;
    }
  }
  
  @media (max-width: 600px) {
    .message.ai,
    .message.user{
      max-width: 480px;
    }
  }