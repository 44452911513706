.thinking-panel {
    background-color: var(--glass-background);
    backdrop-filter: var(--blur-sm);
    border-radius: var(--radius-lg);
    border: 1px solid var(--border-color);
    padding: var(--spacing-md);
    transition: all var(--transition-base);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: var(--text-secondary);
    margin-bottom: var(--spacing-md);
}

.thinking-panel:hover {
    border-color: var(--border-interactive-hover);
    box-shadow: var(--shadow-primary-hover);
}

.thinking-panel.thinking {
    border-color: var(--border-strong);
    color: var(--text-primary);
}

.thinking-panel__header {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    letter-spacing: var(--letter-spacing-wide);
}

.thinking-panel__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: var(--hover-opacity);
    transition: opacity var(--transition-base);
}

@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-3px); }
}

.thinking-panel__time {
    transition: color var(--transition-base);
}

.thinking-panel:hover .thinking-panel__time {
    color: var(--text-primary);
}

.thinking-panel__close-button {
    margin-left: auto;
    transition: all var(--transition-base);
}

.thinking-panel__close-button.open {
    transform: rotate(180deg);
    color: var(--text-primary);
}

.thinking-panel__content {
    margin-top: var(--spacing-lg);
    padding-top: var(--spacing-md);
    border-top: 1px solid var(--border-light);
    color: var(--text-primary);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-relaxed);
    animation: fadeIn var(--transition-slow) var(--ease-out);
    word-wrap: break-word;
    white-space: pre-wrap; /* Changed from normal to pre-wrap to preserve natural spacing */
    overflow-wrap: break-word;
}

.thinking-panel__content p {
    margin-bottom: var(--paragraph-spacing-base);
}

.thinking-panel__content p:last-child {
    margin-bottom: 0;
}

@keyframes fadeIn {
    from { 
        opacity: 0;
        transform: translateY(-4px);
    }
    to { 
        opacity: 1;
        transform: translateY(0);
    }
}
