/* Global styles */
html, body {
  height: 100vh;
  height: 100dvh;
  min-height: -webkit-fill-available;
  font-family: var(--font-primary);
  margin: 0;
  padding: 0;
}

/* Layout */
.chat-page {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Fallback */
  height: 100dvh;
  min-height: -webkit-fill-available;
  background-color: var(--background-main);
  width: 100%;
  color: var(--text-primary);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chat-area {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: 
    width 0.4s cubic-bezier(0.16, 1, 0.3, 1),
    margin-left 0.4s cubic-bezier(0.16, 1, 0.3, 1),
    margin-right 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  will-change: width, margin-left, margin-right;
  height: 93.5%;
  z-index: 1000;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
  -webkit-scrollbar-width: none; /* Arc/Webkit */
}

.chat-area::-webkit-scrollbar {
  display: none; /* Chrome/Safari/Opera/Arc */
  width: 0; /* Arc */
}


@media (min-width: 1001px) {
  .chat-area.sidebar-open.resources-open {
    margin-left: 250px;
    width: var(--chat-width-medium);
    margin-right: var(--resources-width-medium);
    transition: 
      margin-left 0.4s cubic-bezier(0.4, 0, 0.2, 1),
      width 0.4s cubic-bezier(0.4, 0, 0.2, 1),
      margin-right 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .chat-area.sidebar-open.chat-area.resources-open.resources-expanded {
    margin-left: 250px;
    width: var(--chat-width-medium);
    margin-right: calc(50% + 10px);
    transition: 
      margin-left 0.4s cubic-bezier(0.4, 0, 0.2, 1),
      width 0.4s cubic-bezier(0.4, 0, 0.2, 1),
      margin-right 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .chat-area.sidebar-closed.resources-open {
    width: var(--chat-width-medium);
    margin-right: var(--resources-width-medium);
  }

  .chat-area.sidebar-closed.resources-open.resources-expanded {
    width: var(--chat-width-medium);
    margin-right: 50%;
    margin-right: calc(50% + 10px);
  }
}

@media (max-width: 868px) {
  .chat-page {
    flex-direction: column;
  }


}

@media (min-width: 501px) and (max-width: 1000px) {
  

  .chat-area.sidebar-closed{
    width: 100%;
  }

  .chat-area.resources-open {
    margin-right: calc(var(--resources-width-small) + 100px);
    width: 60%;
    /* Resources section now overlays */
  }

}

@media (min-width: 651px) and (max-width: 1000px) {
  .chat-area.sidebar-open{
    width: calc(100% - 250px);
  }
}

@media (max-width: 650px) {
  .chat-area.sidebar-open {
    margin-left: 0px;
    transition: 
      left 0.4s cubic-bezier(0.4, 0, 0.2, 1),
      filter 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    
  }

  .chat-header.sidebar-open {
    margin-left: 0px;
    transition: 
      left 0.4s cubic-bezier(0.4, 0, 0.2, 1),
      filter 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 651px) {
  .chat-area.sidebar-open {
    margin-left: 250px;
    transition: margin-left 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }
}


/* Header styles */
.chat-header {
  backdrop-filter: var(--blur-md);
  padding: 0rem 0 0rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.5%;
  z-index: 1009;
  position: relative;
  gap: 0.5rem;
  transition: 
    margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.3s ease;
}

.chat-header.sidebar-open{
  margin-left: 250px;
}

.header-left{
  margin-left: 15px;
}

.header-left, .header-right {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.header-center {
  flex-grow: 1;
  max-width: 400px;
  margin: 0 auto;
}

.logo-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.user-avatar.empty {
  background-color: var(--background-light);
}

.resources-count {
  position: absolute;
  top: -3px;
  right: 5px;
  font-size: 0.7rem;
  background: var(--accent-primary);
  color: var(--text-primary);
  padding: 2px 5px;
  border-radius: 10px;

  min-width: 18px;
  text-align: center;
}

.menu-button,
.settings-button,
.home-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  transition: color 0.3s ease;
}

.settings-button{
  margin-left:-1rem;
}

.menu-button:hover,
.settings-button:hover {
  background: none !important;
}

.studybuddy-logo {
  width: 300px;
  height: auto;
  margin-top: -0.75rem;
}

@media (max-height: 880px) {
  .studybuddy-logo {
    margin-top: -1.5rem;
  }
}

@media (max-height: 768px) {
  .studybuddy-logo {
    margin-top: -2rem;
    width: 350px;
  }
}

@media (max-height: 600px) {
  .studybuddy-logo {
    margin-top: -2.5rem;
    width: 300px;
  }
}

@media (max-width: 1024px) and (max-height: 768px) {
  .studybuddy-logo {
    margin-top: -1.75rem;
    width: 325px;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .studybuddy-logo {
    margin-top: -1.25rem;
    width: 375px;
  }
}

@media (max-width: 480px) {
  .studybuddy-logo {
    margin-top: -1rem;
    width: 250px;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .studybuddy-logo {
    margin-top: -0.75rem;
    width: 200px;
  }
}

@media (max-width: 1000px) {
  .input-container {
    z-index: 3
  }
}

.messages-container {
  flex: 1;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 40%;
  transition: width 0.3s ease-in-out;
  margin: 0 auto;
  align-self: center;
  z-index: 1;
  overflow-y: auto;
}
.messages-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.3s ease-in-out;
  max-width: 100%;
  position: relative;
  height: 100%;
}

@keyframes messageSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.messages-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  padding-top: 2rem;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  margin-left: 8px;
  color: var(--text-light);
  transition: color 0.3s ease, transform 0.2s ease;
  border-radius: 8px;
}

.action-button:hover {
  color: var(--text-light);
}

.input-area {
  min-width: 100% ;
}

.copy-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;
  border: 1px solid var(--button-disabled);
  border-radius: 4px;
  color: var(--background-dark);
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  right: 0;
}

.copy-button:hover {
  background-color: var(--button-disabled);
}

.copy-button.exp{
  position: absolute;
  right: 20px;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px) translateZ(0); }
  to { opacity: 1; transform: translateY(0) translateZ(0); }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


/* Media Queries */
@media (max-width: 768px) {
  .chat-area {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-overflow-scrolling: touch;
  }

  .studybuddy-logo{
    width: 200px;
    height: auto;
    margin-top: -1.5rem;
    user-select: none;
  -webkit-user-drag: none;
  }

  .suggestions-list {
    flex-direction: column;
    width: 100%;
  }

  .suggestion-item {
    width: 100%;
  }
  


  .chat-header {
    position: relative;

  }

  .sidebar.open {
    left: 0;
  }

  .si{
    width: 100%;
  }

  .chat-area{
    height: 90%;
  }

  .send-button {
    width: 36px;
    height: 36px;
  }

  .logo-title {
    font-size: 1.15rem;
  }

  .user-avatar {
    width: 28px;
    height: 28px;
    font-size: 0.8rem;
  }

}

@media (min-height: 1000px) {
  .chat-header{
    height: 6.5%;
  }
}

@media (min-height: 1300px) {
  .chat-header{
    height: 5.2%;
  }
}


@media (max-width: 650px) {
  .chat-header.sidebar-open {
    margin-left: 0px;
    transition: 
      left 0.4s cubic-bezier(0.4, 0, 0.2, 1),
      filter 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .sidebar {
    width: 275px;
    left: -275px;
  }
}

@media (max-width: 750px) and (min-height: 800px) {
  .chat-header{
    height: 6.5%;
  }
}

@media (max-width: 1050px) and (max-height: 700px) {
  .chat-header{
    height: 8.5%;
  }
}


.upgrade-prompt {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.upgra {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background-color: var(--accent-primary);
  color: var(--text-primary);
  border-radius: 25px;
  border: 1px solid var(--border-color);
  text-decoration: none;
  font-size: 12px;
  transition: all 0.2s ease;
}



.upgra svg {
  color: var(--text-primary);
}

.upgra span {
  white-space: nowrap;
}

.suggestions-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

.suggestion-item {
  background-color: var(--background-main);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  color: var(--text-dark);
  display: flex;
  align-items: center;
}

.suggestion-item:hover {
  background-color: var(--background-hover-secondary);
  border-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px var(--shadow-primary);
}

.suggestion-item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.suggestion-item.disabled:hover {
  transform: none;
  box-shadow: none;
  background-color: var(--background-main);
  border-color: var(--border-color);
}

.suggestion-item svg {
  margin-right: 8px;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .suggestions-list {
    flex-wrap: wrap;
  }

  .suggestion-item {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}

.signup-link {
  display: inline-flex;
  align-items: center;
  padding: 0.4rem 1rem;
  background: var(--primary-color);
  color: var(--text-light);
  text-decoration: none;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  transition: all var(--transition-fast);
  box-shadow: 0 2px 8px var(--shadow-primary);
}

.signup-link:hover {
  background: var(--primary-dark);
}

/* Animations */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.chat-limit-banner {
  position: relative;
  padding: 0.75rem 1.5rem;
  margin: 1rem auto;
  max-width: 80%;
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 24px;
  box-shadow: 0 2px 8px var(--card-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.9rem;
  transition: all 0.2s ease;
  animation: slideDown 0.3s ease-out;
}

.chat-limit-banner .chat-limit-content {
  flex: 1;
  padding: 0 1rem;
}

.chat-limit-banner .close-banner {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: var(--text-secondary);
  padding: 4px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.chat-limit-banner .close-banner:hover {
  opacity: 1;
}

.chat-limit-banner span {
  font-weight: 500;
  margin-right: 8px;
}

.chat-limit-banner .signup-link {
  margin-left: 8px;
  padding: 0.35rem 0.8rem;
  font-size: 0.85rem;
  white-space: nowrap;
}


@media (max-width: 500px) {
  .chat-limit-banner {
    padding: 0.6rem 0rem;
    min-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .chat-limit-content{
    padding: 0rem 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

/* Mobile Responsive */
@media (max-width: 568px) {
  .signup-link {
    padding: 0.3rem 0.8rem;
    font-size: 0.85rem;
  }
}

.scroll-to-bottom {
  position: absolute;
  bottom: 12.5%;
  right: 50%;
  background-color: var(--background-main);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-full);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all var(--transition-bezier);
  animation: fadeIn var(--transition-base) var(--ease-out) forwards;
  z-index: var(--z-tooltip);
  font-size: var(--font-size-sm);

  background: var(--glass-background);
}

.scroll-to-bottom.generating-response {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(-5px); 
  }
}

@media (max-width: 650px) {
  .scroll-to-bottom {
    bottom: 25%;
  }
}

.scroll-to-bottom:hover {
  background-color: var(--background-hover);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(10px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0) translateZ(0);
  }
}

.edit-input {
  width: 100%;
  min-height: 10px;
  max-height: 550px; /* Match the max height from adjustEditTextareaHeight */
  padding: 6px 6px;
  border: none;
  border-radius: 18px 18px 0 18px;
  font-family: var(--user-primary);
  font-size: var(--font-size-sm);
  line-height: 1.5;
  resize: none; /* Remove the resize handle */
  color: var(--text-dark);
  background-color: transparent;
  transition: all var(--transition-base);
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal overflow */
}

@media (max-width: 468px) {
  .edit-input {
    font-size: 16px;
  }
}

.auto-expand {
  overflow: hidden;
  resize: none;
  height: auto;
}

.edit-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 8px;
}

.branch-actions {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
  gap: 8px;
}

.edit-actions button {
  padding: 6px 12px;
  border: none;
  border-radius: var(--radius-lg);
  cursor: pointer;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-sm);
  transition: all var(--transition-bezier);
  background-color: var(--background-hover);
  color: var(--text-dark);
}

.edit-actions .save-button {
  background-color: var(--background-secondary);
  color: var(--accent-primary);
  padding: 8px 16px;
  border: 1px solid var(--text-primary);
  border-radius: var(--radius-2xl);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-wide);
  box-shadow: 0 2px 4px var(--card-shadow);
  transition: all var(--transition-base);
}

.edit-actions .save-button:hover {
  background-color: var(--background-dark);
  color: var(--text-primary);
  box-shadow: 0 4px 8px var(--overlay-color);
}

.edit-actions .save-button:active {
  box-shadow: 0 1px 2px var(--overlay-color);
}

.edit-actions .cancel-button {
  padding: 8px 16px;
}

.edit-actions button:hover {
  background-color: var(--background-hover-secondary);
}

.edit-actions button:active {
  transform: translateY(1px);
}

/* Adjust z-index for other elements */
.chat-header {
  /* ... existing styles ... */
  z-index: var(--z-sticky); /* Lower than mode selector but higher than chat area */
}

.chat-area {
  /* ... existing styles ... */
  z-index: var(--z-dropdown); /* Lower than header and mode selector */
}

.hover-text{
  z-index: var(--z-tooltip);
}

.chat-page .hover-text {
  display: none;
  position: absolute;
  background-color: var(--text-dark);
  color: var(--text-light);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: var(--z-tooltip);
  pointer-events: none;
}

.chat-page button:hover .hover-text {
  display: block;
}

/* Positioning for different buttons */
.menu-button .hover-text,
.edit-new-chat-button .hover-text {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
}

.menu-button .hover-text {
  margin-left: 1.8rem;
}

.action-button .hover-text {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
}

.chat-page .hover-text.chat{
  top: 50px;
}


/* Add a subtle animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.input-container {
  z-index: 2; /* Higher than messages, lower than resources */
  position: relative;
  background-color: transparent;
  margin-bottom: 1rem;

}

@media screen and (max-width: 599px) {
  .input-container {
    margin-bottom: -1.5rem;
  }
}

.web-indicator {
  display: flex;
  align-items: center;
  background-color: var(--background-hover-secondary);
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 12px;
  color: var(--text-secondary);
  margin-left: 8px;
}

.web-indicator svg {
  margin-right: 4px;
}

/* Add these new styles for the chat controls toggle */
.chat-controls-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.chat-controls-toggle.active {
  background-color: var(--background-dark);
  padding: 3px;
  border-radius: 4px;
  color: var(--accent-color);
}


.chat-controls-toggle svg {
  width: 24px;
  height: 24px;
}

.quote-content {
  background-color: var(--background-light);
  border-left: 4px solid var(--accent-color);
  padding: 12px 16px;
  margin: 12px 0;
  font-style: italic;
  border-radius: 8px;
  color: var(--text-primary);
  transition: all 0.3s ease;
  margin-top: 0rem;
}

.imglogo {
  max-width: 40px;
  height: auto;
  margin-bottom: 2.5rem;
  transition: all 0.8s var(--transition-bezier);
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  animation: floatLogo 1.2s var(--transition-bezier) 0.3s forwards;
  font-size: 4rem;
  font-weight: 800;
  color: var(--primary-color);
  text-decoration: none;
  display: block;
  text-align: center;
  letter-spacing: -2px;
  position: relative;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

.notexistslogo{
  width: 200px;
}

@media (max-width: 768px) {
  .suggestions-list{
    margin-top: 0px;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.9); }
  to { transform: scale(1); }
}

@keyframes slideUp {
  from { transform: translate(-50%, 100%); }
  to { transform: translate(-50%, 0); }
}


.auth-button {
  display: inline-block;
  padding: 8px 16px;
  color: var(--text-dark);
  border: 1px solid var(--border-interactive);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px var(--shadow-color);
  cursor: pointer;
}

.auth-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  box-shadow: 0 4px 6px var(--shadow-color);
}

.auth-button:active {
  transform: translateY(0);
  box-shadow: 0 1px 2px var(--shadow-color);
}

.auth-button.sign-in {
  color: var(--background-main);
  background-color: var(--text-primary);
  border: 1px solid var(--border-color);
  padding: 9px 16px;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.auth-button.sign-in:hover {
  background-color: var(--text-muted);
  border-color: var(--border-interactive-hover);
}

.auth-disclaimer {
  position: relative;
  margin-bottom: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--text-muted);
  padding: 0 20px;
  z-index: 10;
}

.auth-disclaimer p {
  margin: 0;
  line-height: 1.3;
}

.link-wrapper {
  color: var(--primary-color);
  transition: color 0.2s var(--ease-out);
}

.link-wrapper:hover {
  color: var(--primary-dark);
}

.chat-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--background-main);
  z-index: 1000;
}

.chat-not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: var(--spacing-xl);
  max-width: 450px;
  background: var(--glass-background);
  backdrop-filter: var(--blur-md);
  border: 1px solid var(--border-glass);
  border-radius: var(--radius-lg);
}

.chat-not-found-content h2 {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-tight);
}

.chat-not-found-content p {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xl);
  line-height: var(--line-height-relaxed);
  max-width: 90%;
}

.chat-not-found-content .submit-button {
  margin: 0rem auto 1rem;
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  background-color: var(--background-dark);
  color: var(--text-primary);
  border: 1px solid var(--border-strong);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  font-family: var(--font-secondary);
  cursor: pointer;
  transition: all var(--transition-base);
  position: relative;
  overflow: hidden;
  display: block;
  letter-spacing: var(--letter-spacing-wide);
  text-transform: uppercase;
}

.chat-not-found-content .submit-button:hover {
  background-color: var(--background-hover);
  border-color: var(--border-interactive-hover);
  border: 1px solid var(--border-strong);
}

@media (max-width: 768px) {
  .chat-not-found-content {
    padding: var(--spacing-lg);
    max-width: 90%;
    margin: 0 var(--spacing-md);
  }

  .chat-not-found-content h2 {
    font-size: var(--font-size-xl);
  }

  .chat-not-found-content p {
    font-size: var(--font-size-sm);
  }


  .suggestions-list {
    display: flex;
    gap: 12px;
    overflow-x: auto;
    width: 100%;
    min-height: 130px;
    max-height: 170px;
    margin-top: 10px;
  }
  
  .suggestion-item {
    flex: 0 0 auto;
    max-width: 300px;
    min-width: 200px;
    max-height: 100px;
    min-height: 80px;
    white-space: normal;
    word-wrap: break-word;
    padding: 10px;
  }

  
  /* Remove animation since we're using manual scroll */
  
  /* Adjust for mobile */
  @media (max-width: 768px) {
    .suggestions-list {
      gap: 8px;
    }
    
    .suggestion-item {
      min-width: 150px;
      max-width: 250px;
    }
  }
}

/* Add this CSS class */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.default-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.chat-preview {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.chat-preview svg {
  color: var(--text-muted);
}

.chat-preview .preview-arrow {
  color: var(--text-tertiary);
}

.chat-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.chat-title {
  font-weight: 500;
  color: var(--text-primary);
  font-size: 0.95rem;
}

.chat-date {
  font-size: 0.8rem;
  color: var(--text-muted);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .default-sections {
    padding: 0 1rem;
  }

  .chat-preview {
    padding: 0.75rem;
    width: 100%;
  }
}

.suggestions-list {
  margin-bottom: 2rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .chat-preview {
    padding: 0.5rem 0.75rem;
    text-align: left;
    align-content: left;
    margin-left: 0;
    justify-content: flex-start;
    width: 100%;
  }

  .chat-preview-header {
    width: calc(100% - 24px);
  }

  .chat-info {
    width: calc(100% - 40px);
  }

  .chat-title {
    text-align: left;
    width: 100%;
    padding-right: 8px;
  }

  .chat-date {
    text-align: left;
    width: 100%;
  }

  .preview-arrow {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  .chat-preview-icon {
    width: 28px;
    height: 28px;
  }

  .chat-title {
    font-size: 0.9rem;
  }

  .chat-date {
    font-size: 0.75rem;
  }
}

/* Add this CSS class */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.new-chat-button {
  display: flex;
  padding: 8px 16px;
  color: var(--white);
  background: linear-gradient(135deg, var(--primary-color), var(--primary-color));
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 5500;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(var(--primary-rgb), 0.15);
}

@media (max-width: 768px) {
  .new-chat-button {
    padding: 7px 14px;
    font-size: 13px;
    border-radius: 20px;
  }
}

.new-chat-button:hover {
  box-shadow: 0 6px 12px rgba(var(--primary-rgb), 0.3);
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--primary-color) 100%);
  filter: brightness(105%);
}

.new-chat-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(var(--primary-rgb), 0.2);
  filter: brightness(95%);
}

@media (max-width: 568px) and (max-height: 800px) {
  .auth-prompt {
    position: fixed;
    bottom: 25%;
  }
}

/* Enhanced Hover Descriptions */
.chat-page .hover-text {
  position: absolute;
  background: var(--background-main);
  color: var(--text-primary);
  padding: 10px 14px;
  border-radius: 8px;
  font-size: 0.85rem;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transform: translateY(5px) translateZ(0);
  transition: opacity 0.2s var(--ease-in-out),
              transform 0.2s var(--ease-in-out);
  box-shadow: 0 4px 12px var(--shadow-color);
  backdrop-filter: blur(8px);
  border: 1px solid var(--border-color);
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}

/* Hover text themes */
.chat-page .hover-text.primary {
  background: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
}

.chat-page .hover-text.secondary {
  background: var(--background-light);
  border-color: var(--border-color);
}

/* Position variants */
.chat-page .hover-text.top {
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%) translateY(5px) translateZ(0);
}

.chat-page .hover-text.bottom {
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%) translateY(-5px) translateZ(0);
}

.chat-page .hover-text.left {
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%) translateX(5px) translateZ(0);
}

.chat-page .hover-text.right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%) translateX(-5px) translateZ(0);
}

/* Show tooltip */
.chat-page *:hover > .hover-text {
  opacity: 1;
  transform: translateX(-50%) translateY(0) translateZ(0);
}

.chat-page *:hover > .hover-text.left {
  transform: translateY(-50%) translateX(0) translateZ(0);
}

.chat-page *:hover > .hover-text.right {
  transform: translateY(-50%) translateX(0) translateZ(0);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .chat-page .hover-text {
    font-size: 0.75rem;
    padding: 6px 10px;
  }
}

.chat-page .hover-text.resources {
  margin-top: 15px;
}

/* Ensure buttons maintain relative positioning */
.menu-button,
.edit-new-chat-button,
.action-button{
  position: relative;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

/* Add to Space Button Styles */
.add-to-space-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.add-to-space-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 6px;
  padding: 6px 10px;
  cursor: pointer;
  transition: all 0.3s var(--ease-in-out);
  color: var(--text-secondary);
  font-size: 0.9rem;

}

.add-to-space-button:hover {
  background-color: var(--background-light);
  color: var(--primary-color);
  border-color: var(--primary-color);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(var(--primary-rgb), 0.1);
}

.add-to-space-button:active {
  transform: translateY(0);
  box-shadow: 0 1px 2px rgba(var(--primary-rgb), 0.1);
}

/* Add responsive styles */
@media (max-width: 768px) {
  .add-to-space-button {
    padding: 4px 8px;
    font-size: 0.85rem;
  }
}

.space-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: var(--background-main);
  border: 1px solid var(--border-light);
  border-radius: 12px;
  box-shadow: 0 4px 20px var(--shadow-color);
  min-width: 240px;
  overflow: hidden;
  z-index: 1000;
  animation: dropdownFade 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.space-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: var(--background-hover-primary);
  border-bottom: 1px solid var(--border-primary);
}

.space-dropdown-header span {
  font-weight: 600;
  color: var(--text-primary);
  font-size: 0.9rem;
}

.spaces-link {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--primary-color);
  font-size: 0.8rem;
  text-decoration: none;
  padding: 4px 8px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.spaces-link:hover {
  background: var(--background-hover-primary);
  transform: translateY(-1px);
}

.spaces-link svg {
  width: 14px;
  height: 14px;
  transition: transform 0.2s ease;
}

.spaces-link:hover svg {
  transform: translateX(2px);
}

.space-option {
  padding: 10px 16px;
  font-size: 0.9rem;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.space-option:hover {
  background: var(--background-hover-primary);
  color: var(--primary-color);
  padding-left: 20px;
}

.space-option::before {
  content: '';
  position: absolute;
  left: 0;
  width: 3px;
  height: 0;
  background: var(--primary-color);
  transition: height 0.2s ease;
}

.space-option:hover::before {
  height: 100%;
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-8px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .space-dropdown {
    min-width: 200px;
  }

  .space-dropdown-header {
    padding: 10px 14px;
  }

  .space-option {
    padding: 8px 14px;
    font-size: 0.85rem;
  }

  .spaces-link {
    font-size: 0.75rem;
  }
}

/* Chat Header Actions */
.chat-header-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-to-space-container {
  display: flex;
  align-items: center;
}

.add-to-space-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--text-secondary);
  border-radius: 4px;
}

.add-to-space-button:hover {
  background: var(--background-hover);
  color: var(--text-primary);
}

.space-dropdown {
  position: absolute;
  top: calc(100% + 8px) translateZ(0);
  right: 0;
  background: var(--background-main);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 2px 8px var(--shadow-color);
  min-width: 200px;
  z-index: 1000;
}
.return-chat-button {
  display: flex;
  align-items: center;
  background: var(--background-main);
  border-radius: 8px;
  color: var(--text-primary);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.return-chat-button:hover {
  transform: translateY(-1px);
}

.chat-header{
  min-height: 50px;
  max-height: 50px;
}

/* Animation for the processing icon */
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.space-chat-icon {
  color: var(--primary-color);
}

/* Add styles for the spaces link */
.spaces-link {
  color: var(--primary-color);
  font-size: 0.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.spaces-link:hover {
  background-color: var(--background-hover-primary);
  transform: translateY(-1px);
}

.spaces-link svg {
  width: 14px;
  height: 14px;
}
.loading-logo {

  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.spinning-logo {
  width: 24px;
  height: 24px;
  animation: spin 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Enhanced AI Typing Indicator */
.ai-typing-indicator {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0px 8px 16px;
  background: var(--background-main);
  border-radius: 20px;
  max-width: fit-content;
  margin: 0;
  animation: slideIn 0.3s ease-out;
}

.typing-dots {
  display: flex;
  align-items: center;
  gap: 4px;
}

.typing-dots span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--primary-color);
  opacity: 0.6;
  display: inline-block;
}

.typing-dots span:nth-child(1) {
  animation: pulse 1.2s ease-in-out infinite;
}

.typing-dots span:nth-child(2) {
  animation: pulse 1.2s ease-in-out 0.4s infinite;
}

.typing-dots span:nth-child(3) {
  animation: pulse 1.2s ease-in-out 0.8s infinite;
}

.typing-text {
  font-size: 0.9rem;
  color: var(--text-primary);
  opacity: 0.8;
  font-weight: 500;
  background: linear-gradient(90deg, 
    var(--primary-color) 0%, 
    var(--primary-light) 50%, 
    var(--primary-color) 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
}

/* Animations */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .ai-typing-indicator {
    padding: 6px 12px;
    gap: 8px;
  }

  .typing-dots span {
    width: 6px;
    height: 6px;
  }

  .typing-text {
    font-size: 0.8rem;
  }
}

.resource-item.chat {
  margin-top: -5px;
}


.resource-loading-spinner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.message-content-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-buttons{
  display: flex;
  align-items: center;
  gap: 10px;
}

.rounded-icon {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-button{
  padding: 8px;
  color: var(--text-primary);
  border-radius: 8px;
  position: relative;
}

.header-button.hoverable:hover {
  background-color: var(--background-hover);
}

.input-container{
  margin-bottom: 0px;
  background: transparent !important; /* Force transparency */
  padding-top: 0px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom;
  will-change: transform, opacity;
  backdrop-filter: none; /* Remove any backdrop filter */
  -webkit-backdrop-filter: none; /* For Safari support */
}

.upgrade-text{
  color: var(--primary-color);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--background-color);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 1rem;
  color: var(--text-color);
}

.modal-content p {
  margin-bottom: 1.5rem;
  color: var(--text-secondary);
}

.modal-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: var(--input-background);
  color: var(--text-color);
}

.modal-button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.modal-button:disabled {
  background-color: var(--disabled-color);
  cursor: not-allowed;
}

.modal-button:hover:not(:disabled) {
  background-color: var(--primary-color-hover);
}


/* Name Prompt Modal Styles */
.name-prompt-overlay {
  position: fixed;
  inset: 0;
  background: var(--background-main);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-modal);
}

.name-prompt-modal {
  background: var(--background-main);
  border-radius: var(--radius-xl);
  padding: var(--spacing-xl);
  width: 90%;
  max-width: 420px;;
  justify-content: center;
  text-align: center;
}

.name-prompt-modal h2 {
  margin: 0 0 var(--spacing-sm);
  color: var(--text-dark);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  letter-spacing: var(--letter-spacing-tight);
}

.name-prompt-modal p {
  margin: 0 0 var(--spacing-lg);
  color: var(--text-secondary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-relaxed);
}

.name-prompt-modal input {
  width: 100%;
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-base);
  margin-bottom: var(--spacing-md);
  background: var(--input-bg);
  color: var(--text-primary);
  transition: all var(--transition-base);
}

.name-prompt-modal input:focus {
  border-color: var(--border-color);
  outline: none;
}

.name-prompt-error {
  color: var(--error-color);
  font-size: var(--font-size-sm);
  margin: calc(-1 * var(--spacing-md)) 0 var(--spacing-md);
  padding-left: var(--spacing-xs);
}

.name-prompt-buttons {
  display: flex;
  gap: var(--spacing-md);
  justify-content: flex-end;
  margin-top: var(--spacing-sm);
}

.name-prompt-button {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-base);
}

.name-prompt-button.primary {
  background: var(--background-dark);
  color: var(--text-dark);
  border: 1px solid var(--border-strong);
}

.name-prompt-button.primary:hover {
  background: var(--background-hover);
  transform: var(--button-hover-transform);
}

.name-prompt-button.secondary {
  background: transparent;
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
}

.name-prompt-button.secondary:hover {
  background: var(--background-light);
  border-color: var(--border-strong);
  color: var(--text-primary);
}

/* Mobile Responsive Adjustments */
@media (max-width: 480px) {
  .name-prompt-modal {
    padding: var(--spacing-lg);
    margin: 0 var(--spacing-md);
  }

  .name-prompt-modal h2 {
    font-size: var(--font-size-base);
  }

  .name-prompt-modal p {
    font-size: var(--font-size-sm);
  }

  .name-prompt-button {
    padding: var(--spacing-xs) var(--spacing-md);
    font-size: var(--font-size-xs);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Comparison Modal Styles */
.comparison-modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comparison-modal-content {
  background-color: var(--background-main);
  border-radius: 12px;
  width: 90vw;
  height: 90vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px var(--shadow-color);
}
.comparison-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color);
}

.comparison-modal-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
}

.comparison-modal-close {
  padding: 0.5rem;
  color: var(--text-secondary);
  cursor: pointer;
  transition: color 0.2s;
  background: none;
  border: none;
}

.comparison-modal-close:hover {
  color: var(--text-primary);
}

.comparison-panels {
  display: flex;
  gap: 1rem;
  flex: 1;
  overflow: hidden;
}

.comparison-panel {
  flex: 1;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  overflow-y: auto;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.comparison-panel:hover {
  border-color: var(--primary-color);
}

.comparison-panel h3 {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.comparison-message {
  padding: 0.75rem;
  margin: 0.5rem 0;
  border-radius: 8px;
}

.comparison-message.assistant {
  background-color: var(--background-hover-secondary);
}

.comparison-message.user {
  background-color: var(--background-hover-primary);
}

/* Responsive styles */
@media (max-width: 768px) {
  .comparison-modal-content {
    width: 95vw;
    height: 95vh;
    padding: 0.75rem;
  }

  .comparison-panels {
    flex-direction: column;
  }

  .comparison-panel {
    max-height: 40vh;
  }
}

.think-harder-option {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 0px 6px;
  background-color: var(--background-light);
  border-radius: 10px;
  cursor: pointer;
  font-size: 0.78rem;
  color: var(--text-primary);
  transition: all 0.2s ease;
  border: 1px solid var(--border-color);
  position: relative;
  user-select: none;
}

/* Active state */
.think-harder-option.active {
  border: 1px solid var(--primary-color);
}

/* Hover state */
.think-harder-option:hover {
  background-color: var(--background-hover);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .think-harder-option {
    padding: 4px 10px;
    font-size: 0.8rem;
  }
}


.sparkles-active{
  color: var(--primary-color);
}



/* Mini Loading Display */
.mini-loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
}

.mini-loading-circle {
  width: 24px;
  height: 24px;
  border: 2px solid var(--background-light);
  border-top: 2px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .mini-loading {
    width: 20px;
    height: 20px;
  }

  .mini-loading-circle {
    width: 20px;
    height: 20px;
    border-width: 1.5px;
  }
}


/* Simple fade-in animation for the overlay */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}