.study-spaces-container {
    padding: 0 2rem 2rem 2rem;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    background-color: var(--background-main);
    overflow-x: hidden;
  }

.recent-chats-list{
  padding: 1rem 1rem;
}
  
.study-spaces-container  .study-spaces-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    margin-bottom: 2rem;
    position: relative;
    background: var(--background-main);
    border-radius: 16px;
    padding: 1.75rem;
    margin-bottom: 2.5rem;

    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
  }
  
.study-spaces-container  .header-left {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
.study-spaces-container  .return-to-chats {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    border-radius: 12px;
    color: var(--text-tertiary);
    font-weight: 500;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    text-decoration: none;
    background: var(--background-light);
    border: 1px solid var(--border-light);
  }
  
  .study-spaces-container .return-to-chats:hover {
    background: var(--background-hover);
    transform: translateX(-2px);
    color: var(--text-primary);
  }
  
  .study-spaces-container .header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .study-spaces-container .study-spaces-header h1 {
    font-size: 2rem;
    color: var(--text-primary);
    margin: 0;
    font-weight: 700;
    letter-spacing: -0.025em;
    position: relative;
  }
  
  .study-spaces-container .study-spaces-header h1::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 3px;
    background: var(--primary-gradient);
    border-radius: 2px;
  }
  
  .study-spaces-container .create-space-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.25rem;
    color: var(--text-light);
    background: var(--primary-gradient);
    border: none;
    border-radius: 0.75rem;
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 8px var(--shadow-primary);
    gap: 0.5rem;
  }
  
  .study-spaces-container .create-space-btn:hover {
    box-shadow: 0 4px 12px var(--shadow-primary-hover);
    transform: translateY(-2px);
  }
  
  .study-spaces-container .search-bar {
    position: relative;
    margin: 0 auto 2.5rem;
    max-width: 600px;
    transform-origin: center;
    transition: all 0.3s ease;
    background: linear-gradient(to right, var(--background-main), var(--background-light));
    border-radius: 16px;


  }
  
  .study-spaces-container .search-bar input {
    width: 100%;
    padding: 12px 16px 12px 46px;
    border: 2px solid var(--border-light);
    border-radius: 24px;
    font-size: 15px;
    background: var(--background-main);
    color: var(--text-primary);
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px var(--shadow-color);
    background: transparent;
    font-size: 1rem;
    padding: 1rem 1rem 1rem 3rem;
  }
  
  .study-spaces-container .search-bar input:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 4px 12px var(--shadow-primary);
  }
  
  .study-spaces-container .search-bar .search-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-tertiary);
    transition: color 0.3s ease;
  }
  
  .study-spaces-container .search-bar input:focus + .search-icon {
    color: var(--primary-color);
  }
  
  .study-spaces-container .study-spaces-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1.5rem;
    margin: 2rem auto 0;
    max-width: 1400px;
  }
  
  .study-spaces-container .study-space-card {
    position: relative;
    padding: 1.5rem;
    border-radius: 1rem;
    background: var(--background-main);
    border: 1px solid var(--border-color);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 2px 4px var(--shadow-color);
    background: linear-gradient(145deg, var(--background-main), var(--background-light));
    border-radius: 16px;
    padding: 1.75rem;
    box-shadow: 0 4px 20px var(--shadow-color);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .study-spaces-container .study-space-card:hover {
    transform: translateY(-6px);
  }
  
  .study-space-card svg{
    color: var(--text-primary);
  }
  .study-spaces-container .space-header {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .study-spaces-container .space-header h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--text-primary);
    line-height: 1.4;
  }
  
  .study-spaces-container .space-stats {
    display: flex;
    gap: 1rem;
    padding: 0.75rem;
    background: var(--background-light);
    border-radius: 0.75rem;
    margin-top: auto;
  }
  
  .study-spaces-container .stat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-tertiary);
    font-size: 0.9rem;
  }
  
  .study-spaces-container .space-meta {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-tertiary);
    font-size: 0.85rem;
    margin-top: auto;
    padding-top: 0.75rem;
    border-top: 1px solid var(--border-light);
  }
  
  .study-spaces-container .space-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;
    padding-top: 0.75rem;
    border-top: 1px solid var(--border-light);
  }
  
  .study-spaces-container .arrow-icon {
    color: var(--primary-color);
    transition: transform 0.2s ease;
  }
  
  .study-spaces-container .study-space-card:hover .arrow-icon {
    transform: translateX(4px);
  }
  
  .study-spaces-container .card-actions {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    gap: 0.5rem;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  
  .study-spaces-container .study-space-card:hover .card-actions {
    opacity: 1;
  }
  
  .study-spaces-container .card-actions button {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    color: var(--text-muted);
    border-radius: 4px;
    transition: all 0.2s ease;
  }
  
  .study-spaces-container .card-actions button:hover {
    background-color: var(--background-light);
    color: var(--text-primary);
    transform: scale(1.1);
  }
  
  .study-spaces-container .delete-btn:hover {
    color: var(--error-color);
  }
  
  @media (max-width: 768px) {
    .study-spaces-container {
      padding: 1rem;
    }
  
    .study-spaces-container .study-spaces-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      padding: 1rem 0;
    }
  
    .study-spaces-container .header-left {
      width: 100%;
      justify-content: space-between;
    }
  
    .study-spaces-container .header-actions {
      width: 100%;
    }
  
    .study-spaces-container .create-space-btn {
      flex: 1;
      justify-content: center;
    }
  
    .study-spaces-container .return-to-chats {
      padding: 0.5rem 0.75rem;
      font-size: 0.9rem;
    }

  
    .study-spaces-container .study-spaces-grid {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      gap: 1rem;
    }
  
    .study-spaces-container .card-actions {
      opacity: 1;
    }
  
    .study-spaces-container .study-space-card {
      height: auto;
      min-height: 120px;
      padding: 1.25rem;
    }
  
    .study-spaces-container .space-stats {
      padding: 0.5rem 0.75rem;
    }
  }
  
  .study-spaces-container .create-space-modal-header h2 {
    color: var(--text-primary);
  }

  .study-spaces-container .create-space-form-step h3 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-primary);
    font-weight: 600;
    letter-spacing: -0.01em;
  }
  
  .study-spaces-container .create-space-form-title {
    font-size: 1.25rem;
    color: var(--text-primary);
    font-weight: 600;
    letter-spacing: -0.01em;
  }
  
  .study-spaces-container .create-space-modal-header .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--text-primary);
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 12px;
    transition: all 0.2s ease;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

  }
  
  .study-spaces-container .create-space-modal-header .close-button:hover {
    background-color: var(--background-light);
    color: var(--text-primary);
    transform: rotate(90deg);
  }
  
  .study-spaces-container .create-space-modal-body {
    padding: 2.5rem;
  }

  .study-spaces-container .pdf-processing-message {
    background: var(--background-light);
    border-radius: var(--radius-lg);
    padding: 1.5rem;
    margin: 1rem 0;
    border: 2px solid var(--border-light);
  }

  .study-spaces-container .processing-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .study-spaces-container .processing-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: var(--radius-md);
    background: var(--background-hover-primary);
    color: var(--primary-color);
  }

  .study-spaces-container .processing-text {
    flex: 1;
  }

  .study-spaces-container .processing-text h4 {
    color: var(--text-primary);
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-semibold);
    margin: 0 0 0.25rem 0;
  }

  .study-spaces-container .processing-text p {
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
    margin: 0;
    line-height: var(--line-height-snug);
  }

  .study-spaces-container .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  .study-spaces-container .form-step {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .study-spaces-container .form-step h3 {
    margin: 0;
    color: var(--text-primary);
    font-size: 1.35rem;
    font-weight: 600;
    letter-spacing: -0.01em;
  }
  
  .study-spaces-container .title-input,
  .study-spaces-container .instructions-input {
    width: 100%;
    padding: 1rem 1.25rem;
    border: 2px solid var(--border-light);
    border-radius: 12px;
    font-size: 1.1rem;
    transition: all 0.2s ease;
    background-color: var(--background-light);
    color: var(--text-primary);
  }
  
  .study-spaces-container .title-input::placeholder,
  .study-spaces-container .instructions-input::placeholder {
    color: var(--text-tertiary);
  }
  
  .study-spaces-container .instructions-input {
    min-height: 180px;
    resize: vertical;
    line-height: 1.6;
  }
  
  .study-spaces-container .title-input:focus,
  .study-spaces-container .instructions-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 4px var(--shadow-primary);
    background-color: var(--background-main);
  }
  
  .study-spaces-container .button-group {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .study-spaces-container .next-button,
  .study-spaces-container .back-button,
  .study-spaces-container .create-button {
    padding: 1rem 2rem;
    border-radius: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    letter-spacing: -0.01em;
  }
  
  .study-spaces-container .next-button,
  .study-spaces-container .create-button {
    background: var(--primary-gradient);
    color: var(--text-light);
    border: none;
    flex: 1;
    position: relative;
    overflow: hidden;
  }
  
  .study-spaces-container .next-button::before,
  .study-spaces-container .create-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    transform: translateX(-100%);
    transition: transform 0.6s ease;
  }
  
  .study-spaces-container .next-button:hover::before,
  .study-spaces-container .create-button:hover::before {
    transform: translateX(100%);
  }
  
  .study-spaces-container .back-button {
    background: var(--background-light);
    color: var(--text-tertiary);
    border: 1px solid var(--border-light);
    min-width: 120px;
  }
  
  .study-spaces-container .next-button:disabled,
  .study-spaces-container .create-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .study-spaces-container .next-button:not(:disabled):hover,
  .study-spaces-container .create-button:not(:disabled):hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px var(--shadow-primary);
  }
  
  .study-spaces-container .back-button:hover {
    background: var(--background-hover);
    transform: translateY(-2px);
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(30px) scale(0.95);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  
  @media (max-width: 640px) {
    .study-spaces-container .modal-content {
      width: 100%;
      margin: 1rem;
      max-height: calc(100vh - 2rem);
      overflow-y: auto;
    }
  
    .study-spaces-container .modal-header {
      padding: 1.25rem 1.5rem;
    }
  
    .study-spaces-container .modal-body {
      padding: 1.5rem;
    }
  
    .study-spaces-container .next-button,
    .study-spaces-container .back-button,
    .study-spaces-container .create-button {
      padding: 0.875rem 1.5rem;
      font-size: 1rem;
    }
  }
  
  .study-spaces-container .create-space-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-overlay);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(8px);
    padding: 1rem;
  }
  
  .study-spaces-container .create-space-modal-content {
    background: var(--background-main);
    border-radius: 24px;
    width: 95%;
    max-width: 800px;
    box-shadow: 0 8px 32px var(--shadow-color);
    animation: modalSlideIn 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
  }
  
  .study-spaces-container .create-space-modal-header {
    padding: 1.75rem 2rem;
    border-bottom: 1px solid var(--border-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(135deg, var(--background-hover-secondary) 0%, var(--background-main) 100%);
  }
  
  .study-spaces-container .create-space-progress {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1.5rem;
    background: var(--background-light);
    border-bottom: 1px solid var(--border-light);
  }
  
  .study-spaces-container .progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    flex: 1;
    max-width: 120px;
  }
  
  .study-spaces-container .progress-step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 20px;
    right: -1rem;
    width: calc(100% - 40px);
    height: 2px;
    background: var(--border-light);
    transform: translateX(50%);
  }
  
  .study-spaces-container .progress-step.completed:not(:last-child)::after {
    background: var(--text-secondary);
  }
  
  .study-spaces-container .step-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--background-main);
    border: 2px solid var(--border-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--text-muted);
    transition: all 0.3s ease;
  }
  
  .study-spaces-container .progress-step.active .step-number {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--text-light);
    transform: scale(1.1);
  }
  
  .study-spaces-container .progress-step.completed .step-number {
    background: var(--text-secondary);
    border-color: var(--text-secondary);
    color: var(--text-light);
  }
  
  .study-spaces-container .step-label {
    font-size: 0.9rem;
    color: var(--text-muted);
    font-weight: 500;
    justify-content: center;
    text-align: center;
  }
  
  .study-spaces-container .progress-step.active .step-label {
    color: var(--primary-color);
    font-weight: 600;
  }
  .study-spaces-container .file-upload-area {
    border: 2px dashed var(--border-light);
    border-radius: 16px;
    padding: 2rem;
    text-align: center;
    transition: all 0.3s ease;
    background: var(--background-light);
    margin-bottom: 1.5rem;
  }
  
  .study-spaces-container .file-upload-area:hover {
    border-color: var(--primary-color);
    background: var(--background-hover-primary);
  }
  
  .study-spaces-container .file-input {
    display: none;
  }
  
  .study-spaces-container .file-upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    color: var(--text-muted);
  }
  
  .study-spaces-container .uploaded-files {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .study-spaces-container .uploaded-file {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    background: var(--background-light);
    border-radius: 12px;
    border: 1px solid var(--border-light);
    color: var(--text-primary);
  }
  
  .study-spaces-container .remove-file {
    margin-left: auto;
    background: none;
    border: none;
    color: var(--text-muted);
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.2s ease;
  }
  
  .study-spaces-container .remove-file:hover {
    background: var(--background-dark);
    color: var(--text-light);
  }
  
  .study-spaces-container .validation-message {
    color: var(--error-color);
    font-size: 0.9rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
    min-height: 1.2rem;
  }
  
  .study-spaces-container .loading-spinner {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
  }
  
  .study-spaces-container .loading-dots {
    display: flex;
    gap: 3px;
    align-items: center;
    height: 100%;
  }
  
  .study-spaces-container .loading-dots span {
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 50%;
    animation: loadingDot 1.4s infinite;
    opacity: 0.7;
  }
  
  .study-spaces-container .loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .study-spaces-container .loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes loadingDot {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-2px); }
  }
  
  @media (max-width: 640px) {
    .study-spaces-container .create-space-progress {
      padding: 1rem;
      gap: 1rem;
    }
  
    .study-spaces-container .step-number {
      width: 32px;
      height: 32px;
      font-size: 0.9rem;
    }
  
    .study-spaces-container .step-label {
      font-size: 0.8rem;
    }
  
    .study-spaces-container .progress-step:not(:last-child)::after {
      top: 16px;
    }

  }
  
  /* Add hover text styles for card actions */
  .study-spaces-container .hover-text {
    position: absolute;
    background: var(--background-dark);
    color: var(--text-light);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    pointer-events: none;
  }
  
  .study-spaces-container .hover-text.top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-8px);
  }
  
  .study-spaces-container .card-actions button:hover .hover-text {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-4px);
  }
  
  /* Add missing modal body styles */
  .study-spaces-container .create-space-modal-body {
    padding: 2rem;
    overflow-y: auto;
  }
  
  .study-spaces-container .create-space-form-step {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .study-spaces-container .modal-input {
    width: 100%;
    padding: 12px 16px;
    border: 2px solid var(--border-light);
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.2s ease;
    color: var(--text-primary);
  }
  
  .study-spaces-container .modal-textarea {
    width: 100%;
    padding: 12px 16px;
    border: 2px solid var(--border-light);
    border-radius: 12px;
    font-size: 1rem;
    resize: vertical;
    min-height: 120px;
    transition: all 0.2s ease;
  }
  
  .study-spaces-container .modal-input:focus,
  .study-spaces-container .modal-textarea:focus {
    outline: none;
  }
  
  .study-spaces-container .primary-button {
    background: var(--primary-gradient);
    color: var(--text-light);
    padding: 12px 24px;
    border: none;
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 120px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .study-spaces-container .secondary-button {
    background: var(--background-light);
    color: var(--text-tertiary);
    padding: 12px 24px;
    border: 1px solid var(--border-light);
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .study-spaces-container .primary-button:hover:not(:disabled) {
    box-shadow: 0 4px 12px var(--shadow-primary);
  }

  .study-spaces-container .primary-button:hover {
    background-color: var(--primary-dark);
  }
  
  .study-spaces-container .secondary-button:hover {
    background: var(--background-hover);
  }
   
    .study-spaces-container .return-to-chats:hover {
      background-color: var(--background-hover);
    }
  
  .study-spaces-container .empty-spaces-state h2 {
    justify-content: center;
  }
  
  .study-spaces-container .empty-spaces-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    padding: 2rem;
  }
  
  .study-spaces-container .empty-spaces-content {
    text-align: center;
    max-width: 400px;
    animation: fadeIn 0.5s ease;
  }
  
  .study-spaces-container .empty-spaces-content .empty-icon {
    color: var(--primary-color);
    margin-bottom: 1.5rem;
    opacity: 0.9;
  }
  
  .study-spaces-container .empty-spaces-content h2 {
    font-size: 1.5rem;
    color: var(--text-dark);
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .study-spaces-container .empty-spaces-content p {
    color: var(--text-secondary);
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .study-spaces-container .create-space-btn-empty {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem 1.5rem;
    color: var(--text-light);
    background: var(--primary-gradient);
    border: none;
    border-radius: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 8px var(--shadow-primary);
    gap: 0.5rem;
  }
  
  .study-spaces-container .create-space-btn-empty:hover {
    box-shadow: 0 4px 12px var(--shadow-primary-hover);
    transform: translateY(-2px);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 640px) {
    .study-spaces-container .empty-spaces-state {
      min-height: 300px;
      padding: 1rem;
    }
  
    .study-spaces-container .empty-spaces-content h2 {
      font-size: 1.25rem;
    }
  
    .study-spaces-container .empty-spaces-content p {
      font-size: 0.95rem;
    }
  }
  
  @keyframes shimmer {
    0% { background-position: -1000px 0 }
    100% { background-position: 1000px 0 }
  }
  
  .study-spaces-container .loading-skeleton {
    background: linear-gradient(90deg, var(--background-hover) 25%, var(--background-light) 50%, var(--background-hover) 75%);
    background-size: 1000px 100%;
    animation: shimmer 2s infinite linear;
  }
  
  @media (max-width: 640px) {
    .study-spaces-container .search-bar {
      margin: 1rem 0;
    }
    
    .study-spaces-container .search-bar input {
      font-size: 14px;
      padding: 10px 16px 10px 40px;
    }
  }
  
  .study-spaces-container .study-space-card:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--border-primary-hover);
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .study-spaces-container .study-space-card {
    animation: fadeInUp 0.6s ease forwards;
    animation-delay: calc(var(--index) * 0.1s);
  }