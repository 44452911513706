/* Modal Overlay */
.image-modal-overlay {
  position: fixed;
  inset: 0;
  z-index: var(--z-modal);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--glass-background);
  backdrop-filter: blur(0);
  animation: modalFadeIn var(--transition-base) var(--transition-bezier);
}

/* Modal Container */
.image-modal-container {
  position: relative;
  max-width: clamp(300px, 80vw, 800px);
  max-height: clamp(300px, 85vh, 900px);
  margin: var(--spacing-md);
  background: var(--glass-background);
  border: 1px solid var(--border-glass);
  border-radius: var(--radius-2xl);
  overflow: hidden;
  box-shadow: var(--shadow-elevated);
  animation: modalScaleIn var(--transition-base) var(--transition-bezier);
}

/* Modal Header */
.image-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-md) var(--spacing-md);
  background: var(--glass-background);
  border-bottom: 1px solid var(--border-glass);
  backdrop-filter: var(--blur-sm);
}

.image-modal-title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  letter-spacing: var(--letter-spacing-tight);
  margin-right: var(--spacing-lg);
  line-height: var(--line-height-snug);
}

/* Close Button */
.image-modal-close {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: var(--background-hover);
  border: 1.5px solid var(--border-interactive);
  border-radius: var(--radius-full);
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-base) var(--transition-bezier);
}

.image-modal-close:hover {

  color: var(--primary-color);
  box-shadow: var(--shadow-primary-hover);
}

.image-modal-close:active {
  transform: scale(0.95);
}

.image-modal-close:focus-visible {
  outline: none;
  box-shadow: var(--focus-ring);
}

/* Image Container */
.image-modal-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--background-main);
  max-height: calc(85vh - 120px);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.image-modal-content::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}

.image-modal-content::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: var(--radius-full);
}

.image-modal-content::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: var(--radius-full);
  border: 2px solid var(--scrollbar-track);
}

.image-modal-content::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

.image-error-message {
  padding: var(--spacing-xl);
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-relaxed);
}

/* Modal Image */
.modal-image {
  max-height: calc(85vh - 120px);
  max-width: 100%;
  object-fit: contain;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-elevated);
  margin-bottom: var(--spacing-xs);
  transition: transform var(--transition-base) var(--transition-bezier);
}

.image-modal-info {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-snug);
  letter-spacing: var(--letter-spacing-wide);
}

/* Animations */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    backdrop-filter: blur(0);
  }
  to {
    opacity: 1;
    backdrop-filter: var(--blur-lg);
  }
}

@keyframes modalScaleIn {
  from {
    transform: scale(0.95) translateY(10px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

/* Loading State */
.image-loading {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-main);
  border-radius: var(--radius-lg);
  backdrop-filter: var(--blur-md);
}

.image-loading::after {
  content: "";
  width: 44px;
  height: 44px;
  border: 3px solid var(--border-primary);
  border-top-color: var(--primary-color);
  border-radius: var(--radius-full);
  box-shadow: 0 0 20px var(--shadow-primary);
  animation: spin 1s var(--ease-in-out) infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .image-modal-container {
    max-width: 95vw;
    margin: var(--spacing-xs);
  }

  .image-modal-header {
    padding: var(--spacing-sm) var(--spacing-md);
  }

  .image-modal-title {
    font-size: var(--font-size-base);
  }

  .image-modal-close {
    width: 32px;
    height: 32px;
  }

  .image-modal-content {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
}