/* Resources Section */
.resources-section {
  position: fixed;
  right: 0;
  top: 60px;
  width: var(--resources-width);
  background: var(--background-light);
  backdrop-filter: var(--blur-sm);
  -webkit-backdrop-filter: var(--blur-sm);
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - 70px);
  max-width: 1200px;
  transition: 
    width var(--transition-base),
    margin-right var(--transition-base);
  border-radius: 16px 0 0 16px;
  border-top: 1px solid var(--border-light);
  border-bottom: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  overflow: hidden;
  transform: translateX(100%);
  z-index: var(--z-fixed);
}

.resources-section.expanded {
  border-radius: 16px;
  border: none;
}

.resources-section.open {
  transform: translateX(0);
}

.resources-section.closed {
  transform: translateX(100%);
}

/* Consolidated media queries */
@media (max-width: 500px) {
  .resources-section {
    position: fixed;
    bottom: 0;
    top: 12vh;
    height: 88vh;
    transition: transform 0.4s var(--transition-bezier),
                opacity var(--transition-fast);
    background: var(--background-main);
    backdrop-filter: none;
    border-right: 1px solid var(--border-light);
    width: 85%;
    margin: 0 6% 0 1%;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -4px 20px var(--card-shadow);
  }
  .resources-section.expanded {
    width: 95%;
    margin: 0 2.5%;
  }
}

@media (min-width: 768px) {
  .resources-section.expanded {
    margin-right: 8px;
  }
}

@media (min-width: 1001px) {
  .resources-section {
    width: var(--resources-width-medium);
  }
  .resources-section.expanded {
    width: var(--resources-width-expanded);
  }
}

@media (min-width: 1380px) {
  .resources-section {
    width: var(--resources-width-large);
  }
}

/* Header styles */
.resources-header {
  background-color: var(--background-light);
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  position: relative;
}

.resources-header h3 {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.resources-header h3 svg {
  font-size: 1.25rem;
  color: var(--primary-color);
}

.resources-header .close-button {
  background: transparent;
  border: none;
  color: var(--text-secondary);
  font-size: 1rem;
  cursor: pointer;
  transition: all var(--transition-fast);
  padding: 0.4rem;
  border-radius: 50%;
  position: absolute;
  right: 0.75rem;
}

.resources-header .close-button:hover {
  background-color: var(--shadow-color);
  color: var(--primary-color);
  transform: scale(1.05);
}

/* Content area */
.resources-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  z-index: var(--z-fixed);
  margin-top: -0.25rem;
}

@media (max-width: 768px) {
  .resources-content {
    padding: 12px;
  }
}

/* Scrollable lists */
.pdf-resources-list,
.other-resources-list {
  overflow-y: auto;
  padding-right: 8px;
}

.pdf-resources-list { max-height: 25vh; }
.other-resources-list { max-height: 35vh; }
.pdf-resources-list.notempty,
.other-resources-list.notempty { margin-top: 12px; }

.resources-section .section-title.materials {
  margin-top: var(--spacing-sm);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1rem;
  color: var(--text-secondary);
}

/* Form styles */
.form-section{
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

/* Form Groups */
.form-group {
  margin-bottom: 12px;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  color: var(--text-primary);
}

.form-group select {
  width: 100%;
  padding: 8px 28px 8px 12px;
  font-size: 0.85rem;
  border: 1px solid var(--resources-border-color);
  border-radius: 6px;
  background-color: var(--background-main);
  color: var(--text-primary);
  appearance: none;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.form-group select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--shadow-primary);
}

.form-group select:hover {
  border-color: var(--primary-color);
}

.form-group select {
  border: 1px solid var(--resources-border-color);
}

/* Button styles */
.save-button {
  background-color: var(--background-dark);
  color: var(--text-primary);
  border: none;
  border-radius: var(--radius-sm);
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.save-button:hover {
  background-color: var(--background-light);
}

.remove-button {
  background: transparent;
  border: none;
  font-size: 1.1rem;
  color: var(--text-secondary);
  cursor: pointer;
  transition: color var(--transition-fast);
}

.remove-button:hover {
  color: var(--text-primary);
}

/* Message styles */
.error-message {
  color: var(--error-color);
  font-size: 0.8rem;
  margin-top: 8px;
}

.success-message {
  color: var(--success-color);
  font-size: 0.8rem;
  margin-top: 8px;
}

/* Resource item styles */
.resource-item {
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-xs);
  background: var(--background-main);
  transition: 
    background-color var(--transition-fast),
    box-shadow var(--transition-fast),
    transform var(--transition-fast);
  cursor: pointer;
  border: 1px solid var(--border-interactive-hover);
  position: relative;
  overflow: hidden;
}

.resources-section .section-title {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  line-height: var(--line-height-snug);
  letter-spacing: var(--letter-spacing-wide);
  transition: color var(--transition-fast);
}

.resource-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: var(--background-light);
  margin-right: 10px;
  transition: all var(--transition-fast);
  flex-shrink: 0;
  color: var(--text-secondary);
}

.resource-info {
  flex-grow: 1;
  margin-right: 6px;
  overflow: hidden;
}

.resource-title {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-primary);
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resource-arrow {
  font-size: 0.7rem;
  color: var(--text-tertiary);
  transition: all var(--transition-fast);
  opacity: 0.7;
  flex-shrink: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .resource-item {
    padding: 6px 8px;
    min-height: 36px;
  }

  .resource-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .resource-title {
    font-size: 0.75rem;
  }
}

.expanded-resource-content {
  padding: 16px;
  overflow-y: auto;
  flex: 1;
}

.expanded-resource-content.code-content {
  padding: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
}

/* Ensure the expanded resource container takes full height */
.expanded-resource {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: width var(--transition-slow), margin-left var(--transition-slow);
  overflow: hidden;
}

.resource-info {
  flex-grow: 1;
}

.expanded-resource-content.code-content {
  padding: 0;
  margin-top: 0;
}

.resource-title {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
}

.expanded-resource-content p {
  line-height: 1.4;
}

.resource-type {
  font-size: 11px;
  color: var(--text-secondary);
  text-transform: capitalize;
}

.resource-arrow {
  font-size: 14px;
  color: var(--text-secondary);
}

/* Resource type-specific styles */
.resource-item.pdf {
  border-left: 2px solid var(--error-color);
}

.resource-item.pdf .resource-icon {
  color: var(--error-color);
  background-color: var(--background-hover-secondary);
}

.resource-item.essay {
  border-left: 2px solid var(--primary-color);
}

.resource-item.essay .resource-icon {
  color: var(--text-primary);

}

.resource-item.code {
  border-left: 2px solid var(--primary-color);
}

.resource-item.code .resource-icon {
  color: var(--text-primary);

}

.resource-item.general {
  border-left: 2px solid var(--primary-color);
}

.resource-item.general .resource-icon {
  color: var(--primary-color);

}

/* Compact Layout for Small Screens */
@media (max-width: 768px) {
  .resource-item {
    padding: 8px;
  }

  .resource-icon {
    font-size: 14px;
    margin-right: 6px;
  }

  .resource-title {
    font-size: 11px;
  }

  .resource-type {
    font-size: 9px;
  }
}

.copy-button {
  background-color: var(--background-main);
  border: 1px solid var(--border-light);
  color: var(--text-secondary);
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  transition: all var(--transition-fast);
  right: 6px;
  top: 6px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 4px;
  box-shadow: 0 1px 2px var(--shadow-color);
}

.copy-button:hover {
  background-color: var(--background-light);
  border-color: var(--background-hover);
  box-shadow: 0 2px 4px var(--shadow-color);
}

.copy-button:active {
  transform: translateY(0);
  box-shadow: 0 1px 2px var(--shadow-color);
}

@media (max-width: 768px) {
  .copy-button {
    padding: 3px 6px;
    font-size: 0.75rem;
  }
}

.preferences-toggle {
  font-size: 1.1rem;
  transition: transform var(--transition-fast);
}

.preferences-toggle.open {
  transform: rotate(180deg);
}

.preferences-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition-fast);
}

.preferences-content.open {
  max-height: 400px;
  transition: max-height var(--transition-slow);
}

/* Empty Resources Message - Updated Styles */
.empty-resources-message {
  background: linear-gradient(to bottom right, var(--background-main), var(--background-light));
  border-radius: 12px;
  padding: 14px;
  text-align: center;
  margin: 12px 0;
  border: 1px dashed var(--border-color);
  transition: all var(--transition-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.empty-resources-message .icon-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--background-light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

.empty-resources-message .icon-container svg {
  font-size: 20px;
  color: var(--text-secondary);
}

.empty-resources-message strong {
  display: block;
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 3px;
}

.empty-resources-message p {
  color: var(--text-secondary);
  font-size: 0.8rem;
  line-height: 1.4;
  margin: 0;
}

.empty-resources-message .suggestion {
  font-size: 0.75rem;
  color: var(--primary-color);
  margin-top: 6px;
}

@media (max-width: 768px) {
  .empty-resources-message {
    padding: 16px;
  }

  .empty-resources-message .icon-container {
    width: 36px;
    height: 36px;
  }

  .empty-resources-message .icon-container svg {
    font-size: 18px;
  }

  .empty-resources-message strong {
    font-size: 0.9rem;
  }

  .empty-resources-message p {
    font-size: 0.75rem;
  }
}

.pdf-link {
  display: inline-flex;
  align-items: center;
  background-color: var(--background-overlay-light);
  color: var(--text-quaternary);
  text-decoration: none;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.75rem; 
  transition: all 0.3s ease;
  border: 1px solid var(--border-color);
  margin-left: auto;
}

.pdf-link:hover {
  background-color: var(--background-overlay-light);
  color: var(--text-primary);
  box-shadow: 0 1px 2px var(--card-shadow);
}

.pdf-link svg {
  margin-left: 3px;
  font-size: 0.65rem;
}

@media (max-width: 768px) {
  .pdf-link {
    font-size: 0.65rem;
    padding: 2px 4px;
  }
}

@media (max-width: 768px) {


  .version-toggle-icon {
    font-size: 0.8rem;
  }
}

/* Responsive Design */
@media (max-width: 500px) {
  .resources-section {
    width: 85%;
    margin-left: 1%;
    margin-right: 6%;
  }

  .resources-section.expanded{
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  .resources-content {
    padding: 12px;
  }

  .resource-item {
    padding: 8px;
  }

  .resource-icon {
    font-size: 1rem;
    margin-right: 8px;
  }

  .resource-title {
    font-size: 0.75rem;
  }

  .resource-type {
    font-size: 0.65rem;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pdf-resources-list,
  .other-resources-list {
    max-height: 20vh;
  }
}


  .resources-section {
    max-height: 85vh;
  }


/* Global scrollbar styles for desktop */
@media (min-width: 769px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--text-tertiary) var(--background-secondary);
  }

  *::-webkit-scrollbar {
    width: 1px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--text-tertiary);
    border-radius: 1px;
  }

  *::-webkit-scrollbar-track {
    background-color: var(--background-secondary);
  }
}

/* Remove or update any existing scrollbar styles */
.memory-list::-webkit-scrollbar,
.pdf-resources-list::-webkit-scrollbar,
.other-resources-list::-webkit-scrollbar {
  width: 1px;
}

.memory-list::-webkit-scrollbar-thumb,
.pdf-resources-list::-webkit-scrollbar-thumb,
.other-resources-list::-webkit-scrollbar-thumb {
  background-color: var(--text-tertiary);
  border-radius: 1px;
}

.memory-list::-webkit-scrollbar-track,
.pdf-resources-list::-webkit-scrollbar-track,
.other-resources-list::-webkit-scrollbar-track {
  background-color: var(--background-secondary);
}

.resource-loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--background-primary);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px var(--card-shadow);
  margin: 0.75rem 0;
  min-height: 200px;
  border: 1px solid var(--border-color);
  text-align: center;
}

.loading-spinner {
  width: 36px;
  height: 36px;
  border: 2px solid var(--background-secondary);
  border-top: 2px solid var(--accent-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.resource-loading-indicator h3 {
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.4rem 0;
}

.resource-loading-indicator p {
  color: var(--text-secondary);
  font-size: 0.8rem;
  margin: 0;
  line-height: 1.3;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .resource-loading-indicator {
    padding: 1.5rem;
    min-height: 150px;
  }

  .loading-spinner {
    width: 32px;
    height: 32px;
    margin-bottom: 0.75rem;
  }

  .resource-loading-indicator h3 {
    font-size: 0.9rem;
  }

  .resource-loading-indicator p {
    font-size: 0.75rem;
  }
}

/* Update the resources-section for mobile */
@media (max-width: 500px) {
  .resources-section {
    z-index: 1000;
    position: fixed;
    border-radius: 12px 12px 0 0;
    width: 88%;
    border-bottom: none;
    transform: translateY(100%);
    transition: transform var(--transition-speed) ease-in-out;
    box-shadow: 0 -4px 20px var(--card-shadow);
    top:12vh;
    display: flex;
    flex-direction: column;
  }

  .resources-content {
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 12px;
  }

  /* Update scrollable sections */
  .pdf-resources-list,
  .other-resources-list {
    max-height: 30vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-right: 6px;
  }
}

/* Add after the existing resource type styles */
.resource-item.visualization {
  border-left: 2px solid var(--accent-primary);
}

.resource-item.visualization .resource-icon {
  color: var(--accent-primary);
  background-color: var(--background-secondary);
}

/* Update the expanded resource styles */
.expanded-resource {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--background-secondary);
  border-radius: 8px;
  overflow: hidden;
}

.expanded-resource-content {
  flex: 1;
  overflow-y: auto;
  background: var(--background-secondary);
}

.expanded-resource-content.visualization-content {
  background: var(--background-secondary);
  padding: 16px;
}

.visualization-wrapper {
  background: var(--background-secondary);
  border-radius: 8px;
  box-shadow: 0 3px 8px var(--card-shadow);
  padding: 16px;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

.visualization-tab-content {
  height: 100%;
  min-height: 300px;
  padding: 12px;
  background: var(--background-secondary);
  border-radius: 6px;
}

/* Update visualization resource item styles */
.resource-item.visualization {
  border-left: 3px solid var(--accent-primary);
  background: linear-gradient(45deg, var(--overlay-color), transparent);
}

.resource-item.visualization .resource-icon {
  color: var(--accent-primary);
  background-color: var(--overlay-color);
}

.resource-item.visualization:hover {
  background: linear-gradient(45deg, var(--overlay-color), var(--overlay-color));
}

/* Update the PDF Resources Section styles */
.study-materials-section {
  max-height: 18vh;
  overflow-y: auto;
  padding-right: 6px;
  margin-bottom: 20px;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}

/* Ensure consistent scrollbar styling */
.study-materials-section::-webkit-scrollbar {
  width: 1px;
}

.study-materials-section::-webkit-scrollbar-thumb {
  background-color: var(--text-tertiary);
  border-radius: 1px;
}

.study-materials-section::-webkit-scrollbar-track {
  background-color: var(--background-secondary);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .study-materials-section {
    max-height: 16vh;
  }
}

@media (min-height: 1000px) {
  .study-materials-section {
    max-height: 25vh;
  }
}
/* Loading spinner styles */
.loading-spinner {
  width: 24px;
  height: 24px;
  border: 2px solid var(--border-primary);
  border-top: 2px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  margin-right: 12px;
  flex-shrink: 0;
}

.resource-item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.resource-item-container.loading {
  padding-bottom: 1rem;
}

.resource-item-container{
  padding-bottom: 0.3rem;
}

.resource-streaming {
  display: block;
  font-size: 0.9rem;
  color: var(--text-muted);
  align-self: flex-end;
  width: 100%;
  min-height: 20px;
  max-height: 100px;
  background: linear-gradient(145deg, var(--background-light), var(--background-main));
  border: 1px solid var(--border-primary);
  border-radius: 0 0 8px 8px;
  padding: 8px;
  margin-top: -10px;
  overflow-y: hidden;
  white-space: nowrap;
}

.resource-streaming span {
  display: inline;
  white-space: pre-wrap;
  animation: scrollUp 2s linear infinite;
}

@keyframes scrollUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

/* Staggered animation for lines */
.resource-streaming span:nth-child(1) { animation-delay: 0s; }
.resource-streaming span:nth-child(2) { animation-delay: 0.4s; }
.resource-streaming span:nth-child(3) { animation-delay: 0.8s; }
.resource-streaming span:nth-child(4) { animation-delay: 1.2s; }
.resource-streaming span:nth-child(5) { animation-delay: 1.6s; }

/* Memory Section Styles */
.memory-group {
  background: var(--background-main);
  border-radius: 8px;
  border: 1px solid var(--border-light);
  overflow: hidden;
  margin-top: 1rem;
}

.memory-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.memory-header:hover {
  background-color: var(--background-light);
}

.memory-header h2 {
  margin: 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.memory-toggle {
  font-size: 0.9rem;
  color: var(--text-secondary);
  transition: transform var(--transition-fast);
}

.memory-toggle.open {
  transform: rotate(180deg);
}

.memory-content {
  max-height: 0;
  overflow: hidden;
  transition: 
    max-height var(--transition-base) var(--ease-out),
    transform var(--transition-base) var(--ease-out),
    padding var(--transition-base) var(--ease-out);
  padding: 0 1rem;
  transform: translateY(-10px);
}

.memory-content.open {
  max-height: 600px;
  padding: 1rem;
  transform: translateY(0);
}

.memory-description {
  color: var(--text-secondary);
  font-size: 0.8rem;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.memory-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.memory-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--background-light);
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid var(--border-light);
}

.memory-preview {
  flex: 1;
  font-size: 0.85rem;
  color: var(--text-primary);
  cursor: text;
  padding: 0.25rem;
}

.memory-item textarea {
  background: var(--background-main);
  border: 1px solid var(--border-light);
  border-radius: 4px;
  color: var(--text-primary);
  font-size: 0.85rem;
  padding: 0.5rem;
  transition: border-color var(--transition-fast);
}

.memory-item textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--shadow-primary);
}

.memory-button {
  background: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 4px;
  transition: all var(--transition-fast);
}

.memory-button:hover {
  color: var(--error-color);
  background: var(--background-hover);
}

.memory-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.memory-add-button {
  background: var(--background-light);
  border: 1px solid var(--border-light);
  color: var(--text-secondary);
  padding: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.memory-add-button:hover {
  background: var(--background-hover);
  color: var(--primary-color);
  transform: scale(1.05);
}
.empty-memory-message {
  color: var(--text-secondary);
  font-size: 0.8rem;
  text-align: center;
  padding: 1rem;
  background: var(--background-light);
  border-radius: 6px;
  border: 1px dashed var(--border-light);
}

.resources-section {
  min-width: 250px;
  z-index: var(--z-fixed);
}

.resources-section.expanded {
  min-width: 350px;
  max-width: 1050px;
}