/* Smooth Animations */
@keyframes fadeInDisplay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
@keyframes floatLogo {
    from {
      opacity: 0;
      transform: translateY(20px) scale(0.95);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  
@keyframes slideHeader {
    from {
      opacity: 0;
      transform: translateY(15px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
@keyframes gradientFlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
@keyframes expandUnderline {
    from {
      width: 0;
    }
    to {
      width: 60px;
    }
  }

/* Enhanced Default Display */
.default-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 2rem;
    text-align: center;
    background-color: var(--background-main);
    color: var(--text-primary);
    transition: all 0.3s var(--transition-bezier);
    opacity: 0;
    animation: fadeInDisplay 0.3s var(--transition-bezier) forwards;
    margin-top: 15%;
  }

.default-display .logo::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 4px;
    background-color: var(--primary-color);
    border-radius: 2px;
    transition: all 0.3s ease;
  }

@media( min-height: 1200px){
    .default-display{
      margin-top: 220px;
    }
  }

/* Refined Dynamic Header */
.dynamic-header {
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
    line-height: 1.4;
    max-width: 85%;
    color: var(--text-primary);
    opacity: 0;
    transform: translateY(15px);
    animation: slideHeader 0.4s var(--transition-bezier) 0.3s forwards;
    background: linear-gradient(135deg, var(--text-primary) 0%, var(--text-secondary) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    font-size: var(--font-size-2xl);
  }

.learn-more-container {
    display: flex;
    justify-content: center;
    margin-top: 150px;
  }
  
.learn-more-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
    border-radius: var(--radius-full);
    background-color: var(--background-light);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    transition: all var(--transition-base);
    box-shadow: var(--shadow-color) 0px 1px 2px;
  }
  
.learn-more-button:hover {
    transform: translateY(-1px);
  }
  
.learn-more-button:active {
    transform: translateY(0);
    box-shadow: var(--shadow-color) 0px 1px 2px;
  }
  
.learn-more-button svg {
    color: var(--primary-color);
    transition: transform var(--transition-base);
  }
  
.learn-more-button:hover svg {
    transform: rotate(5deg);
  }
  
@media (max-width: 568px) {
    .learn-more-container {
      margin-top: 70px;
    }
  }

@media (max-width: 450px) {  
    .dynamic-header {
      font-size: var(--font-size-xl);
      margin-bottom: 1rem;
    }
  }

@media (max-width: 450px) and (max-height: 800px) {
    .default-display {
        padding: 0rem;
        margin-top: 80px;
    }
}