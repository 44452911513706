
.user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--background-light);
    color: var(--text-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-sm);
    cursor: pointer;
    transition: all var(--transition-fast) var(--ease-out);
    border: 1px solid var(--border-interactive);
  }
  
  .user-avatar:hover {
    background-color: var(--background-hover);
    border: 1px solid var(--border-interactive-hover);
  }
  
  /* Dropdown Container */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Menu - Enhanced Design */
  .dropdown-menu {
    position: absolute;
    top: calc(100% + 8px); /* Increased spacing from trigger */
    right: 12px;
    background: var(--dropdown-bg); /* Changed to use solid background color */
    backdrop-filter: var(--blur-lg);
    border-radius: var(--radius-2xl);
    box-shadow: var(--shadow-elevated);
    min-width: 260px;
    padding: var(--spacing-md) 0;
    z-index: var(--z-dropdown);
    transform-origin: top right;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.95) translateY(-8px);
    transition: all var(--transition-base) var(--ease-out);
    border: 1px solid var(--border-color);
  }
  
  /* Active state with improved animation */
  .dropdown-menu.active {
    opacity: 1;
    visibility: visible;
    transform: scale(1) translateY(0);
  }
  
  /* Dropdown items with modern styling */
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    margin: 4px 8px;
    color: var(--dropdown-text);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    transition: all var(--transition-fast);
    cursor: pointer;
    gap: var(--spacing-sm);
    border-radius: var(--radius-lg);
    position: relative;
    overflow: hidden;
    min-width: 90%;
  }
  
  /* Enhanced hover effect */
  .dropdown-item:hover {
    background: var(--dropdown-hover);
    color: var(--dropdown-text-hover);
  }
  
  /* Icon styling */
  .dropdown-item svg {
    color: var(--primary-color);
    font-size: var(--font-size-lg);
    transition: transform var(--transition-fast);
  }
  
  /* Icon animation on hover */
  .dropdown-item:hover svg {
    transform: scale(1.1);
  }

.user-dropdown-icon {
  min-width: 30px;
}

.user-dropdown-text {
  flex-grow: 1;
  text-align: left;
}

  /* Dropdown divider styling */
.dropdown-divider {
    height: 1px;
    margin: 8px;
    background: linear-gradient(
      to right,
      var(--border-primary),
      var(--border-primary-hover),
      var(--border-primary)
    );
    border: none;
  }
  
  /* Mobile Responsive Adjustments */
  @media (max-width: 768px) {
    .dropdown-menu {
      min-width: 240px;
      padding: var(--spacing-sm) var(--spacing-xs);
    }
  
    .dropdown-item {
      padding: var(--spacing-sm) var(--spacing-md);
      font-size: var(--font-size-xs);
      margin: var(--spacing-xs) var(--spacing-xs);
    }
  }