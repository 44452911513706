/* General math styles */
.math-inline,
.math-block {
    font-size: var(--font-size-base);
    line-height: var(--line-height-snug);
    overflow-x: auto;
    max-width: 100%;
    font-family: var(--font-mono);
}

.math-inline {
    display: inline-flex;
    align-items: center;
    margin: 0 var(--spacing-xs);
    padding: calc(var(--spacing-xs)/2) var(--spacing-sm);
    background-color: var(--background-light);
    border-radius: var(--radius-sm);
    border: 1px solid var(--border-color);
    transition: all var(--transition-fast);
}

.math-inline:hover {
    background-color: var(--background-hover);
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-color);
}

.math-block {
    display: block;
    margin: var(--spacing-xl) 0;
    padding: var(--spacing-lg);
    background-color: var(--background-light);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-elevated);
    border: 1px solid var(--border-color);
}

.math-block::before {
    content: '∑';
    position: absolute;
    top: var(--spacing-sm);
    left: calc(-1 * var(--spacing-md));
    font-size: var(--font-size-xl);
    color: var(--error-color);
    opacity: var(--hover-opacity);
}

/* Physics and Chemistry content */
.physics-content,
.chemistry-content {
    margin: var(--spacing-xl) 0;
    padding: var(--spacing-lg);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-elevated);
    border: 1px solid var(--border-color);
}

.inline-physics,
.inline-chemistry {
    display: inline-flex;
    align-items: center;
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--radius-sm);
    font-family: var(--font-mono);
    font-size: var(--font-size-sm);
    transition: all var(--transition-base);
    border: 1px solid var(--border-color);
}

.inline-physics {
    background-color: var(--info-color);
    color: var(--text-light);
}

.inline-chemistry {
    background-color: var(--primary-light);
    color: var(--text-light);
}

.inline-physics:hover,
.inline-chemistry:hover {
    background-color: var(--background-main);
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-color);
}

/* KaTeX specific styles */
.katex {
    font-size: var(--font-size-base);
    white-space: normal;
    text-indent: 0;
    color: var(--text-primary);
}

.katex-display {
    overflow-x: auto;
    overflow-y: hidden;
    padding: var(--spacing-md) 0;
    margin: var(--spacing-md) 0;
}

.katex .mord.mathdefault,
.katex .mop,
.katex .mbin {
    color: var(--text-primary);
}

.katex .mrel {
    color: var(--error-color);
}

/* Chemistry-specific styles */
.katex .mhchem {
    font-size: var(--font-size-base);
    white-space: normal;
    word-wrap: break-word;
}

.katex .mhchem .mord {
    margin-right: var(--spacing-xs);
    overflow-wrap: break-word;
    word-break: break-word;
}

/* Chemistry content styles */
.chemistry-content {
    overflow-x: auto;
    max-width: 100%;
    padding: var(--spacing-md);
    background-color: var(--background-hover-primary);
    border-radius: var(--radius-md);
    margin: var(--spacing-md) 0;
    white-space: normal;
    word-wrap: break-word;
}

.inline-chemistry {
    display: inline-block;
    max-width: 100%;
    overflow-wrap: break-word;
    word-break: break-word;
    vertical-align: middle;
    padding: calc(var(--spacing-xs)/2) var(--spacing-sm);
    background-color: var(--background-hover-primary);
    border-radius: var(--radius-sm);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .math-block,
    .physics-content,
    .chemistry-content {
        padding: var(--spacing-md);
        margin: var(--spacing-lg) 0;
        font-size: var(--font-size-sm);
    }

    .math-block::before {
        font-size: var(--font-size-lg);
        left: calc(-1 * var(--spacing-sm));
    }

    .katex {
        font-size: var(--font-size-sm);
    }

    .chemistry-content,
    .inline-chemistry {
        font-size: var(--font-size-xs);
    }
}

@media (max-width: 480px) {
    .math-block,
    .physics-content,
    .chemistry-content {
        padding: var(--spacing-sm);
        margin: var(--spacing-md) 0;
        font-size: var(--font-size-xs);
    }

    .katex {
        font-size: var(--font-size-sm);
    }
}
