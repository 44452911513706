.search-modal {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: min(90%, 700px);
  height: min(50vh, 500px);
  max-height: 500px;
  background: var(--background-light);
  box-shadow: var(--shadow-primary);
  border-radius: var(--radius-2xl);
  z-index: var(--z-modal);
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: all var(--transition-base);
  border: 1px solid var(--border-light);
  overflow: hidden;
}

.search-modal.open {
  opacity: 1;
  visibility: visible;
  top: 50%;
  transform: translate(-50%, -50%);
}

.search-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-md) var(--spacing-md);
  border-bottom: 1px solid var(--border-color);
}

.search-header input {
  flex: 1;
  padding: var(--spacing-sm) var(--spacing-md);
  color: var(--text-primary);
  font-size: var(--font-size-base);
  transition: border-color var(--transition-fast);
  outline: none;
  border: none;
  background: transparent;
}

.search-header input:focus {
  outline: none;
}

.close-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: var(--font-size-xl);
  cursor: pointer;
  padding: var(--spacing-xs);
  border-radius: var(--radius-full);
  transition: all var(--transition-fast);
}

.close-button:hover {
  color: var(--text-primary);
  background: var(--background-hover);
}

.search-results-container {
  max-height: 60vh;
  overflow-y: auto;
  padding: 0 var(--spacing-sm);
  max-width: 100%;
}

.results-header {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-md);
  position: sticky;
  top: 0;
  z-index: 1;
  padding: var(--spacing-sm) var(--spacing-sm);
}

.results-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.result-item {
  background: transparent;
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-lg);
  transition: all var(--transition-fast);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.result-item-left {
    min-width: 35px;
    margin: auto 0;
}

.result-item-right {
    display: flex;
    flex-grow: 1;
    margin-left: var(--spacing-sm);
}

.result-item-preview {
    flex-grow: 1;
    padding-right: var(--spacing-md);
    margin: auto 0;
}

.result-item-time {
    margin-left: var(--spacing-md);
    min-width: fit-content;
    display: none;
    margin: auto 0;
}

.result-item:hover {
  background: var(--background-hover);
  border-bottom-color: var(--border-strong);
}

.result-item:hover .result-item-time {
    display: block;
}

.result-item h4 {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-xxs);
}

.result-item p {
  color: var(--text-secondary);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-base);
}

.result-item.new-chat {
    margin-top: var(--spacing-sm);
    padding: var(--spacing-md) var(--spacing-sm);
    border-radius: var(--radius-lg);
    justify-content: flex-start;
}

.result-item.new-chat:hover {
    background: var(--background-hover);
}

.result-item.new-chat .result-item-left {
    min-width: 30px;
}

.search-chat-listings {
    padding: var(--spacing-md) var(--spacing-sm);
    text-align: left;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    width: 100%;
}

.search-chat-listings h4 {
    color: var(--text-primary);
    font-size: var(--font-size-sm);
    font-weight: 500;
    margin: 0;
}

.search-chat-listings p {
    color: var(--text-secondary);
    font-size: var(--font-size-xs);
    margin: 0;
    line-height: var(--line-height-base);
}


/* Mobile Overlay */

.search-mobile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background-overlay);
    z-index: var(--z-modal);
}

.search-mobile-overlay-header {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    padding: var(--spacing-md) var(--spacing-sm);
    border-bottom: 1px solid var(--border-color);
}

.search-mobile-overlay .search-results-container {
    padding: 0 var(--spacing-md);
    max-height: 70vh;
}

.search-mobile-overlay-header input {
    flex: 1;
    padding: var(--spacing-sm) var(--spacing-md);
    color: var(--text-primary);
    font-size: var(--font-size-base);
    transition: border-color var(--transition-fast);
    outline: none;
    border: none;
    background: transparent;
}

.search-mobile-overlay .result-item-time {
    display: block;
}

