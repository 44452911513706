
.file-card {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px 16px;
  background-color: var(--background-main);
  border: 2px solid var(--border-color);
  margin: 0 10px;
  border-radius: 16px;
  font-size: 1rem;
  max-width: 300px;
  flex-shrink: 0;
}

.file-card:hover {
  background: var(--background-hover);
  border: 2px solid var(--border-light);
}

.file-icon {
  color: var(--primary-color);
  font-size: 0.7rem;
  flex-shrink: 0;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-primary);
  font-size: 0.75rem;
}

.remove-file {
  background: none;
  border: none;
  color: var(--text-tertiary);
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.remove-file:hover {
  color: var(--error-color);
}