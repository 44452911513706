.study-space-page {
  background-color: var(--background-main);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
}

.study-space-page .study-space-header {
  padding: 1.25rem 2.5rem;
  border-bottom: 1px solid var(--border-light);
  background-color: var(--background-main);
  box-shadow: 0 1px 3px var(--shadow-color);
}

.study-space-page .back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  background: none;
  border: none;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 6px;
}

.study-space-page .back-button:hover {
  color: var(--text-primary);
  transform: translateX(-2px);
}

.study-space-page .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.study-space-page .header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.study-space-page .header-left h1 {
  font-size: 1.75rem;
  color: var(--text-primary);
  margin: 0;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.study-space-page .study-space-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  background-color: var(--background-main);
  padding: 1.5rem 2.5rem;
  gap: 2rem;
}

.study-space-page .space-sidebar {
  width: 360px;
  border-radius: 16px;
  border: 1px solid var(--border-light);
  background-color: var(--background-main);
  overflow-y: auto;
  padding: 1.5rem;
  box-shadow: 0 4px 12px var(--shadow-color);
  height: fit-content;
  max-height: calc(100vh - 180px);
}

.study-space-page .section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  padding: 0 0.25rem;
}

.study-space-page .section-header h2 {
  font-size: 1.15rem;
  color: var(--text-primary);
  margin: 0;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.study-space-page .instructions-section,
.study-space-page .files-section {
  margin-bottom: 2rem;
}

.study-space-page .instructions-content {
  font-size: 0.95rem;
  color: var(--text-secondary);
  line-height: 1.7;
  max-height: 600px;
  overflow-y: auto;
  padding: 1.25rem;
  background: var(--background-main);
  border: 1px solid var(--border-light);
  border-radius: 12px;
  box-shadow: var(--shadow-color);
  transition: all 0.2s ease;
}

.study-space-page .instructions-content::-webkit-scrollbar {
  width: 2px;
}

.study-space-page .instructions-content::-webkit-scrollbar-track {
  background: var(--background-light);
  border-radius: 4px;
}

.study-space-page .instructions-content::-webkit-scrollbar-thumb {
  background: var(--border-light);
  border-radius: 4px;
}

.study-space-page .instructions-content::-webkit-scrollbar-thumb:hover {
  background: var(--border-color);
}

.study-space-page .instructions-editor-container {
  background: var(--background-main);
  border-radius: 16px;
  border: 1px solid var(--border-light);
  box-shadow: var(--shadow-color);
  transition: all 0.2s ease;
  overflow: hidden;
}

.study-space-page .instructions-editor-container:focus-within {
  border-color: var(--border-color);
}

.study-space-page .instructions-editor {
  width: 100%;
  min-height: 120px;
  max-height: 400px;
  padding: 1.25rem;
  border: none;
  font-size: 0.95rem;
  line-height: 1.7;
  color: var(--text-primary);
  background-color: transparent;
  transition: all 0.2s ease;
  font-family: inherit;
  resize: none;
  overflow-y: auto;
}

.study-space-page .instructions-editor:focus {
  outline: none;
}

.study-space-page .editor-actions {
  display: flex;
  gap: 0.75rem;
  justify-content: flex-end;
  padding: 1rem 1.25rem;
  background: var(--background-light);
  border-top: 1px solid var(--border-light);
}

.study-space-page .cancel-button {
  padding: 0.625rem 1.25rem;
  border: 1px solid var(--border-light);
  border-radius: 12px;
  background: var(--background-main);
  color: var(--text-secondary);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: var(--shadow-color);
}

.study-space-page .cancel-button:hover {
  background: var(--background-hover);
  border-color: var(--border-color);
  color: var(--text-primary);
  transform: translateY(-1px);
}

.study-space-page .cancel-button.save {
  padding: 0.625rem 1.5rem;
  border: none;
  background: var(--primary-gradient);
  color: var(--text-light);
  font-weight: 500;
  box-shadow: 0 4px 12px var(--shadow-primary);
}

.study-space-page .cancel-button.save:hover {
  filter: brightness(105%);
  transform: translateY(-1px);
  box-shadow: 0 6px 16px var(--shadow-primary-hover);
}

.study-space-page .cancel-button.save:active {
  transform: translateY(0);
  filter: brightness(95%);
}

.study-space-page .upload-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1.2rem;
  color: var(--text-primary);
  background: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all 0.2s var(--transition-bezier);
  box-shadow: var(--shadow-color);
}

.study-space-page .upload-button:hover {
  background: var(--background-hover);
  border-color: var(--border-interactive-hover);
  box-shadow: var(--shadow-primary);
}

.study-space-page .file-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  background-color: var(--background-main);
  border: 1px solid var(--border-light);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.study-space-page .file-item:hover {
  background-color: var(--background-light);
}

.study-space-page .file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 3px;
  background: var(--background-main);
  border-radius: 8px;
  color: var(--primary-color);
}

.study-space-page .file-info {
  flex: 1;
  min-width: 0;
}

.study-space-page .file-name {
  display: block;
  color: var(--text-primary);
  font-weight: 500;
  text-decoration: none;
  margin-left: -0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.study-space-page .file-name:hover {
  color: var(--primary-color);
}

.study-space-page .file-meta {
  display: flex;
  gap: 1rem;
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.study-space-page .file-date, .study-space-page .file-type {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.study-space-page .file-actions {
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.study-space-page .file-item:hover .file-actions {
  opacity: 1;
}

.study-space-page .file-action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background: var(--background-light);
  border-radius: 6px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.study-space-page .file-action-btn:hover {
  background: var(--background-hover);
  color: var(--primary-color);
  transform: scale(1.05);
}

.study-space-page .empty-files-state {
  text-align: center;
  padding: 2rem;
  color: var(--text-tertiary);
  background: var(--background-light);
  border-radius: 8px;
  border: 2px dashed var(--border-light);
}

.study-space-page .empty-files-state p {
  margin: 0;
  font-size: 0.9rem;
}

.study-space-page .chats-section {
  flex: 1;
  padding: 1.75rem;
  overflow-y: auto;
}

.study-space-page .create-chat-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1.2rem;
  color: var(--text-light);
  background: var(--primary-gradient);
  border: none;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 4px var(--shadow-primary);
}

.study-space-page .create-chat-btn:hover {
  filter: brightness(105%);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px var(--shadow-primary-hover);
}

.study-space-page .chats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.25rem;
  margin-top: 1.25rem;
}
.study-space-page .chat-card {
  background: var(--glass-background);
  border: 1px solid var(--border-light);
  transition: all var(--transition-base) var(--transition-bezier);
  border-radius: var(--radius-lg);
  padding: var(--spacing-lg);
  cursor: pointer;
  height: 160px;
  display: flex;
  flex-direction: column;
  position: relative;
  backdrop-filter: var(--blur-sm);
}

.study-space-page .chat-card:hover {
  border-color: var(--border-color);
}

.study-space-page .chat-card-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  padding-right: var(--spacing-xl);
}

.study-space-page .chat-card-header h3 {
  margin: 0;
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
}

.study-space-page .chat-card-footer {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: var(--text-secondary);
  padding-top: 0.5rem;
  border-top: 1px solid var(--border-light);
}

.study-space-page .empty-state {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .study-space-page .study-space-content {
    flex-direction: column;
  }

  .study-space-page .space-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border-light);
  }

  .study-space-page .chats-grid {
    grid-template-columns: 1fr;
  }

  .study-space-page .chat-card {
    height: 120px;
  }

  .study-space-page .chat-preview {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  .study-space-page .return-to-chats {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }
  .study-space-page .return-to-chats:hover {
    background-color: var(--background-hover);
  }
}

.study-space-page .empty-chats-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 2rem;
  background: linear-gradient(145deg, var(--background-main), var(--background-light));
  border-radius: 16px;
  margin: 1rem;
  box-shadow: 
    0 8px 16px var(--shadow-color),
    0 4px 8px var(--shadow-color);
}

.study-space-page .empty-chats-content {
  text-align: center;
  max-width: 360px;
  animation: fadeIn 0.5s ease-out;
}

.study-space-page .empty-chats-content .empty-icon {
  color: var(--primary-color);
  opacity: 0.9;
  margin-bottom: 1.5rem;
}

.study-space-page .empty-chats-content h2 {
  font-size: 1.4rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
  font-weight: 600;
}

.study-space-page .empty-chats-content p {
  color: var(--text-secondary);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.study-space-page .create-chat-btn-empty {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-light);
  background: var(--primary-gradient);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px var(--shadow-primary);
}

.study-space-page .create-chat-btn-empty:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px var(--shadow-primary-hover);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.study-space-page .upload-progress-container {
  background: var(--background-main);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px var(--shadow-color);
  border: 1px solid var(--border-light);
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.study-space-page .upload-progress-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.study-space-page .upload-filename {
  font-size: 0.9rem;
  color: var(--text-primary);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.study-space-page .upload-progress-bar-container {
  height: 6px;
  background: var(--background-light);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.study-space-page .upload-progress-bar {
  height: 100%;
  background: var(--primary-gradient);
  border-radius: 10px;
  transition: width 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.study-space-page .upload-progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent 0%,
    var(--background-overlay) 50%,
    transparent 100%
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.study-space-page .upload-progress-text {
  font-size: 0.8rem;
  color: var(--text-secondary);
  text-align: right;
  margin-top: 0.5rem;
  font-weight: 500;
}

/* Add a pulsing animation when processing */
.study-space-page .upload-progress-container[data-processing="true"] .upload-progress-text {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.study-space-page .upload-status-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.study-space-page .current-file {
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.study-space-page .upload-results {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.study-space-page .upload-results.success {
  background-color: var(--success-color);
  color: var(--text-light);
}

.study-space-page .upload-results.error {
  background-color: var(--error-color);
  color: var(--text-light);
}

.study-space-page .resource-item {
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  border-radius: 8px;
  margin-bottom: 8px;
  background: var(--background-hover);
  cursor: pointer;
  transition: all 0.2s ease;
  max-height: 50px;
  border: 1px solid var(--border-light);
}

.study-space-page .resource-item:hover {
  background: var(--background-hover-primary);
}

.study-space-page .resource-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 6px;
  background: var(--background-main);
  margin-right: 12px;
  color: var(--primary-color);
}

.study-space-page .resource-info {
  flex: 1;
  min-width: 0;
}

.resource-title {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resource-meta {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}

.resource-type {
  font-size: 12px;
  color: var(--text-secondary);
}

.resource-chat-link {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: var(--primary-color);
  padding: 2px 6px;
  border-radius: 4px;
  background: var(--background-main);
}

.resource-arrow {
  color: var(--text-secondary);
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.resource-item:hover .resource-arrow {
  opacity: 1;
}

.empty-resources-state {
  text-align: center;
  padding: 24px;
  color: var(--text-secondary);
}

.study-space-page .resources-list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 1rem;
}

/* Loading Skeleton */
.loading-skeleton {
  padding: 1rem;
  background: var(--background-main);
  border-radius: var(--radius-md);
  margin-bottom: 1rem;
}

.skeleton-header {
  height: 24px;
  background: var(--background-light);
  border-radius: var(--radius-sm);
  margin-bottom: 1rem;
  animation: pulse 1.5s infinite;
}

.skeleton-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.skeleton-item {
  height: 16px;
  background: var(--background-light);
  border-radius: var(--radius-sm);
  animation: pulse 1.5s infinite;
  width: 100%;
}

.skeleton-item:nth-child(2) {
  width: 80%;
}

.skeleton-item:nth-child(3) {
  width: 60%;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

/* Resource Preview */
.resource-preview {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Language Tag */
.language-tag {
  background: var(--primary-light);
  color: var(--primary-color);
  padding: 0.125rem 0.375rem;
  border-radius: var(--radius-sm);
  font-size: 0.75rem;
  margin-left: 0.5rem;
}
 
.study-space-page  .return-to-chats {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border-radius: 12px;
  color: var(--text-secondary);
  font-weight: 500;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  text-decoration: none;
  background: var(--background-light);
  border: 1px solid var(--border-light);
}

.study-space-page .return-to-chats:hover {
  background: var(--background-hover);
  transform: translateX(-2px);
  color: var(--text-primary);
}

.editable-title {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: var(--radius-sm);
  transition: all 0.2s ease;
}

.editable-title .edit-icon {
  opacity: 0;
  color: var(--primary-color);
  transition: all 0.2s ease;
}

.edit-button {
  color: var(--text-primary);
}

.editable-title:hover .edit-icon {
  opacity: 1;
  transform: translateX(2px);
}

.title-editor {
  display: flex;
  align-items: center;
  gap: 12px;
  background: var(--background-main);
  border-radius: var(--radius-lg);
  padding: 4px;
  box-shadow: var(--shadow-color);
  border: 1px solid var(--border-light);
  transition: all 0.2s ease;
}

.title-editor input {
  font-size: 1.75rem;
  font-weight: 600;
  padding: 8px 12px;
  border: none;
  background: transparent;
  color: var(--text-primary);
  width: 100%;
  min-width: 200px;
  letter-spacing: -0.02em;
}

.title-editor input:focus {
  outline: none;
}

.title-editor-actions {
  display: flex;
  gap: 8px;
  padding-right: 8px;
}

.title-editor-actions button {
  padding: 6px 12px;
  border-radius: var(--radius-md);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

/* Add these styles for the upload progress */
.study-space-page .pdf-processing-message {
  background: linear-gradient(135deg, #fff8f7 0%, #fff 100%);
  border: 1px solid rgba(244, 128, 107, 0.2);
  border-radius: 12px;
  padding: 16px;
  margin: 12px 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  animation: slideIn 0.3s ease-out;
}

.study-space-page .processing-content {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
}

.study-space-page .processing-icon {
  background: rgba(244, 128, 107, 0.1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.study-space-page .processing-icon svg {
  color: #F4806B;
}

.study-space-page .animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.study-space-page .processing-text h4 {
  color: #2d3748;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 4px 0;
}

.study-space-page .processing-text p {
  color: #718096;
  font-size: 0.9rem;
  margin: 0;
}

.study-space-page .processing-progress {
  background: rgba(244, 128, 107, 0.1);
  border-radius: 6px;
  height: 4px;
  overflow: hidden;
}

.study-space-page .progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #F4806B, #ff9b8a);
  border-radius: 6px;
  transition: width 0.3s ease;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .study-space-page .pdf-processing-message {
    padding: 12px;
    margin: 8px 0;
  }

  .study-space-page .processing-icon {
    width: 32px;
    height: 32px;
  }

  .study-space-page .processing-text h4 {
    font-size: 0.9rem;
  }

  .study-space-page .processing-text p {
    font-size: 0.8rem;
  }

  .study-space-page .space-content {
    padding: 0;
  }
}

/* Mobile Goals & Objectives Dropdown */
@media (max-width: 768px) {
  .study-space-page .instructions-section {
    background: var(--background);
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-sm);
    margin-bottom: 1rem;
  }

  .study-space-page .instructions-section .section-header {
    cursor: pointer;
    padding: 1rem 0;
    margin-bottom: 0;
    transition: background-color 0.2s ease;
  }

  .study-space-page .instructions-section .section-header h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .study-space-page .instructions-section .section-header h2::after {
    content: '';
    width: 10px;
    height: 10px;
    border-right: 2px solid var(--text-secondary);
    border-bottom: 2px solid var(--text-secondary);
    transform: rotate(45deg);
    transition: transform 0.2s ease;
  }

  .study-space-page .instructions-section[data-expanded="true"] .section-header h2::after {
    transform: rotate(-135deg);
  }

  .study-space-page .instructions-section .instructions-content {
    max-height: 0;
    overflow: hidden;
    padding: 0 1rem;
    transition: all 0.3s ease;
  }

  .study-space-page .instructions-section[data-expanded="true"] .instructions-content {
    max-height: 500px;
    padding: 1rem;
    padding-top: 0;
    overflow-y: auto;
  }

  .study-space-page .instructions-section .instructions-editor-container {
    margin: 0 1rem 1rem;
  }

  .study-space-page .study-space-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 0.5rem 0.5rem;
    gap: 2rem;
  }
}

/* Mobile Header Improvements */
@media (max-width: 768px) {
  .study-space-page .study-space-header {
    padding: 0.75rem 1rem;
  }

  .study-space-page .study-space-header h1 {
    font-size: 1.35rem;
  }

  .study-space-page .header-content {
    flex-direction: column;
    gap: 0.75rem;
    padding: 0;
  }

  .study-space-page .header-left {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .study-space-page .return-to-chats {
    width: 100%;
    justify-content: center;
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .study-space-page .back-button {
    margin-bottom: 0.5rem;
    padding: 0.375rem;
    font-size: 0.875rem;
  }

  .study-space-page .editable-title {
    width: 100%;
    padding: 0.5rem;
  }

  .study-space-page .editable-title h1 {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .study-space-page .title-editor {
    width: 100%;
  }

  .study-space-page .title-editor input {
    font-size: 1.25rem;
    padding: 0.5rem;
  }

  .study-space-page .title-editor-actions {
    padding-right: 4px;
  }

  .study-space-page .title-editor-actions button {
    padding: 4px 8px;
    font-size: 0.75rem;
  }

  .study-space-page .header-right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

/* Additional improvements for very small screens */
@media (max-width: 380px) {
  .study-space-page .study-space-header {
    padding: 0.5rem;
  }

  .study-space-page .back-button {
    font-size: 0.8125rem;
  }

  .study-space-page .editable-title h1 {
    font-size: 1.125rem;
  }

  .study-space-page .title-editor input {
    font-size: 1.125rem;
    min-width: 150px;
  }
}

/* Loading State */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  padding: 2rem;
  background: linear-gradient(to right, #fff, #fafafa);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(244, 128, 107, 0.1);
  border-radius: 50%;
  border-top-color: #F4806B;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 1rem;
}

.loading-text {
  color: #64748b;
  font-size: 0.95rem;
  font-weight: 500;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.chat-card .delete-button {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 6px;
  border-radius: 6px;
  background: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-card:hover .delete-button {
  opacity: 1;
}

.chat-card .delete-button:hover {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

/* Update existing chat-card-header to accommodate delete button */
.chat-card-header {
  padding-right: 32px; /* Add space for delete button */
}

.chat-card-header svg {
  color: var(--text-primary);
  min-width: 14px;
  align-self: flex-start;
  margin-top: 6px;
  min-width: 18px;
}
/* Add styles for delete confirmation modal */
.delete-confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.delete-confirm-content {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
}

.delete-confirm-content h3 {
  margin: 0 0 16px 0;
  font-size: 1.2rem;
  color: #333;
}

.delete-confirm-content p {
  margin: 0 0 24px 0;
  color: #666;
  line-height: 1.5;
}

.delete-confirm-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.delete-confirm-actions button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.delete-confirm-actions .cancel {
  background-color: #f0f0f0;
  color: #666;
}

.delete-confirm-actions .cancel:hover {
  background-color: #e0e0e0;
}

.delete-confirm-actions .delete {
  background-color: #ff4444;
  color: white;
}

.delete-confirm-actions .delete:hover {
  background-color: #ff2222;
}

.delete-file-button {
  color: var(--text-primary);
}

.knowledge-tracker {
  padding: 12px 16px;
  background: var(--background-light);
  border-radius: 8px;
  margin-bottom: 16px;
}

.knowledge-tracker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.knowledge-tracker-bar {
  height: 6px;
  background: var(--background-hover);
  border-radius: 3px;
  overflow: hidden;
}

.knowledge-tracker-progress {
  height: 100%;
  background: #ef4444;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.knowledge-tracker-progress.full {
  background-color: #ff4444;
}

.not-found-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 2rem;
  background: var(--background-main);
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

.not-found-state::before {
  content: '404';
  font-size: 6rem;
  font-weight: 700;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.5;
  margin-bottom: 1rem;
  line-height: 1;
}

.not-found-state::after {

  display: block;
  color: var(--text-secondary);
  font-size: 1.1rem;
  max-width: 400px;
  line-height: 1.6;
  margin-bottom: 2rem;
}

/* Add a button to return to spaces */
.not-found-state .return-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.5rem;
  background: var(--primary-gradient);
  color: var(--text-light);
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  box-shadow: 0 4px 12px var(--shadow-primary);
}

.not-found-state .return-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px var(--shadow-primary-hover);
}

.not-found-state .return-button:active {
  transform: translateY(0);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .not-found-state::before {
    font-size: 4rem;
  }

  .not-found-state::after {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .not-found-state .return-button {
    padding: 0.75rem 1.25rem;
    font-size: 0.95rem;
  }
}