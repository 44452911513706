.sales-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--shadow-color-rgb), 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    backdrop-filter: blur(5px);
}

.sales-modal-content {
    background-color: var(--background-main);
    padding: 2.5rem;
    border-radius: 20px;
    max-width: 1000px; /* Increased for better layout */
    width: 90%;
    position: relative;
    box-shadow: 0 10px 30px var(--shadow-color);
    box-shadow: 0 10px 30px var(--shadow-color);
    border: 1.5px solid var(--primary-color);
    border: 1.5px solid var(--primary-color);
}

@keyframes modalFadeIn {
    from { opacity: 0; transform: translateY(-30px); }
    to { opacity: 1; transform: translateY(0); }
}

.sales-modal-close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-secondary);
    transition: all 0.3s ease;
    padding: 0.5rem;
    border-radius: 50%;
}

.sales-modal-close:hover {
    color: var(--primary-color);
    background-color: var(--background-light);
    transform: rotate(90deg);
}

.sales-modal-content h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--text-primary);
    font-weight: var(--font-weight-bold);
    text-align: center;
}

.modal-description {
    margin-bottom: 2rem;
    color: var(--text-secondary);
    line-height: 1.7;
    font-size: 1.1rem;
    text-align: center;
}

.plans-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    margin: 2rem auto;
}

.plan-card {
    padding: 2rem;
    border-radius: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 400px;
    max-width: 500px;
    margin: 0 auto;
    border: 1px solid var(--primary-color);

}

.plan-card:hover {
    box-shadow: 0 8px 20px var(--shadow-color);
}

@media (max-width: 468px) {
    .plan-card {
        min-width: 230px;
    }
}

.card-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.card-header h3 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
}

.tagline {
    color: var(--text-secondary);
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}

.plan-price {
    font-size: 2rem;
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 1.5rem;
}

.period {
    font-size: 1rem;
    color: var(--text-secondary);
}

.monthly-equivalent {
    display: block;
    font-size: 2.5rem;
    font-weight: bold;
}

.annual-total {
    display: block;
    font-size: 1rem;
    color: var(--text-secondary);
    margin-top: 0.25rem;
}

.free-trial-badge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(135deg, var(--primary-color), var(--error-color));
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    font-weight: 600;
    font-size: 0.875rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1;
    white-space: nowrap;
}

.feature-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1.5rem;
    text-align: left;
    flex-grow: 1;
}

.feature-list li {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    color: var(--text-secondary);
    font-size: 0.95rem;
}

.feature-list li svg {
    color: var(--primary-color);
    margin-right: 0.5rem;
}

.upgrade-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-weight: var(--font-weight-bold);
    transition: all 0.3s ease;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    background-color: var(--primary-color);
    color: var(--text-light);
    width: 100%;
    margin-top: auto;
}

.upgrade-button:hover {
    background-color: var(--primary-dark);
    box-shadow: 0 6px 20px var(--shadow-primary);
}

.modal-footer {
    text-align: center;
    margin-top: 2rem;
}

.modal-footer p {
    margin-bottom: 0.5rem;
    color: var(--text-secondary);
}

.view-all-plans {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: var(--font-weight-bold);
    transition: all 0.3s ease;
}

.view-all-plans:hover {
    text-decoration: underline;
}

.ultimate-star {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: var(--primary-color);
}

.plan-mobile {
    display: none;
}

@media (max-width: 768px) {
    .sales-modal-content {
        padding: 2rem;
        max-width: 90%;
        max-height: 80vh; /* Limit the height on mobile */
        display: flex;
        flex-direction: column;
    }

    .sales-modal-content h2 {
        font-size: 1.75rem;
    }

    .modal-description,
    .plan-card h3,
    .plan-price {
        font-size: 1rem;
    }

    .plans-grid {
        grid-template-columns: 1fr;
        overflow-y: auto; /* Make it scrollable */
        max-height: 70vh; /* Adjust this value as needed */
        padding-right: 1rem; /* Add some padding for the scrollbar */
    }

    .feature-list li {
        font-size: 0.9rem;
    }
    .plan-mobile{
      display:flex;
    }

    .upgrade-button {
        padding: 0.6rem 1.2rem;
        font-size: 0.9rem;
    }
    .upgrade-button:hover{
        color: var(--text-light);
    }

    /* Style the scrollbar for WebKit browsers */
    .plans-grid::-webkit-scrollbar {
        width: 6px;
    }

    .plans-grid::-webkit-scrollbar-track {
        background: var(--background-light);
    }

    .plans-grid::-webkit-scrollbar-thumb {
        background-color: var(--primary-color);
        border-radius: 3px;
    }

    /* Style the scrollbar for Firefox */
    .plans-grid {
        scrollbar-width: thin;
        scrollbar-color: var(--primary-color) var(--background-light);
    }
}

/* Add a media query for very small screens */
@media (max-width: 380px) {

}

.save-badge {
    background: var(--primary-dark);
    color: var(--text-light);
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    margin-left: 0.5rem;
    animation: pulse 2s infinite;
}

/* Update plan card styles */
.plan-card {
    background: linear-gradient(145deg, var(--background-main), var(--background-light));
    padding: 2rem;
    border-radius: 15px;
    transition: all 0.3s ease;
}

.plan-card:hover {
    box-shadow: 0 8px 20px var(--shadow-color);
}

.card-header h3 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
}

.tagline {
    color: var(--text-secondary);
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}

.plan-price {
    font-size: 2rem;
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
}

.period {
    font-size: 1rem;
    color: var(--text-secondary);
}

/* Update mobile styles */
@media (max-width: 768px) {
    .sales-modal-content {
        padding: 2rem;
        width: 95%;
        max-height: 90vh;
        overflow-y: auto;
    }

    .plans-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .pricing-toggle {
        flex-direction: row;
        padding: 0.5rem;
        width: fit-content;
        margin: 1rem auto;
    }
}

.sales-modal-content .pricing-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    gap: 1rem;
    padding: 0.5rem;
    background: var(--background-light);
    border-radius: 2rem;
    width: fit-content;
    position: relative;
}

/* Update responsive breakpoints */
@media (max-width: 800px) {
    .sales-modal-content {
        padding: 2rem;
        width: 95%;
        max-height: 90vh;
        overflow-y: auto;
    }

    .plans-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        padding: 0.5rem;
    }

    .plan-card {
        padding: 1.5rem;
    }



    .card-header h3 {
        font-size: 1.5rem;
    }

    .tagline {
        font-size: 1rem;
    }

    .plan-price {
        font-size: 1.6rem;
    }
}

@media (max-width: 538px) {
    .sales-modal-content {
        padding: 1.5rem;
        width: 100%;
        max-height: 100vh;
    }

    .pricing-toggle {
        flex-direction: column;
        gap: 0.5rem;
    }

    .save-badge {
        display: block;
        margin: 0.5rem auto 0;
        width: fit-content;
    }

    .modal-description {
        font-size: 0.9rem;
        margin-bottom: 1rem;
    }

    .sales-modal-content h2 {
        font-size: 1.5rem;
    }
}

@media (min-width: 539px) and (max-width: 800px) {
    .plans-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1rem;
    }
}

/* Add smooth transitions for resize events */
.sales-modal-content, .plans-grid, .plan-card {
    transition: all 0.3s ease-in-out;
}