/* Quiz Container */
.quiz-container {
  border-radius: var(--radius-lg);
  padding: 0 var(--spacing-md);
  background: var(--background-main);
  box-shadow: var(--shadow-sm);
}

/* Quiz Header */
.quiz-header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  padding: var(--spacing-md) 0;
  top: 0;
  background: var(--background-main);
  z-index: 10;
  display: none;
  backdrop-filter: var(--blur-md);
}

@media screen and (min-width: 768px) {
  .quiz-header {
    display: block;
  }
}

.quiz-metadata {
  display: flex;
  justify-content: center;
  gap: var(--spacing-xl);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  text-shadow: var(--text-shadow-base);
}

.quiz-metadata p {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--background-light);
  border-radius: var(--radius-md);
}

/* Questions Section */
.quiz-questions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-xs) 0;
}

.question-container {
  background: var(--background-light);
  border-radius: var(--radius-lg);
  padding: var(--spacing-md);
  border: 1px solid var(--border-interactive);
  transition: all var(--transition-base);
  box-shadow: var(--shadow-sm);
}

.question-container:hover {
  border-color: var(--border-interactive-hover);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.question-container h3 {
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-md);
  letter-spacing: -0.01em;
}

.question-container p {
  color: var(--text-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-relaxed);
  margin-bottom: var(--spacing-xl);
}

@media (max-width: 768px) {
  .question-container {
    padding: var(--spacing-sm);
  }

  .quiz-questions {
    padding-top: var(--spacing-md);
    gap: var(--spacing-lg);
  }
}

/* Answer Choices */
.answer-choices {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.answer-choice {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-lg);
  transition: all var(--transition-base);
  background: var(--background-main);
}

.answer-choice:hover {
  background: var(--background-hover);
}

.answer-choice input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid var(--border-color);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: all var(--transition-base);
}

.answer-choice input[type="radio"]:checked {
  border-color: var(--primary-color);
  background: var(--primary-color);
  box-shadow: 0 0 0 2px var(--shadow-primary);
}

.answer-choice label {
  color: var(--text-primary);
  font-size: var(--font-size-base);
  cursor: pointer;
  flex: 1;
  line-height: 1.4;
}

.show-answer-btn {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: var(--spacing-md);
  font-size: var(--font-size-sm);
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--background-light);
  border-radius: var(--radius-md);
  transition: all var(--transition-base);
}

.show-answer-btn:hover {
  transform: translateY(-2px);
  background: var(--background-dark);
}

/* Dark Mode Adjustments */
[data-theme="dark"] .question-container {
  background: var(--background-dark);
}

[data-theme="dark"] .answer-choice:hover {
  background: var(--background-hover-primary);
}

/* Accessibility Focus States */
.answer-choice input[type="radio"]:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Animation for Question Loading */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .answer-choices {
    gap: var(--spacing-sm);
  }
}

.question-container {
  animation: fadeIn 0.3s ease-out forwards;
}

.correct-answer {
    background-color: rgba(75, 181, 67, 0.1);
    border-radius: var(--radius-lg);
}

.correct-answer:hover {
  background-color: rgba(75, 181, 67, 0.2);
}

.incorrect-answer {
    background-color: rgba(255, 0, 0, 0.1);
    border-radius: var(--radius-lg);
}

.incorrect-answer:hover {
  background-color: rgba(255, 0, 0, 0.2);
}

.quiz-footer {
  position: sticky;
  padding: var(--spacing-md);
  bottom: 0;
  background: var(--background-main);
  border-top: 1px solid var(--border-light);
  z-index: var(--z-sticky);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  backdrop-filter: var(--blur-lg);
  box-shadow: var(--shadow-elevated);
  margin-top: var(--spacing-xl);
}

.grade-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--background-light);
  border-radius: var(--radius-md);
}

.quiz-footer button {
  padding: var(--spacing-sm) var(--spacing-xl);
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-base);
  transition: all var(--transition-base);
  cursor: pointer;
  min-width: 120px;
  text-align: center;
}

.quiz-footer button:hover {
  background-color: var(--background-dark);
  border-color: var(--border-strong);
  transform: var(--button-hover-transform);
  box-shadow: var(--shadow-md);
}

.quiz-footer button:active {
  transform: var(--button-active-transform);
}

.quiz-footer button:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
  box-shadow: var(--button-focus-shadow);
}

.quiz-footer button:disabled {
  background-color: var(--background-light);
  color: var(--text-secondary);
  border-color: var(--border-color);
  cursor: not-allowed;
}