.title-header-container .title-dropdown-menu {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    background: var(--dropdown-bg); /* Changed to use solid background color */
    border-radius: var(--radius-2xl);
    border: 1px solid var(--border-color);
    padding: var(--spacing-sm) var(--spacing-xs);
    z-index: 2000
}

.title-header-container{
    margin: 0 auto;
}

.title-dropdown-item {
    display: flex;
    align-items: center;
    padding: var(--spacing-sm) var(--spacing-md);
    color: var(--dropdown-text);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    transition: all var(--transition-fast);
    cursor: pointer;
    gap: var(--spacing-xs);
    border-radius: var(--radius-md);
    position: relative;
    overflow: hidden;
    margin: auto 0;
}

.title-dropdown-item:hover {
    background: var(--dropdown-hover);
    color: var(--dropdown-text-hover);
}


.title-dropdown-icon {
    min-width: 20px;
    min-height: 15px;
    margin: auto 0;
}

.title-dropdown-text {
    margin: auto 0;
    margin-bottom: 2px;
}

.chat-title-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-sm);
    margin: 0 auto;
    border-radius: var(--radius-2xl);
    padding: var(--spacing-sm) var(--spacing-md);
    width: fit-content;
}

.chat-title-container.not-editing.closed:hover {
    background: var(--background-hover);
}
  
.chat-title-container .chat-title {
    text-align: center;
    margin: 0;
    font-weight: var(--font-weight-bold);
}

.chat-title-input {
    background: transparent;
    padding: var(--spacing-sm);
    border-radius: 0;
    border: none;
    color: var(--text-muted);
    border-bottom: 2px solid var(--text-primary);
    font-size: var(--font-size-sm);
}

.chat-title-input:focus {
    color: var(--text-primary);
    outline: none;
    box-shadow: none;
    border-color: var(--text-primary);
}

.title-menu-button {
    position: relative;
    opacity: 0;
}

.chat-title-container:hover .title-menu-button.not-editing.closed {
    opacity: 1;
}