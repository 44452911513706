/* Custom Dropdown Base Styles */
.custom-select {
  position: relative;
  width: 100%;
  font-family: var(--font-primary);
}

.select-button {
  width: 100%;
  padding: 0.5rem 0.75rem;
  background: var(--background-main);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  color: var(--text-primary);
  font-size: 0.875rem;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all var(--transition-base);
}

.select-button:hover {
  border-color: var(--border-light);
  background: var(--background-hover);
}

.select-button svg {
  transition: transform var(--transition-base);
  font-size: 0.875rem;
}

.select-button.open svg {
  transform: rotate(180deg);
}

.select-options {
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  right: 0;
  background: var(--background-main);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  box-shadow: 0 2px 8px var(--shadow-color);
  max-height: 150px;
  overflow-y: auto;
  z-index: var(--z-dropdown);
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
  transition: all var(--transition-base);
}

.select-options.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.select-option {
  padding: 0.5rem 0.75rem;
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
  font-size: 0.875rem;
}

.select-option:hover {
  background: var(--background-hover);
  color: var(--primary-color);
}

.select-option.selected {
  background: var(--background-hover-primary);
  color: var(--primary-color);
}