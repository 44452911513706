.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-main);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loading-spinner {
    border: 4px solid var(--background-light);
    border-top: 4px solid var(--text-dark);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  .loading-content p {
    margin-top: 20px;
    font-size: 18px;
    color: var(--text-dark);
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }